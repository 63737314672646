import React, {useState} from "react";
import MenuTable from "../../components/tables/MenuTable";
import Menu from "@material-ui/core/Menu";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import Youth, {ListYouth, YouthGroup, youthToList} from "../../models/Youth";
import {projectYouthActions} from "../../store/YouthState";
import {useDispatch} from "react-redux";
import useCurrentProject from "../../hooks/useCurrentProject";
import {ListItemIcon, MenuItem, Select} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import HeadItem from "../../components/tables/HeadItem";
import UserAddLineIcon from "remixicon-react/UserAddLineIcon";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import ChatIcon from 'remixicon-react/Chat3LineIcon';
import JournalIcon from "remixicon-react/ContactsBook2LineIcon";
import {journalActions} from "../../store/JournalState";
import useCurrentUser from "../../hooks/useCurrentUser";

const heads: HeadItem<ListYouth>[] = [
    {id: "name", numeric: false, disablePadding: false, label: "Navn"},
    {id: "sex", numeric: false, disablePadding: false, label: "Køn"},
    {id: "age", numeric: true, disablePadding: false, label: "Alder"},
    {id: "municipalityName", numeric: false, disablePadding: false, label: "Kommune"},
    {id: "createdAt", numeric: false, disablePadding: false, label: "Oprettelsesdato"},
    {id: "groupName", numeric: false, disablePadding: false, label: "Grupper"}
]

interface YouthTableProps {
    youths: Youth[];
    onSelect: (y: Youth) => void;
}

const YouthListTable: React.FC<YouthTableProps> = ({youths, onSelect}) => {
    const dispatch = useDispatch();
    const currentProjectId = useCurrentProject().id;
    const currentUserId = useCurrentUser()?.id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [youthId, setYouthId] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<YouthGroup>();


    const groups = youths.reduce((prev, curr) => {
        curr.groups.forEach((currGroup) => {
            const exists = prev.some(prevGroup => prevGroup.id === currGroup.id);
            if (!exists) prev.push(currGroup);
        });
        return prev;
    }, [] as YouthGroup[]);

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorEl(e.currentTarget);
        setYouthId(id);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setYouthId("");
    }
    const handleDelete = () => {
        const youth = youths.find(y => y.id === youthId);
        dispatch(projectYouthActions.deleteElement(currentProjectId, youth))
        handleMenuClose();
    }
    const handleEdit = () => {
        const youth = youths.find(y => y.id === youthId) as Youth;
        onSelect(youth);
        handleMenuClose();
    }

    const handleGoToChat = () => {
        const youth = youths.find(y => y.id === youthId) as Youth;
        dispatch(push(Routes.chat + '/' + youth.chatThreadId))
        handleMenuClose();
    }

    const handleGoToJournal = () => {
        dispatch(journalActions.selectJournal(currentUserId, youthId));
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedGroup(undefined);
        setYouthId("");
    }
    const handlePickGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
        const group = groups.find(g => g.id === event.target.value);
        if (group) setSelectedGroup(group);
        setAnchorEl(null);
    };
    const handleAddToGroup = () => {
        if (selectedGroup) {
            const youth = youths.find(y => y.id === youthId);
            if (youth) {
                dispatch(projectYouthActions.updateElement(currentProjectId, {
                    ...youth,
                    groups: [...youth.groups, selectedGroup],
                }))
            }
        }
        handleClose();
    }

    const formattedYouths = youths.map(youthToList);

    return (
        <React.Fragment>
            <MenuTable
                heads={heads}
                rows={formattedYouths}
                onMenuClick={handleMenuClick}
            />
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <Edit2LineIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Rediger ung</Typography>
                </MenuItem>
                <MenuItem onClick={handleOpen}>
                    <ListItemIcon>
                        <UserAddLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">Tilføj til gruppe</Typography>
                </MenuItem>
                <MenuItem onClick={handleGoToChat}>
                    <ListItemIcon>
                        <ChatIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Gå til samtale</Typography>
                </MenuItem>
                <MenuItem onClick={handleGoToJournal}>
                    <ListItemIcon>
                        <JournalIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Gå til journal</Typography>
                </MenuItem>
                <DeleteMenuItem
                    title="Slet ung"
                    message="Er du sikker på, at du vil slette denne ung?"
                    onConfirm={handleDelete}
                />
            </Menu>
            <ConfirmDialog
                title="Vælg gruppe"
                open={open}
                onClose={handleClose}
                onConfirm={handleAddToGroup}
                disabled={selectedGroup === undefined}
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "xs"
                }}
            >
                <Select
                    value={selectedGroup?.id}
                    onChange={handlePickGroup}
                    fullWidth={true}
                >
                    {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                </Select>
            </ConfirmDialog>
        </React.Fragment>
    )
}

export default YouthListTable;
