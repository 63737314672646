import React, {useState} from 'react';
import {Box, Drawer, IconButton} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../store';
import ScrollableFeed from 'react-scrollable-feed';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MessageBlob from "../../components/MessageBlob";
import MessageInput from "../chat/MessageInput";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Fab from "@material-ui/core/Fab";
import {callActions} from "../../store/CallState/actions";
import SendPlaneLineIcon from "remixicon-react/SendPlaneLineIcon";
import QuestionLineIcon from "remixicon-react/QuestionLineIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    feed: {
        padding: theme.spacing(0, 1),
    },
    chatButton: {
        margin: theme.spacing(1),
        pointerEvents: 'auto',
        background: theme.palette.common.black,
        color: theme.palette.common.white,
        '&:hover': {
            opacity: 0.7
        }
    },
    paper: {
        height: "calc(100% - 16px)",
        width: theme.spacing(38),
        top: theme.spacing(1),
        left: theme.spacing(1),
        borderRadius: theme.spacing(2),
    },
    title: {flex: 1, fontWeight: 500, fontSize: 18},
    scrollBuffer: {height: 8, display: 'block'},
}));

const ChatDrawer: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [text, setText] = useState<string>('');
    const {localDeviceId, messages} = useSelector((state: AppState) => state.call);
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleCreateMessage = () => {
        dispatch(callActions.createMessage(text));
        setText('');
    };
    const handleChange = (text: string) => setText(text);

    return (
        <div className={classes.root}>
            <Fab className={classes.chatButton}
                 onClick={() => window.open('https://support.innosocial.dk/innovideo', "_blank")}>
                <QuestionLineIcon color="white"/>
            </Fab>
            <Fab className={classes.chatButton} onClick={() => setOpen(true)}>
                <SendPlaneLineIcon color="white"/>
            </Fab>
            <Drawer
                onClose={handleClose}
                open={open}
                PaperProps={{
                    elevation: 3,
                    className: classes.paper,
                }}
            >
                <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
                    <IconButton onClick={handleClose}>
                        <CloseLineIcon size={20}/>
                    </IconButton>
                </Box>
                <ScrollableFeed className={classes.feed}>
                    {messages.map((message) =>
                        <MessageBlob
                            key={message.id}
                            me={message.deviceId === localDeviceId}
                            message={message}
                        />
                    )}
                    <span className={classes.scrollBuffer}/>
                </ScrollableFeed>
                <MessageInput value={text} onSubmit={handleCreateMessage} onChange={handleChange}/>
            </Drawer>
        </div>
    )
};

export default ChatDrawer;
