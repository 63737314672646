import React, {useState} from "react";
import {InputProps} from "@material-ui/core/Input/Input";
import {FilledInput} from "@material-ui/core";
import EmojiPicker from "../../components/pickers/EmojiPicker";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        borderBottom: 'none',
    },
    rounded: {
        borderRadius: theme.spacing(3),
    }
}))

export interface MessageInputProps {
    value: string;
    onSubmit: (text: string) => void;
    onChange: (text: string) => void;
    placeholder?: string;
    variant?: "square" | "rounded";
}

const MessageInput: React.FC<MessageInputProps> =
    ({
         value = "",
         onSubmit,
         onChange,
         placeholder = "Aa",
         variant = "rounded"
     }) => {
        const classes = useStyles();
        const [shift, setShift] = useState(false);
        const [ctrl, setCtrl] = useState(false);

        const handleKeyDown: InputProps['onKeyDown'] = (e) => {
            if (e.key === 'Enter' && !shift && !ctrl) {
                e.preventDefault();
                if (value.trim().length === 0) {
                    onChange("");
                    return;
                }
                onSubmit(value);
                onChange("");
            }
            if (e.key === 'Shift') {
                setShift(true);
            } else if (e.key === 'Control') {
                setCtrl(true);
            }
        };

        const handleKeyUp: InputProps['onKeyUp'] = (e) => {
            if (e.key === 'Shift') {
                setShift(false);
            } else if (e.key === 'Control') {
                setCtrl(false);
            }
        };

        const handleChange: InputProps['onChange'] = e => onChange(e.target.value);

        const endAdornment = <EmojiPicker value={value} onChange={onChange}/>;

        return (
            <FilledInput
                value={value}
                name="content"
                type="text"
                autoComplete="message"
                fullWidth
                multiline
                rowsMax="4"
                placeholder={placeholder}
                disableUnderline={true}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                autoFocus
                endAdornment={endAdornment}
                className={clsx(classes.root, variant === "rounded" && classes.rounded)}
            />
        );
    };

export default MessageInput;