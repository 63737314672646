import Journal, {JournalEntry} from "../../models/Journal";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import RestResponse, {responseFromError} from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";


export type JournalServiceType = CrudPropertyServiceType<Journal> & {
    updateEntry: (journalId?: string, entry?: JournalEntry) => RestResponse<JournalEntry>;
    deleteEntry: (journalId?: string, entryId?: string) => RestResponse<JournalEntry>;


    getJournalByYouthId: (userId: string, youthId: string) => RestResponse<Journal | undefined>;
};

export const journalService = (client: RestClient): JournalServiceType => {
    return {
        ...crudPropertyService(client, '/api/web/v1/users', 'journals'),

        async updateEntry(journalId, entry) {
            if (!journalId?.length) return responseFromError(404)
            if(!entry?.id?.length) return client.fetchJSON(RequestMethod.POST, "/api/web/v1/journals/" + journalId + "/entries", {...entry})
            return client.fetchJSON(RequestMethod.PUT, "/api/web/v1/journals/" + journalId + "/entries/" + entry.id, {...entry});
        },
        async deleteEntry(journalId, journalEntryId) {
            if (!journalId?.length || !journalEntryId?.length) return responseFromError(404)
            return await client.fetchJSON(RequestMethod.DELETE, "/api/web/v1/journals/" + journalId + "/entries/" + journalEntryId);
        },

        getJournalByYouthId: async (userId, youthId) => {
            const response = await client.fetchJSON<Journal[]>(RequestMethod.GET, "/api/web/v1/users/" + userId + "/journals");
            if(!response.success) return response;

            const journal = response.value.find(j => j.youthId === youthId);
            if(journal === undefined) return {
                ...response,
                status: 404,
                value: undefined,
            }

            return {
                ...response,
                value: journal,
            };
        }
    };
};

export default journalService;
