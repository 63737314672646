import React, {useEffect, useState} from 'react'
import VerticalBar from "./VerticalBar";
import {emptyVerticalBarDataset, ModuleData} from "../../models/OverviewModel";
import useTypedSelector from "../../hooks/useTypedSelector";
import {toLocalDayDateString} from "../../lib/toLocalISO";

// TEMP
const ChatPlot: React.FC = () => {

    // TODO: ELASTIC for the win no this
    const state = useTypedSelector(state => state.chat);
    const [moduleData, setModuleData] = useState<ModuleData | undefined>()

    const {elements: threads} = state;


    useEffect(() => {
        const calcPlot = () => {
            const dates = threads.map((thread) => thread.messages.map(message => toLocalDayDateString(message.createdAt))).flat()
            const dataset = emptyVerticalBarDataset;
            const labels: string[] = []

            const arrFrequencies = dates.reduce((acc: { [p: string]: number }, value) =>
                ({...acc, [value]: acc[value] + 1 || 1}), {})

            Object.keys(arrFrequencies).forEach((k: string) => {
                dataset.data.push(arrFrequencies[k])
                labels.push(k)
            })

            const moduleData: ModuleData = {
                datasets: [dataset],
                labels: labels
            }

            if (moduleData.labels.length === 0) {
                return;
            }

            setModuleData(moduleData)
        }
        calcPlot()
    }, [threads]);

    return (
        <VerticalBar verticalBarModuleData={moduleData}/>
    )
}

export default ChatPlot