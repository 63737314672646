import React from "react";
import {Box, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FabToggleButton from "../../components/buttons/FabToggleButton";
import MicOffLineIcon from "remixicon-react/MicOffLineIcon";
import MicLineIcon from "remixicon-react/MicLineIcon";
import CameraLineIcon from "remixicon-react/CameraLineIcon";
import CameraOffLineIcon from "remixicon-react/CameraOffLineIcon";
import PhoneFillIcon from "remixicon-react/PhoneFillIcon";
import Fab from "@material-ui/core/Fab";


const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        position: 'absolute',
        zIndex: 3,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            // bottom: (props) => (props.active ? theme.spacing(props.bottom) : -100),
        },
        // bottom: (props) => theme.spacing(props.bottom / 2),
        left: 0,
        right: 0,
        transition: 'all 0.4s ease',
        pointerEvents: 'none',
    },
    endCall: {
        margin: theme.spacing(1),
        pointerEvents: 'auto',
        background: theme.palette.error.dark,
        '& svg': {
            transform: 'rotate(136deg)',
        }
    },
}));

interface VideoActionProps {
    audioEnabled: boolean;
    videoEnabled: boolean;
    onToggleAudio: () => void;
    onToggleVideo: () => void;
    onEndCall: () => void;
}

const VideoActions: React.FC<VideoActionProps> =
    ({
         audioEnabled,
         videoEnabled,
         onToggleAudio,
         onToggleVideo,
         onEndCall,
     }) => {
        const classes = useStyles();

        return (
            <Box className={classes.root} display="flex" justifyContent="center" alignItems="center" width="100%">
                <FabToggleButton onIcon={MicLineIcon} offIcon={MicOffLineIcon} on={audioEnabled} onToggle={onToggleAudio}/>
                <Fab onClick={onEndCall} className={classes.endCall}>
                    <PhoneFillIcon color="white"/>
                </Fab>
                <FabToggleButton onIcon={CameraLineIcon} offIcon={CameraOffLineIcon} on={videoEnabled} onToggle={onToggleVideo} />
            </Box>
        );
    };

export default VideoActions;
