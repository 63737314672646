import {useDispatch} from "react-redux";
import useTypedSelector from "./useTypedSelector";
import {useEffect} from "react";
import {AppState} from "../store";
import {projectUserActions} from "../store/ProjectUserState";


export const useProjectUserState = () => {
    const dispatch = useDispatch();
    const currentProjectId = useTypedSelector((state) => state.project.selectedElement?.id);
    const state = useTypedSelector((state: AppState) => state.users);

    useEffect(() => {
        if (state.elements?.length) return;
        dispatch(projectUserActions.refresh(currentProjectId));
    }, [currentProjectId, dispatch, state.elements])

    return state;
}

export default useProjectUserState;

