import React, {useEffect, useState} from "react";
import services from "../../services/rest/services";
import OverviewModule from "../../models/OverviewModel";
import Box from "@material-ui/core/Box";
import ChartComponent from "@reactchartjs/react-chart.js";
import {SurveyAnswers} from "../../models/Survey";
import useCurrentProject from "../../hooks/useCurrentProject";
import getChartColors from "../../lib/getChartColors";
import {Typography} from "@material-ui/core";

interface SurveyPlotProps {
    module: OverviewModule;
}

// TODO: Temp
const SurveyPlot: React.FC<SurveyPlotProps> = ({module}) => {

    const [answers, setAnswers] = useState<SurveyAnswers>();
    const currentProjectId = useCurrentProject().id;

    const chartData = (answerCount: { [p: string]: number }) => {
        const data = Object.values(answerCount);
        return ({
            labels: Object.keys(answerCount),
            datasets: [{
                backgroundColor: getChartColors(data.length),
                data: data,
            }]
        });
    };

    useEffect(() => {
        if (answers) return;
        const temp = async () => {
            if (module.fields.length > 0) {
                const response = await services.surveys.getAnswers(currentProjectId, module.fields[0]);
                if (response.success) {
                    setAnswers(response.value);
                }
            }
        }
        temp()
    }, [currentProjectId, module.fields, answers]);

    return (
        <React.Fragment>
            {answers?.questions.map(q => (
                <>
                    <Typography variant="h6">{q.title}</Typography>
                    <ChartComponent type="pie" data={chartData(q.answerCount)}/>
                </>
            ))}
            {!answers &&
            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                Ingen data
            </Box>
            }
        </React.Fragment>
    )
}

export default SurveyPlot