import React from "react";
import { useHistory } from 'react-router-dom';
import {LoginForm} from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import useTypedSelector from "../../hooks/useTypedSelector";
import LogoPage from "../../components/containers/LogoPage";
import Routes from "../../constants/Routes";


const LoginPage: React.FC = () => {
    const anchor = useTypedSelector(state => state.router?.location?.hash);
    const auth = useTypedSelector((state) => state.auth);
    const history = useHistory();

    if(auth.authenticated){
        history.replace(`${Routes.overview}`);
        return null;
    }

    const showForgotPassword = anchor === "#forgot-password";

    return (
        <LogoPage>
            {showForgotPassword ? <ForgotPasswordForm/> : <LoginForm/>}
        </LogoPage>
    )
}

export default LoginPage;

