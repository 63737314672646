import {Field, Form, Formik} from "formik";
import TextField from "@material-ui/core/TextField/TextField";
import React from "react";
import {Grid} from "@material-ui/core";
import FormikErrorDisplay from "../components/displays/FormikErrorDisplay";
import FormProps from "../models/FormProps";
import ProjectUser from "../models/ProjectUser";
import SelectNamed from "../components/inputs/SelectNamed";
import Validators from "../lib/Validators";
import splitName from "../lib/splitName";

export type ProjectUserFormProps = FormProps<ProjectUser>;
export const ProjectUserForm: React.FC<ProjectUserFormProps> = ({submitButtonRef, initial, onSubmit}) => {

    const initialValues = {
        id: initial?.id ?? '',
        email: initial?.email ?? '',
        phoneNumber: initial?.phoneNumber ?? '',
        name: initial?.name ?? '',
        active: initial?.active ?? true,
        ...splitName(initial?.name),
        roleId: initial?.roleId ?? '',
    }

    const handleSubmit = (values: ProjectUser) => {
        onSubmit && onSubmit({
            ...values,
            name: values.firstName + ' ' + values.lastName,
        })
    }

    return (
        <div>
            <Formik<ProjectUser>
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize={true}
            >
                {({values, errors, touched}) => {
                    return (
                        <Form>
                            <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.name}
                                        error={errors.firstName && touched.firstName && values.firstName?.length !== 0}
                                        helperText={values.firstName?.length !== 0 && errors.firstName}
                                        fullWidth
                                        variant='filled'
                                        id="firstName"
                                        label="Fornavn"
                                        name="firstName"
                                        type="text"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.name}
                                        error={errors.lastName && touched.lastName && values.lastName?.length !== 0}
                                        helperText={values.lastName?.length !== 0 && errors.lastName}
                                        fullWidth
                                        variant='filled'
                                        id="lastName"
                                        label="Efternavn"
                                        name="lastName"
                                        type="text"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        validate={Validators.email}
                                        error={errors.email && touched.email && values.email?.length !== 0}
                                        helperText={values.email?.length !== 0 && errors.email}
                                        required
                                        fullWidth
                                        variant='filled'
                                        id="email"
                                        label="Email"
                                        name="email"
                                        type="email"
                                        autoComplete='email'
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        validate={() => Validators.phoneNumber(values.phoneNumber)}
                                        error={errors.phoneNumber && touched.phoneNumber}
                                        helperText={errors.phoneNumber}
                                        fullWidth
                                        variant='filled'
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        label="Telefonnummer"
                                        type="phone"
                                        autoComplete='phone'
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        as={SelectNamed}
                                        options={[{
                                            id: 'std',
                                            name: 'Standard',
                                        }, {
                                            id: 'super',
                                            name: 'Super'
                                        }]}
                                        name="roleId"
                                        label="Rolle"
                                    />
                                </Grid>

                                {/*<Grid item xs={12} style={{paddingBottom: 0}}>*/}
                                {/*    <Field*/}
                                {/*        as={Checkbox}*/}
                                {/*        name="active"*/}
                                {/*    />*/}
                                {/*    <span>Aktiv</span>*/}
                                {/*</Grid>*/}

                            </Grid>

                            <FormikErrorDisplay errors={errors}/>

                        </Form>

                    );
                }}
            </Formik>
        </div>
    )
}
export default ProjectUserForm;