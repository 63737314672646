export const colors = [
    "#3998E7",
    "#A957D0",
    "#E35555",
    "#FFCF71",
    "#66DFC7",
];

const getChartColors = (len: number) => new Array(len).fill(0).map((e, i) => colors[i % colors.length]);

export default getChartColors;
