import SendMessageMenuItem from "../../components/menu/SendMessageMenuItem";
import {ListItemIcon, MenuItem} from "@material-ui/core";
import Calendar2LineIcon from "remixicon-react/Calendar2LineIcon";
import Typography from "@material-ui/core/Typography";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import Menu from "@material-ui/core/Menu";
import React from "react";
import {emptyCalendarEvent} from "../../models/CalendarEvent";
import {emptyParticipant} from "../../models/Participant";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import {projectYouthActions} from "../../store/YouthState";
import {AppState} from "../../store";
import {eventActions} from "../../store/EventState";
import {useDispatch} from "react-redux";
import useCurrentUser from "../../hooks/useCurrentUser";
import Youth from "../../models/Youth";
import useCurrentProject from "../../hooks/useCurrentProject";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";

interface YouthGroupMenuProps {
    anchorEl: HTMLElement | null;
    groupName: string | undefined;
    members: Youth[];
    onClose: VoidFunction;
}

const YouthGroupMenu: React.FC<YouthGroupMenuProps> = ({anchorEl, groupName, members, onClose}) => {

    const dispatch = useDispatch();
    const currentUser = useCurrentUser();
    const currentProjectId = useCurrentProject().id;
    const [, {setSelectedElement: setSelectedEvent}] = useReduxCrudPropertyState((state: AppState) => state.event, eventActions, currentProjectId);

    const handleCreateEvent = () => {
        if (groupName) {
            setSelectedEvent({
                ...emptyCalendarEvent,
                title: groupName,
                participants: [
                    {
                        ...emptyParticipant,
                        id: currentUser?.id ?? "",
                        name: currentUser?.firstName ?? "",
                    },
                    ...members,
                ]
            });
            dispatch(push(Routes.calendar));
        }
    }
    const handleDeleteGroup = () => {
        if (groupName) {
            const updatedYouths = members.map(y => ({
                ...y,
                groups: y.groups.filter(g => g.name !== groupName)
            }));
            dispatch(projectYouthActions.updateAll(currentProjectId, updatedYouths));
        }
        onClose();
    }

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <SendMessageMenuItem
                respondents={members}
                title="Send besked til gruppen"
                onClose={onClose}
            />
            <MenuItem onClick={handleCreateEvent}>
                <ListItemIcon>
                    <Calendar2LineIcon size={20}/>
                </ListItemIcon>
                <Typography variant="inherit">Opret gruppebegivenhed</Typography>
            </MenuItem>
            <DeleteMenuItem
                title="Slet gruppe"
                message="Er du sikker på, at du vil slette gruppen?"
                onConfirm={handleDeleteGroup}
            />
        </Menu>
    )
}

export default YouthGroupMenu;
