import React, {useState} from "react";
import Youth, {youthSearchFilter} from "../../models/Youth";
import BasePage from "../../components/containers/BasePage";
import BasePageToolbar from "../../components/containers/BasePageToolbar";
import {AppState} from "../../store";
import {projectYouthActions} from "../../store/YouthState";
import useCurrentProject from "../../hooks/useCurrentProject";
import YouthListTable from "./YouthListTable";
import YouthForm from "../../forms/YouthForm";
import ContentContainer from "../../components/containers/ContentContainer";
import FormDrawer from "../../components/drawers/FormDrawer";
import JournalForm from "../../forms/JournalForm";
import Journal from "../../models/Journal";
import {journalActions} from "../../store/JournalState"
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import useCurrentUser from "../../hooks/useCurrentUser";

export const YouthListPage = () => {
    const currentProjectId = useCurrentProject().id
    const currentUserId = useCurrentUser().id;
    const [youthState, {dispatch, setSelectedElement}] = useReduxCrudPropertyState((state: AppState) => state.youth, projectYouthActions, currentProjectId);
    const elements = youthState.elements;
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [submitButtonRef, handleSubmitClick] = useSubmitButtonRef();

    const [journalState, {updateElement, setSelectedElement: setJournal}] = useReduxCrudPropertyState((state: AppState) => state.journal, journalActions, currentUserId);
    const journals = journalState.elements;
    const [submitJournalButtonRef, handleSaveJournalClick] = useSubmitButtonRef();

    const handleJournalSubmit = async (journal: Journal) => {
        await updateElement(journal);
        const jId = journals.find((j) => j.youthId === journal.youthId);
        dispatch(push(Routes.journal + '/' + jId?.id));
    };



    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedElement();
    }
    const handleSelectYouth = (y: Youth) => {
        setSelectedElement(y);
        handleOpen();
    }
    const handleSubmit = ({id, birthDate, groups, postalCode, ...rest}: Youth) => {
        dispatch(projectYouthActions.updateElement(currentProjectId, {
            id: id ?? "",
            birthDate: birthDate ?? new Date(),
            groups: groups ?? [],
            postalCode: postalCode&&Number(postalCode),
            ...rest,
        }));
        handleClose();
    }
    const filteredYouths = elements.filter(youthSearchFilter(search));

    return (
        <BasePage>
            <BasePageToolbar
                search={search}
                onSearch={setSearch}
                permitted={true}
                onCreateClick={handleOpen}
            />
            <ContentContainer state={youthState} empty="Ingen unge">
                <YouthListTable
                    youths={filteredYouths}
                    onSelect={handleSelectYouth}
                />
            </ContentContainer>
            <FormDrawer
                title={youthState.selectedElement ? "Rediger ung" : "Opret ung"}
                open={open}
                onSave={handleSubmitClick}
                onCancel={handleClose}
                feedback={youthState.feedback}
            >
                <YouthForm
                    onSubmit={handleSubmit}
                    submitButtonRef={submitButtonRef}
                    initial={youthState.selectedElement}
                />
            </FormDrawer>
            <FormDrawer
                title={"Opret journal"}
                open={!!journalState.selectedElement}
                onCancel={() => setJournal(undefined)}
                loading={youthState.loading}
                onSave={handleSaveJournalClick}
            >
                <JournalForm
                    submitButtonRef={submitJournalButtonRef}
                    onSubmit={handleJournalSubmit}
                    initial={journalState.selectedElement}
                />
            </FormDrawer>
        </BasePage>
    )
}

export default YouthListPage;

