import UserProject from "../models/UserProject";
import Project from "../models/Project";
import services from "../services/rest/services";

import ProjectChannel from "../models/ProjectChannel";
import {crudActionTypes, crudReducer, CrudState, initialCrudState, KnownCrudAction} from "./CrudState";
import {crudRestActions, CrudRestActionType} from "./CrudRestActions";
import {AppThunkAction} from "./index";
import snackbar from "../services/snackbar";

const stateName = 'PROJECT_STATE';
export const projectStateName = stateName;

export type ProjectState = CrudState<Required<UserProject>> & {
    selectedElement: Project | undefined;
};

export const initialProjectState: ProjectState = {
    ...initialCrudState<Required<UserProject>>(),
    selectedElement: undefined,
};

export type ProjectActions = CrudRestActionType<UserProject> & {
    updateChannels: (projectId: string, channels: ProjectChannel[]) => AppThunkAction<KnownCrudAction<Project>>;
}

export const projectActions: ProjectActions = {
    ...crudRestActions<Project>(stateName, services.projects),
    refresh: (): AppThunkAction<KnownCrudAction<UserProject>> => {
        return async (dispatch) => {
            dispatch({name: stateName, type: crudActionTypes.LOADING});
            const response = await services.me.getProjects();
            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.REFRESH, elements: response.value});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback})
            }
        };
    },
    selectElement(element: UserProject | undefined): AppThunkAction<KnownCrudAction<UserProject>> {
        return async (dispatch) => {
            if (!element?.id) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});
            const response = await services.projects.get(element.id);
            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.SELECT, element: response.value});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback})
            }
        }
    },
    updateElement: (element?: Project) => {
        return async (dispatch) => {
            if (element === undefined) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});
            const response = element?.id ? await services.projects.update(element) : await services.projects.create(element);
            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.UPDATE, element: response.value});
                snackbar.success("Du har opdateret " + element.name);
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback});
                snackbar.showFeedback(response.feedback);
            }
        };
    },
    updateChannels(projectId, channels): AppThunkAction<KnownCrudAction<Project>> {
        return async (dispatch) => {
            dispatch({name: stateName, type: crudActionTypes.LOADING});
            const response = await services.projects.updateChannels(projectId, channels);
            if (response.success) {
                await dispatch({name: stateName, type: crudActionTypes.SELECT, element: response.value});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback})
            }
        }
    }
};

export const projectReducer = crudReducer<Required<UserProject>>(stateName, initialProjectState);

export default projectReducer;

