import ProjectUser from "../models/ProjectUser";
import services from "../services/rest/services";
import {crudActionTypes, crudReducer, CrudState, initialCrudState} from "./CrudState";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";
import snackbar from "../services/snackbar";

const stateName = 'PROJECT_USERS_STATE';

export type ProjectUserState = CrudState<Required<ProjectUser>>;

export const initialProjectUserState = initialCrudState<Required<ProjectUser>>();

export type ProjectUserActions = CrudRestPropertyActionType<ProjectUser>;
export const projectUserActions: ProjectUserActions = {
    ...crudRestPropertyActions<ProjectUser>(stateName, services.projectUsers),
    updateElement: (id, element) => {
        return async (dispatch) => {
            if (element === undefined) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});

            const response = element?.id ? await services.projectUsers.update(id, element) : await services.projectUsers.create(id, element);
            if (!response.success) {
                snackbar.showFeedback(response.feedback);
                return;
            }

            dispatch({name: stateName, type: crudActionTypes.UPDATE, element: response.value});
            const message = element?.id ? 'Oplysningerne er opdateret' : 'Din kollega vil nu modtage en mail, så de selv kan færdiggøre deres profil.';
            snackbar.success(message)
        };
    },
};

export const projectUserReducer = crudReducer<Required<ProjectUser>>(stateName, initialProjectUserState);

export default projectUserReducer;
