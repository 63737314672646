import React from "react";
import {AppBar, Paper, Toolbar} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ProjectList from "./ProjectList";
import ProjectForm from "../../forms/ProjectForm";
import services from "../../services/rest/services";
import Typography from "@material-ui/core/Typography";
import BoxButton from "../../components/buttons/BoxButton";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import useCrudRestState from "../../hooks/useCrudRestState";
import snackbar from "../../services/snackbar";


export const AdminPage: React.FC = () => {
    const [submitButtonRef, handleSaveClick] = useSubmitButtonRef();
    const [elements, editElement, setEditElement, updateElement, deleteElement] = useCrudRestState(services.projects);

    const handleAddClick = async () => {
        const response = await services.admin.addMe('' + editElement?.id);
        if (response.success) {
            snackbar.success("Du er blevet tilføjet til projektet " + editElement?.name);
        } else {
            snackbar.showFeedback(response.feedback);
        }
    }

    return (
        <div>
            <AppBar position='static'>
                <Toolbar>
                    Diti Dash
                </Toolbar>
            </AppBar>

            <Grid container style={{margin: 8}}>
                <Grid item xs={12} md={6}>
                    <Paper style={{margin: 8, overflow: 'scroll', height: 1156}}>
                        <div style={{padding: 20}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography variant={"h4"}>
                                    Rådgivningstilbud
                                </Typography>
                                <BoxButton onClick={() => setEditElement(undefined)}>
                                    Unselect
                                </BoxButton>
                            </div>

                            <ProjectList
                                elements={elements}
                                onClick={setEditElement}
                                onDelete={deleteElement}
                            />
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper style={{margin: 8, padding: 32}}>
                        <ProjectForm
                            submitButtonRef={submitButtonRef}
                            initial={editElement}
                            onSubmit={async (e) => {
                                const response = await updateElement(e);
                                if(!response.success){
                                    snackbar.showFeedback(response.feedback);
                                }
                            }}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 16}}>
                            <BoxButton onClick={handleAddClick}>
                                TILFØJ MIG
                            </BoxButton>
                            <BoxButton onClick={handleSaveClick}>
                                GEM
                            </BoxButton>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default AdminPage;