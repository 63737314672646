import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {SelectInputProps} from "@material-ui/core/Select/SelectInput";
import {authActions} from "../../store/AuthState";
import SelectNamed from "../../components/inputs/SelectNamed";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
    select: {
        borderBottom: 'none',
        '& .MuiInput-underline:before,.MuiInputBase-root:hover': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
        }
    }
}))


const ProjectSelector: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const state = useSelector((state: AppState) => state.project);
    const projects = state.elements ?? [];
    const currentProject = state.selectedElement;

    const handleChange: SelectInputProps['onChange'] = async (event) => {
        const id = event.target.value as string;
        await dispatch(authActions.updateToken(id));
    }

    if (projects.length === 1) return (
        <Box display="flex" alignItems="center" height="100%">
            {currentProject?.name}
        </Box>
    )

    return (
        <SelectNamed
            className={classes.select}
            label={''}
            value={currentProject?.id || ''}
            options={projects}
            variant="standard"
            onChange={handleChange}
        />
    )
}
export default ProjectSelector;