import React, {RefObject, useState} from 'react';

import FullCalendar, {DatesSetArg, ViewApi} from '@fullcalendar/react'
import daLocale from '@fullcalendar/core/locales/da';
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
import makeStyles from "@material-ui/core/styles/makeStyles";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import CalendarToolbar from "./CalendarToolbar";
import {toLocalEventTime} from "../../lib/toLocalISO";
import {eventStatusOptions} from "../../models/CalendarEvent";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        '& .fc-theme-standard th': {
            padding: '16px 0px',
            textTransform: 'capitalize',
            border: 'none',
            borderRight: '1px dashed #D4D4D9',
            fontSize: 16,
            letterSpacing: '0.05em',
        },
        '& .fc-theme-standard td': {
            border: 'none',
            borderRight: '1px dashed #D4D4D9',
        },
        '& .fc-scrollgrid': {
            border: 'none',
        },
        '& .fc-theme-standard tr th:last-child, td:last-child': {
            borderRight: 'none !important',
        },
        '& .fc-theme-standard .fc-col-header-cell': {
            borderBottom: '1px solid #EFEFF1',
        },
        '& .fc-theme-standard td.fc-day-today': {
            backgroundColor: '#EBEBEB',
        },
        '& .fc-theme-standard .fc-scrollgrid-sync-table': {
            minHeight: '100%',
        },
        '& .fc .fc-scroller-liquid-absolute': {
            position: 'relative',
        },
    },
    eventRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '2px 6px',
        padding: 10,
        backgroundColor: '#087EE1',
        color: '#ffffff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    eventTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '22px',
        letterSpacing: '0.06em',
    },
    eventDate: {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '18px',
        letterSpacing: '0.06em',
    }
}));

export type StyledCalendarProps = FullCalendar['props'] & {
    calendarRef: RefObject<FullCalendar>;
    onCreateClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

export const initialView = "dayGridWeek";
export const CalendarView: React.FC<StyledCalendarProps> = ({calendarRef, className, style, onCreateClick, ...props}) => {
    const classes = useStyles();

    const onDatesSet = ({view}: DatesSetArg) => setView(view);
    const [view, setView] = useState<Partial<ViewApi>>({
        title: '',
        type: '',
    });

    return (
        <div className={classes.root} style={style}>
            <CalendarToolbar
                calendarRef={calendarRef}
                view={view}
                onChangeView={setView}
                onCreateClick={onCreateClick}
            />
            <Divider/>
            <FullCalendar
                ref={calendarRef}
                height="100%" // auto
                locale={daLocale}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={false}
                editable={true}
                eventResizableFromStart={true}
                fixedWeekCount={false}
                datesSet={onDatesSet}
                initialView={initialView}
                views={{
                    dayGridDay: {
                        type: 'timeGrid',
                        duration: {days: 1},
                        buttonText: 'Dag'
                    },
                    dayGridFourDay: {
                        type: 'dayGrid',
                        hiddenDays: [0, 5, 6],
                        buttonText: '4 day',
                        visibleRange: function (currentDate) {
                            const day = currentDate.getDay();
                            const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
                            const start = new Date(new Date().setDate(diff));
                            const end = new Date(start.valueOf())
                            end.setDate(end.getDate() + 3);
                            return {start: start, end: end};
                        }
                    }
                }}
                dayHeaderFormat={{weekday: 'long', month: '2-digit', day: '2-digit'}}
                eventContent={(arg) => (
                    <>
                        <EventStatusLabel isCancelled={arg.event.extendedProps.isCancelled}/>
                        <div className={classes.eventRoot}>
                            <span className={classes.eventTitle}>{arg.event.title}</span>
                            <span className={classes.eventDate}>
                                {toLocalEventTime(arg.event.start, arg.event.end)}
                            </span>
                        </div>
                    </>
                )}
                // minTime={"08:00:00"}
                // maxTime={"22:00:00"}
                // header={false}
                {...props}
            />
        </div>
    );
};

export const EventStatusLabel: React.FC<{ isCancelled: boolean }> = ({isCancelled}) => {
    if (!isCancelled) return <></>;
    const label = eventStatusOptions.find(s => s.id === "cancelled")?.name;

    return (
        <div style={{
            pointerEvents: 'none',
            touchAction: 'none',
            position: 'absolute',
            width: 'calc(100% - 2px)',
            height: 'calc(100% - 8px)',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            zIndex: 100,
        }}>
            <div style={{
                background: '#E35555',
                borderTopRightRadius: 4,
                borderBottomLeftRadius: 4,
                color: 'white',
                fontWeight: 600,
                padding: '0px 6px 2px 6px',
                marginRight: '4px'
            }}>
                {label}
            </div>
        </div>
    )
}

export default CalendarView;
