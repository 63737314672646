import {Box} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {setVideoElement} from "../../store/CallState/lib";
import MicOffLineIcon from "remixicon-react/MicOffLineIcon";
import clsx from "clsx";
import UserLineIcon from "remixicon-react/UserLineIcon";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        width: 'unset',
    },
    flip: {
        '& video': {
            transform: 'rotateY(180deg)'
        },
    },
    video: {
        width: "100%",
        height: "100%"
    },
    videoOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: '#292828',
        zIndex: 1,
    },
    videoIcon: {
        width: 80, // (props) => (props.iconSize === 'small' ? 20 : 80),
        height: 80, // (props) => (props.iconSize === 'small' ? 20 : 80),
    },
    smallVideo: {
        height: 20,
        width: 20,
    },
    videoIconWrapper: {
        position: 'absolute',
        zIndex: 2,
        borderRadius: '50%',
        width: 150, // (props) => (props.iconSize === 'small' ? 50 : 150),
        height: 150, // (props) => (props.iconSize === 'small' ? 50 : 150),
        backgroundColor: '#4f4e4e',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    smallVideoWrapper: {
        width: 50,
        height: 50,
    },
    soundIcon: {
        position: 'absolute',
        zIndex: 2,
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        height: 50, // (props) => (props.iconSize === 'small' ? 15 : 50),
    },
    smallSound: { height: 15 }
}))

export interface StreamProps {
    stream: MediaStream;
    video?: boolean;
    audio?: boolean;
    small?: true;
    me?: true;
}

const Stream: React.FC<StreamProps> = ({
                                           stream,
                                           video= true,
                                           audio = true,
                                           small = false,
                                           me = false,
}) => {
    const classes = useStyles();
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!stream) return;
        setVideoElement(videoRef, stream);
    }, [stream]);

    return (
        <Box className={clsx(classes.root, me && classes.flip)}>
            {!video && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={clsx(classes.videoIconWrapper, small && classes.smallVideoWrapper)}
                >
                    <UserLineIcon color="white" className={clsx(classes.videoIcon, small && classes.smallVideo)} />
                </Box>
            )}
            {!audio && <MicOffLineIcon color="white" className={clsx(classes.soundIcon, small && classes.smallSound)} />}
            <video
                className={classes.video}
                ref={videoRef}
                playsInline
                autoPlay
                muted={me}
            />
        </Box>
    )
}

export default Stream;
