import services from "../services/rest/services";
import AppNotification from "../models/AppNotification";
import notificationClient, {NotificationClient} from "../services/websocket/notificationClient";
import {startSignalRConnection} from "./CallState/client";
import snackbar from "../services/snackbar";
import {
    initialSocketState,
    KnownSocketAction,
    socketActionNames,
    SocketActionType,
    SocketActionTypes, socketReducer,
    SocketState
} from "./SocketState";

const stateName = 'NOTIFICATION_STATE';
export const notificationStateName = stateName;

export type NotificationState = SocketState<Required<AppNotification>, NotificationClient>;

export const initialNotificationState: NotificationState = initialSocketState<Required<AppNotification>, NotificationClient>();

export type KnownNotificationAction = KnownSocketAction<Required<AppNotification>>;

export const notificationActionNames: SocketActionTypes = {
    ...socketActionNames,
};

export type NotificationActions = SocketActionType<KnownNotificationAction>;

export const notificationActions: NotificationActions = {
    connect: () => {
        return async (dispatch) => {
            const accessToken = services.client.getAccessToken();
            if (accessToken === undefined || accessToken === "undefined" || accessToken.length === 0) {
                console.log("Failed to connect to chat, because accessToken was undefined")
                return;
            }


            await dispatch({name: stateName, type: notificationActionNames.LOADING});

            const hubPath = 'notification';
            const client = notificationClient(accessToken);

            client.connection.on('refresh', (elements: AppNotification[]) => {
                console.log("refresh:", elements)
                dispatch({name: stateName, type: notificationActionNames.REFRESH, elements: elements});
            });

            client.connection.on('update', (element: AppNotification) => {
                console.log("update:", element)
                snackbar.showMessage(element.body);
                dispatch({name: stateName, type: notificationActionNames.UPDATE, element: element});
            });

            await startSignalRConnection(hubPath, client.connection);
            await dispatch({name: stateName, type: notificationActionNames.CONNECT, client});
        }
    },
    disconnect: () => {
        return async (dispatch) => {
            await initialNotificationState.client?.connection.stop();
            await dispatch({name: stateName, type: notificationActionNames.DISCONNECT});
        }
    },
};

export const notificationReducer = socketReducer<Required<AppNotification>, NotificationState>(stateName, initialNotificationState);

export default notificationReducer;
