import ChatThread, {ChatMessage} from "../models/ChatThread";
import chatClient, {ChatClient} from "../services/websocket/chatClient";
import {AppState} from "./index";
import {
    initialSocketState,
    KnownSocketAction,
    socketActionNames,
    SocketActionType,
    SocketActionTypes,
    socketReducer,
    SocketState
} from "./SocketState";

const stateName = 'CHATS_STATE';
export const chatStateName = stateName;

export type ChatState = SocketState<Required<ChatThread>, ChatClient>;

export const initialChatState: ChatState = initialSocketState<Required<ChatThread>, ChatClient>();

export type KnownChatAction = KnownSocketAction<Required<ChatThread>>;

export const chatActionNames: SocketActionTypes = {
    ...socketActionNames,
};

export type ChatActions = SocketActionType<KnownChatAction>;

export const chatActions: ChatActions = {
    connect: () => {
        return async (dispatch, getState) => {
            console.log('chatActions.connect');

            await dispatch({name: stateName, type: chatActionNames.LOADING});

            // disconnect from current connection if any
            const state = getState();
            console.log("state:", state);
            await state.chat.client?.connection?.stop()


            // create new client and set connection listeners
            const client = chatClient();

            client.connection.on('refresh', (threads: ChatThread[]) => {
                console.log("refresh:", threads)
                dispatch({name: stateName, type: chatActionNames.REFRESH, elements: threads});
            });

            client.connection.on('update', (thread: ChatThread) => {
                console.log("update:", thread)
                dispatch({name: stateName, type: chatActionNames.UPDATE, element: thread});
            });

            client.connection.on('update-message', (message: ChatMessage) => {
                console.log("update-message:", message)
                const state = getState() as AppState;
                const thread = state.chat.elements.find(e => e.id === message.threadId)
                if (!thread) return;
                if (message.author.id !== state.auth.currentUser?.id) {
                    thread.read = false;
                }

                thread.messages.push(message);
                const elements = state.chat.elements.filter(e => e.id !== message.threadId);
                elements.unshift(thread);
                dispatch({name: stateName, type: chatActionNames.REFRESH, elements: elements});
            });

            client.connection.on('update-thread', (thread: ChatThread) => {
                console.log("update-thread:", thread)
                const state = getState() as AppState;
                thread.messages = state.chat.elements.find(e => e.id === thread.id)?.messages ?? []
                dispatch({name: stateName, type: chatActionNames.UPDATE, element: thread});
            });

            client.connection.on("delete", (threadId: string) => {
                console.log("delete", threadId);
                dispatch({name: stateName, type: chatActionNames.DELETE, element: {id: threadId} as ChatThread});
            })

            // start connection with listeners
            client.connection.start()
                .then(() => console.log("chat connected"))
                .catch((e) => console.error(e));

            await dispatch({name: stateName, type: chatActionNames.CONNECT, client});
        }
    },
    disconnect: () => {
        return async (dispatch, getState) => {
            const state = getState();
            await state.chat.client?.connection?.stop()
            await dispatch({name: stateName, type: chatActionNames.DISCONNECT});
        }
    },
};

export const chatReducer = socketReducer<Required<ChatThread>, ChatState>(stateName, initialChatState);

export default chatReducer;
