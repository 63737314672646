import React from "react";
import Box from "@material-ui/core/Box";
import BoxButton from "../buttons/BoxButton";
import useIsMobile from "../../hooks/useIsMobile";
import BasePage from "./BasePage";
import BaseContainer from "./BaseContainer";

export interface MobileBasePageProps {
    buttonText?: string,
    onClick?: VoidFunction;
    p?: number | string;
    title?: string;
    onBack?: VoidFunction;
    permitted?: boolean;
}

export const MobileBasePage: React.FC<MobileBasePageProps> =
    ({
         p = "40px 16px 40px 16px",
         buttonText = 'Næste',
         onClick,
         children,
         onBack,
         permitted = true
     }) => {
        const isMobile = useIsMobile();
        if (isMobile) {
            return (
                <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <div style={{padding: p}}>
                        {children}
                    </div>

                    <Box flex={1}/>
                    {permitted && (
                        <BoxButton onClick={onClick}>
                            {buttonText}
                        </BoxButton>
                    )}
                </div>
            );
        }

        return (
            <BasePage>
                <BaseContainer onBack={onBack} flex={1}>
                    <Box p={p}>
                        {children}
                    </Box>
                    <Box flex={1}/>
                    {permitted && (
                        <BoxButton onClick={onClick}>
                            {buttonText}
                        </BoxButton>
                    )}
                </BaseContainer>
            </BasePage>
        )
    }

export default MobileBasePage;

