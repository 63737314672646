import React from 'react';
import Project from "../../models/Project";
import {List, ListItem} from "@material-ui/core";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonConfirm from "../../components/buttons/ButtonConfirm";
import CloseLineIcon from "remixicon-react/CloseLineIcon";


export interface ProjectListProps {
    elements: Project[];
    onClick?: (element: Project) => void;
    onDelete?: (element: Project) => void;
}

export const ProjectList: React.FC<ProjectListProps> = ({elements, onClick, onDelete}) => {

    return (
        <List>
            {elements?.map((project) =>
                <ListItem key={project.id} button onClick={() => onClick && onClick(project)}>
                    <ListItemAvatar>
                        <Avatar
                            alt={project.id} src={project.logo}
                            style={{width: 72, height: 72, marginRight: 16}}
                            imgProps={{style: {objectFit: 'contain',}}}
                        >
                            {project.id}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={project.name} secondary={project.slogan}/>
                    <ListItemSecondaryAction>
                        <ButtonConfirm
                            title={"Er du sikker på du vil slette dette rådgivningstilbud?"}
                            onConfirm={() => onDelete && onDelete(project)}
                        >
                            <Tooltip title={"SLET"}>
                                <CloseLineIcon/>
                            </Tooltip>
                        </ButtonConfirm>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </List>
    );
}

export default ProjectList;