import React, {useState} from "react";
import Journal, {entrySearchFilter, JournalEntry} from "../../models/Journal";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import {
    toLocalDateTimeString,
    toLocalDayDateString,
} from "../../lib/toLocalISO";
import IconButton from "@material-ui/core/IconButton";
import More2FillIcon from "remixicon-react/More2FillIcon";
import {Divider, ListItemIcon, MenuItem} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import Typography from "@material-ui/core/Typography";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import {journalActions} from "../../store/JournalState";
import {useDispatch} from "react-redux";
import SearchInput from "../../components/inputs/SearchInput";
import FilterIcon from "remixicon-react/Filter2LineIcon";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddLineIcon from "remixicon-react/AddLineIcon";

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
    },
    container: {
        position: 'relative',
        margin: theme.spacing(0, 2),
        padding: '16px 24px',
        backgroundColor: theme.palette.background.default,
        borderRadius: 7,
    },
    header: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        textAlign: 'start',
        fontSize: 16,
        fontWeight: 600,
        //color: theme.palette.grey["500"],
    },
    content: {
        fontSize: 16,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        textAlign: 'start',
    },
    subtitle: {
        margin: theme.spacing(0, 2),
        fontSize: 14,
        color: theme.palette.grey["500"],
        padding: theme.spacing(1),
    },
    more: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    taglist: {
        display: 'flex',
        marginTop: '8px',
    },
    tags: {
        borderRadius: 4,
        color: '#3c4043',
        backgroundColor: '#f1f3f4',
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5,
        padding: 5,
        fontSize: "1.1em",
        fontWeight: 400,
        borderBottom: "1px solid #808080",
    },
}));


interface JournalEntryListViewProps {
    journal: Journal;
    openDrawer: (b: boolean) => void;
    setSelectedEntry: (entry: JournalEntry) => void;
    search: string;
    onSearch: (search: string) => void;
    sortEntries: (b:boolean) => void;
}

export const JournalEntryListView: React.FC<JournalEntryListViewProps> = ({journal, openDrawer, setSelectedEntry, search, onSearch, sortEntries}) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const [selectedElement, setSelectedElement] = useState<JournalEntry>();

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>, entry: JournalEntry) => {
        setSelectedElement(entry);
        setAnchorEl(e.currentTarget);
    }

    const handleEditClick = () => {
        setAnchorEl(null);
        if (selectedElement) {
            setSelectedEntry(selectedElement);
        }
        openDrawer(true);
    }

    const handleDeleteEntry = () => {
        setAnchorEl(null);
        if (selectedElement) {
            dispatch(journalActions.deleteEntry(journal, selectedElement.id));
        }
    }

    const [sortMenuAnchorEl, setSortMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleCreateClick = () => {
        openDrawer(true);
    }

    const handleSortMenuClick = (e: React.MouseEvent<HTMLElement>) => {
            setSortMenuAnchorEl(e.currentTarget);
    }

    const filteredEntries = journal.entries.filter(entrySearchFilter(search));

    return (
        <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 5, paddingTop: 5, paddingLeft: 20, paddingRight: 20}}>
                <div style={{display: 'flex'}}>
                    <SearchInput search={search} onChange={onSearch} width={300} style={{marginRight: 5}}/>
                    <IconButton
                        aria-label="more"
                        aria-controls="more-menu"
                        aria-haspopup="true"
                        onClick={(e: React.MouseEvent<HTMLElement>) => handleSortMenuClick(e)}
                    >
                        <FilterIcon />
                    </IconButton>
                </div>
                <Box>
                    <Button
                        style={{borderRadius: 25, boxShadow: "none"}}
                        variant="contained"
                        color="primary"
                        onClick={handleCreateClick}
                    >
                        <AddLineIcon style={{marginRight: 2}}/>
                        Notat
                    </Button>
                </Box>
            </div>
            <Divider/>
            <Menu
                id="more-menu"
                anchorEl={sortMenuAnchorEl}
                keepMounted
                open={Boolean(sortMenuAnchorEl)}
                onClose={() => setSortMenuAnchorEl(null)}
            >
                <MenuItem onClick={() => {sortEntries(true); setSortMenuAnchorEl(null);}}>
                    <Typography variant="inherit">Sotere efter hændelsesdato</Typography>
                </MenuItem>
                <MenuItem onClick={() => {sortEntries(false); setSortMenuAnchorEl(null);}}>
                    <Typography variant="inherit">Sotere efter oprettelsesdato</Typography>
                </MenuItem>
            </Menu>
            <div style={{height: '100%', overflowY: 'scroll'}}>
                {filteredEntries.map((entry) => {

                    return (
                        <div key={entry.id} className={classes.root}>
                            <div className={classes.container}>
                                <div className={classes.more}>
                                    <IconButton
                                        key={entry.id}
                                        aria-label="more"
                                        aria-controls="more-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, entry)}
                                    >
                                        <More2FillIcon/>
                                    </IconButton>
                                </div>
                                <div style={{marginBottom: 8}}>
                                    <div className={classes.header}>
                                        {entry.name}
                                    </div>
                                    <div style={{fontSize: 14, color: theme.palette.grey["500"]}}>
                                        {`Hændelsesdato ${toLocalDayDateString(entry.occurrenceDate)}`}
                                    </div>
                                </div>
                                <div className={classes.content}>
                                    {entry.content}
                                </div>
                                <div className={classes.taglist}>
                                    {entry.tags.map((tag) => {
                                        return(
                                            <div className={classes.tags}>
                                                {tag.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={classes.subtitle}>
                                {`${entry.author.name} ${toLocalDateTimeString(entry.updatedAt)}`}
                            </div>
                            <Divider style={{marginLeft: 15, marginRight: 15}}/>
                        </div>
                    );
                })}
                <Menu
                    id="more-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                        setAnchorEl(null);
                        setSelectedElement(undefined);
                    }}
                >
                    <MenuItem onClick={() => handleEditClick()}>
                        <ListItemIcon>
                            <Edit2LineIcon/>
                        </ListItemIcon>
                        <Typography variant="inherit">Rediger notat</Typography>
                    </MenuItem>
                    <DeleteMenuItem
                        title="Slet notat"
                        message="Er du sikker på, at du vil slette dette notat?"
                        onConfirm={() => handleDeleteEntry()}
                    />
                </Menu>
            </div>
        </div>

    )
}

export default JournalEntryListView;