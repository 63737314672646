import React from "react";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {TextField} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {InputProps} from "@material-ui/core/Input/Input";

const minTimes = 1
const maxTimes = 99;
const weekdays: { [key: number]: string } = {
    0: "Søndag",
    1: "Mandag",
    2: "Tirsdag",
    3: "Onsdag",
    4: "Torsdag",
    5: "Fredag",
    6: "Lørdag",
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "flex-start",
    },
    check: {
        marginTop: theme.spacing(-1)
    },
    repeat: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    dayWrapper: {
        marginLeft: theme.spacing(1),
    },
    day: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: theme.spacing(3),
        paddingLeft: 0,
    },
    timesWrapper: {
        display: "flex",
        alignItems: "center",
    },
    times: {
        paddingTop: theme.spacing(1),
        width: theme.spacing(4),
        margin: theme.spacing(0, 1),
    }
}))

interface EventRepeatFieldProps {
    occurrences: number | undefined;
    startDate: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const EventRepeatField: React.FC<EventRepeatFieldProps> = ({occurrences, setFieldValue, startDate}) => {
    const classes = useStyles();

    const handleToggleRepeat: CheckboxProps['onChange'] = (event) => {
        if (event.target.checked) {
            setFieldValue('occurrences', 5, false);
        } else {
            setFieldValue('occurrences', undefined, false);
        }
    }
    const handleTimesChange: InputProps['onChange'] = e => {
        if (minTimes <= Number(e.target.value) && Number(e.target.value) <= maxTimes) {
            setFieldValue("occurrences", Number(e.target.value), false);
        }
    }

    return (
        <div className={classes.root}>
            <Checkbox
                color="primary"
                name="occurrences"
                id="occurrences"
                checked={Boolean(occurrences)}
                onChange={handleToggleRepeat}
                className={classes.check}
            />
            {!occurrences && <span>Gentag</span>}
            {occurrences && (
                <div className={classes.repeat}>
                    <div>
                        Gentag hver uge om {weekdays[new Date(startDate).getDay()]}en
                    </div>
                    <div className={classes.timesWrapper}>
                        Gentag
                        <TextField
                            value={occurrences}
                            type="number"
                            size="small"
                            onChange={handleTimesChange}
                            className={classes.times}
                        />
                        gange
                    </div>
                </div>
            )}
        </div>
    );
}

export default EventRepeatField;
