import React, {useEffect, useState} from "react";
import Youth, {YouthGroup} from "../../models/Youth";
import groupBy from "../../lib/groupBy";
import Center from "../../components/containers/Center";
import YouthGroupList from "./YouthGroupList";
import Box from "@material-ui/core/Box";
import YouthGroupTable from "./YouthGroupTable";

interface YouthGroupViewProps {
    youths: Youth[];
    search: string;
}

const YouthGroupView: React.FC<YouthGroupViewProps> = ({youths, search}) => {
    const [selectedGroupName, setSelectedGroupName] = useState<string>();

    const groupsOfYouths = groupBy(youths, (item: Youth) => item.groups, (group: YouthGroup) => group.name);

    useEffect(() => {
        if (!selectedGroupName && groupsOfYouths.length > 0) {
            setSelectedGroupName(groupsOfYouths[0].name);
        }
    }, [youths.length, selectedGroupName, groupsOfYouths])

    const selectedGroupMembers = groupsOfYouths.find(g => g.name === selectedGroupName)?.items ?? [];

    return (
        <Box height="100%" width="100%" display="flex">
            <YouthGroupList
                groupsOfYouths={groupsOfYouths}
                search={search}
                onSelect={setSelectedGroupName}
                selectedGroupName={selectedGroupName}
            />
            <Box height="100%" width="100%" display="flex">
                {selectedGroupName && (
                    <YouthGroupTable
                        youths={selectedGroupMembers}
                        selectedGroupName={selectedGroupName}
                    />
                )}
                {!selectedGroupName && (
                    <Center>
                        Ingen gruppe valgt
                    </Center>
                )}
            </Box>
        </Box>
    )
}

export default YouthGroupView;
