import React from "react";
import {Field, Form, useFormikContext} from "formik";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import OverviewModule from "../../models/OverviewModel";
import BarChartIcon from "remixicon-react/BarChartLineIcon"
import makeStyles from "@material-ui/core/styles/makeStyles";
import SurveyIcon from 'remixicon-react/SurveyLineIcon';
import ChatIcon from 'remixicon-react/Chat3LineIcon';
import PieCharIcon from 'remixicon-react/PieChart2LineIcon';
import OverviewModuleSurveyForm from "./OverviewModuleSurveyForm";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main
    },
    iconText: {
        marginLeft: theme.spacing(2)
    }
}))


interface OverviewModuleFormFieldsProps {
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

const OverviewModuleFormFields: React.FC<OverviewModuleFormFieldsProps> = ({submitButtonRef}) => {
    const {values, errors, touched} = useFormikContext<OverviewModule>();
    const classes = useStyles();

    return (
        <Box display='flex' width='100%'>
            <Form style={{flex: 1}}>
                <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Field
                            as={TextField}
                            error={errors.name && touched.name && values.name?.length !== 0}
                            helperText={values.name?.length !== 0 && errors.name}
                            fullWidth
                            id="name"
                            label="Titel"
                            variant='filled'
                            placeholder="Titel"
                            name="name"
                            type="text"
                        />
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <Field*/}
                    {/*        as={TextField}*/}
                    {/*        error={errors.color && touched.color && values.color?.length !== 0}*/}
                    {/*        helperText={values.color?.length !== 0 && errors.color}*/}
                    {/*        fullWidth*/}
                    {/*        id="color"*/}
                    {/*        label="Farve"*/}
                    {/*        disabled={true}*/}
                    {/*        variant='filled'*/}
                    {/*        placeholder="Farve"*/}
                    {/*        name="color"*/}
                    {/*        type="color"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel>Graf</InputLabel>
                            <Field
                                as={Select}
                                error={errors.type && touched.type && values.type?.length !== 0}
                                fullWidth
                                id="type"
                                variant='filled'
                                name="type"
                                type="text"
                            >
                                <MenuItem value={"bar"}>
                                    <Box display='flex' alignItems='center'>
                                        <BarChartIcon className={classes.icon}/>
                                        <span className={classes.iconText}>Søjle diagram</span>
                                    </Box>
                                </MenuItem>
                                <MenuItem value={"pie"}>
                                    <Box display='flex' alignItems='center'>
                                        <PieCharIcon className={classes.icon}/>
                                        <span className={classes.iconText}>Cirkel diagram</span>
                                    </Box>
                                </MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel>Aktivitet</InputLabel>
                            <Field
                                as={Select}
                                error={errors.service && touched.service && values.service?.length !== 0}
                                fullWidth
                                id="service"
                                variant='filled'
                                name="service"
                                type="text"
                            >
                                {values.type === "pie" && <MenuItem value={"survey"}>
                                    <Box display='flex' alignItems='center'>
                                        <SurveyIcon className={classes.icon}/>
                                        <span className={classes.iconText}>Spørgeskema</span>
                                    </Box>
                                </MenuItem>}
                                {(values.type === "bar" || !values.type) && <MenuItem value={"chat"}>
                                    <Box display='flex' alignItems='center'>
                                        <ChatIcon className={classes.icon}/>
                                        <span className={classes.iconText}>Chat</span>
                                    </Box>
                                </MenuItem>}
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel>Vis mig</InputLabel>
                            <Field
                                as={Select}
                                error={errors.aggregation && touched.aggregation && values.aggregation?.length !== 0}
                                fullWidth
                                id="aggregation"
                                disabled={!(values.service && values.type)}
                                variant='filled'
                                name="aggregation"
                                type="text"
                            >
                                {(values.type === "bar") && <MenuItem value={"count"}>
                                    <span>Optælling</span>
                                </MenuItem>}

                                {(values.service === "survey" && (values.type === "pie" || values.type === "radar")) &&
                                <MenuItem value={"distribution"}>
                                    <span>Fordeling</span>
                                </MenuItem>}

                            </Field>
                        </FormControl>

                        {/*TODO use map of logic */}
                        {values.service === "survey" &&
                        <OverviewModuleSurveyForm/>
                        }
                    </Grid>
                </Grid>

            </Form>
        </Box>

    )
}

export default OverviewModuleFormFields;