import services from "../services/rest/services";
import {crudReducer, CrudState, initialCrudState} from "./CrudState";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";
import OverviewModule from "../models/OverviewModel";

const stateName = 'OVERVIEW_STATE';
export const overviewStateName = stateName;

export type OverviewState = CrudState<Required<OverviewModule>>;

export const initialOverviewState = initialCrudState<Required<OverviewModule>>();

export type OverviewActions = CrudRestPropertyActionType<OverviewModule>;
export const overviewActions: OverviewActions = {
    ...crudRestPropertyActions<OverviewModule>(stateName, services.overview)
};

export const overviewReducer = crudReducer<Required<OverviewModule>>(stateName, initialOverviewState);

export default overviewReducer;
