import React from "react";
import ProjectForm from "../../forms/ProjectForm";
import {MobileBasePage} from "../../components/containers/MobileBasePage";
import {useDispatch} from "react-redux";
import Project from "../../models/Project";
import useCurrentProject from "../../hooks/useCurrentProject";
import {projectActions} from "../../store/ProjectState";
import SettingsContainer from "./SettingsContainer";
import useIsMobile from "../../hooks/useIsMobile";
import Grid from "@material-ui/core/Grid";
import ProjectChannelPage from "./ProjectChannelPage";
import ProjectLogoPage from "./ProjectLogoPage";
import BasePage from "../../components/containers/BasePage";
import ExpandedGrid from "../../components/material/ExpandedGrid";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import useTypedSelector from "../../hooks/useTypedSelector";

const ProjectPage: React.FC = () => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const projectState = useTypedSelector((state) => state.project)

    const [submitInfoButtonRef, handleSaveInfoClick] = useSubmitButtonRef();
    const currentProject = useCurrentProject();
    const onSubmit = async (o: Project) => {
        await dispatch(projectActions.updateElement(o));
    };

    if (isMobile) {
        return (
            <div style={{height: '100%', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: 1, position: 'relative'}}>
                    <MobileBasePage buttonText="Gem" onClick={handleSaveInfoClick} title='Projektindstillinger'>
                        <ProjectForm
                            submitButtonRef={submitInfoButtonRef}
                            initial={currentProject}
                            onSubmit={onSubmit}
                            hideId={true}
                        />
                    </MobileBasePage>
                </div>
            </div>
        );
    }
    return (
        <BasePage>
            <ExpandedGrid container spacing={2} style={{marginTop: 8, marginBottom: 8}}>
                <Grid item xs={12} md={6}>
                    <SettingsContainer
                        title="Informationer"
                        onSave={handleSaveInfoClick}
                        disabled={false}
                        loading={projectState.loading}
                    >
                        <ProjectForm
                            submitButtonRef={submitInfoButtonRef}
                            initial={currentProject}
                            onSubmit={onSubmit}
                            hideId={true}
                        />
                    </SettingsContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12} style={{paddingBottom: 16}}>
                        <SettingsContainer title="Upload logo" disabled={true}>
                            <ProjectLogoPage/>
                        </SettingsContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <SettingsContainer title="Kommunikation" disabled={false}>
                            <ProjectChannelPage/>
                        </SettingsContainer>
                    </Grid>
                </Grid>
            </ExpandedGrid>
        </BasePage>
    );
}

export default ProjectPage;