import React from "react";
import Journal, {journalSearchFilter} from "../../models/Journal";
import JournalView from "./JournalView";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import {useDispatch} from "react-redux";
import {journalActions} from "../../store/JournalState";
import SearchListView from "../../components/SearchListView";
import DualSplitPage from "../../components/containers/DualSplitPage";
import {Box} from "@material-ui/core";
import {AppState} from "../../store";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";
import useCurrentUser from "../../hooks/useCurrentUser";
import {useParams} from "react-router-dom";

const JournalPage: React.FC = () => {
    const dispatch = useDispatch();
    const currentUser = useCurrentUser();

    const [state] = useReduxCrudPropertyState((state: AppState) => state.journal, journalActions, currentUser.id);
    const journals = state.elements;

    const {journalId} = useParams<{ journalId: string }>();
    const journal = journals.find(e => e.id === journalId);

    const handleJournalClick = (j: Journal) => {
        dispatch(push(Routes.journal + '/' + j?.id));
    };

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <DualSplitPage>
                <SearchListView
                    selectedId={journal?.id}
                    elements={journals}
                    onElementClick={handleJournalClick}
                    searchFilter={journalSearchFilter}
                />
                <JournalView
                    journal={journal}
                />
            </DualSplitPage>
        </Box>
    );
}

export default JournalPage;