import React from "react";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {ActionNavItem} from "../../constants/NavigationList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CustomTheme} from "../../constants/theme";

const useStyles = makeStyles<CustomTheme>((theme) => ({
    link: {
        textDecoration: "none",
        color: "black",
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary.contrastText,
        },
    },
    active: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary.contrastText,
            textDecoration: "underline",
        },
    }
}))

interface AppBarActionProps {
    action: ActionNavItem;
    pathname: string;
}


const AppBarAction : React.FC<AppBarActionProps> = ({action, pathname}) => {
    const classes = useStyles();
    return(
        <Link key={action.path} to={action.path}
              className={clsx(classes.link, pathname === action.path ? classes.active : "")}>
            {action.component ?
                action.component
                :
                action.name
            }
        </Link>
    )
}

export default AppBarAction;