import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    JsonHubProtocol,
    LogLevel,
} from '@microsoft/signalr';
import config from "./config";

export const startSignalRConnection = async (hubPath: string, connection: HubConnection): Promise<void> => {
    try {
        await connection.start();
        console.info(hubPath + ' Connected');
    } catch (err) {
        console.error('Connection Error: ', err);
    }
};

export const stopSignalRConnection = async (hubPath: string, connection: HubConnection | undefined): Promise<void> => {
    if (connection) {
        try {
            await connection.stop();
        } catch (err) {
            console.error('Connection Error: ', err);
        }
    }
};

export const isClientConnectedState = (signalClient: HubConnection) => {
    if (!signalClient) return false;
    return signalClient.state === HubConnectionState.Connected;
};

export const appSignalClient = (hubPath: string, params: string): HubConnection => {
    const connectionHub = `${config.HOST}/${hubPath}?${params}`;
    const protocol = new JsonHubProtocol();
    const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

    const options = {
        transport,
        logMessageContent: false,
        logger: LogLevel.Error,
    };

    return new HubConnectionBuilder()
        .withUrl(connectionHub, options)
        .withHubProtocol(protocol)
        .withAutomaticReconnect()
        .build();
};
