import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Journal from "../../models/Journal";
import useTheme from "@material-ui/core/styles/useTheme";
import useTypedSelector from "../../hooks/useTypedSelector";
import Grid from "@material-ui/core/Grid";
import {
    toLocalDateTimeString,
    twoDigit
} from "../../lib/toLocalISO";
import sexOptions from "../../constants/sexOptions";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2, 2),
        padding: '16px 24px',
    },
    header: {
        textAlign: 'start',
        fontSize: 18,
        fontWeight: 450,
        marginBottom: 16,
    },
    description: {
        textAlign: 'start',
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        fontSize: 16,
        fontWeight: 400,
    },
    subtitle: {
        fontSize: 14,
        color: theme.palette.grey["500"],
        padding: theme.spacing(1),
    },
    infoBox: {
        position: "relative",
        height: 50,
        padding: 5,
        backgroundColor: "#EFEFF1",
        borderBottom: "1px solid #808080",
    },
    info: {
        fontSize: 16,
        fontWeight: 400,
        position: "absolute",
        bottom: 10,
    },
}));

const JournalGeneral: React.FC<{journal: Journal}> = ({journal}) => {
    const classes = useStyles();
    const theme = useTheme();
    const elements = useTypedSelector((state) => state.youth.elements);
    const youth = elements.find((y) => y.id === journal.youthId);

    const formatBirthDate = (input: Date|string) => {
        const date = new Date(input);
        return [twoDigit(date.getDate()), twoDigit(date.getMonth() + 1), date.getFullYear()].join("-");
    };


    return (
        <div className={classes.root}>
            <div>
                <div className={classes.header}>
                    Journal beskrivelse
                </div>
                <div className={classes.description}>
                    {journal.description}
                </div>
                <div className={classes.subtitle}>
                    {`${journal.author.name} ${toLocalDateTimeString(journal.updatedAt)}`}
                </div>
                <div className={classes.header} style={{marginTop: theme.spacing( 2)}}>
                    Ung info
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className={classes.infoBox}>
                          <div style={{fontSize: 12, color: "#838391"}}>Name</div>
                          <div className={classes.info}>{youth?.name??"Ikke angivet"}</div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.infoBox}>
                            <div style={{fontSize: 12, color: "#838391"}}>Køn</div>
                            <div className={classes.info}>{sexOptions.find((o) => o.id === youth?.sex)?.name??"Ikke angivet"}</div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.infoBox}>
                            <div style={{fontSize: 12, color: "#838391"}}>Fødselsdato</div>
                            <div className={classes.info}>{youth?.birthDate ? formatBirthDate(youth.birthDate) : "Ikke angivet"}</div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.infoBox}>
                            <div style={{fontSize: 12, color: "#838391"}}>Kommune</div>
                            <div className={classes.info}>{youth?.municipality?.name??"Ikke angivet"}</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}


export default JournalGeneral;


