import services from "../services/rest/services";
import {Survey} from "../models/Survey";

import {crudReducer, CrudState, initialCrudState} from "./CrudState";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";


const stateName = 'SURVEY_STATE';
export const surveyStateName = stateName;

export type SurveyState = CrudState<Required<Survey>>;

export const initialSurveyState = initialCrudState<Required<Survey>>();

export type SurveyActions = CrudRestPropertyActionType<Survey>;
export const surveyActions: SurveyActions = {
    ...crudRestPropertyActions<Survey>(stateName, services.surveys)
};

export const surveyReducer = crudReducer<Required<Survey>>(stateName, initialSurveyState);

export default surveyReducer;
