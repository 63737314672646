import {Message, RTCPeer, RTCPeerState} from "./models";

export type InvokeSignal = (callId: string, type: string, payload: string) => void;

// TODO: MAKE CALLSTREAm CONNECTED INTERFACE

export interface CallState {
    id?: string;
    localStreamActive: boolean;
    localId?: string;
    localDeviceId?: string;
    localStream: MediaStream;
    localAudioEnabled: boolean;
    localVideoEnabled: boolean;
    loading: boolean;
    activeStream: MediaStream | undefined;
    connected: boolean;
    joined: boolean;
    rtcPeerStates: RTCPeerState[];
    rtcPeers: RTCPeer[];
    messages: Message[];
    selectedId?: string;
    autoSelectedId?: string;
}

export const initialCallState: CallState = {
    id: undefined,
    localStreamActive: true,
    localId: undefined,
    localDeviceId: undefined,
    localStream: new MediaStream(),
    localAudioEnabled: true,
    localVideoEnabled: true,
    loading: false,
    activeStream: undefined,
    rtcPeerStates: [],
    rtcPeers: [],
    messages: [],
    connected: false,
    joined: false,
    selectedId: undefined,
    autoSelectedId: undefined,
};



