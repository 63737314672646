import {HOST} from "../rest/services";
import AppNotification from "../../models/AppNotification";
import signalRConnection from "./signalRConnection";
import {SocketClient} from "./socketClient";


export interface NotificationClient extends SocketClient {
    readAll: () => Promise<AppNotification[]>;
}

export const notificationClient = (token: string): NotificationClient => {
    const url = HOST + "/notification?access_token=" + token;
    const connection = signalRConnection(url);


    return {
        connection,
        readAll: () => {
            console.log("readAll")
            return connection.invoke("readAll").catch((error: unknown) => console.error(error));
        },

    }
}

export default notificationClient;