import searchFilter from "../lib/searchFilter";
import Municipality from "./Municipality";
import calculateAge from "../lib/calculateAge";
import {sexMap} from "../constants/sexOptions";
import {PostalMunicipality} from "./PostalData";
import {
    toLocalISODate, toLocalISOTime
} from "../lib/toLocalISO";

export interface YouthGroup {
    id: string;
    name: string;
}

interface Youth {
    id: string;
    email?: string;
    name: string;
    birthDate: Date;
    sex: string;
    projectId: string;
    projectName: string;

    groups: YouthGroup[];
    groupId?: string;
    groupName?: string;

    postalCode?: number;
    // postal?: { number?: number, name?: string },
    municipality?: Municipality;
    municipalityOptions?: PostalMunicipality[];

    createdAt?: Date;
    updatedAt?: Date;

    message?: string;
    chatThreadId?: string;
}

export interface ListYouth {
    name: string;
    age: number | null;
    sex: string;
    municipalityName?: string;
    createdAt: string;
    groupName?: string;
}

export const emptyYouth: Youth = {
    id: '',
    name: '',
    email: '',
    birthDate: new Date(),
    sex: '',
    postalCode: undefined,
    projectId: '',
    projectName: '',
    groupId: '',
    groupName: '',
    groups: [],
}

export const youthToList = (y: Youth): ListYouth => ({
    ...y,
    municipalityName: y.municipality?.name,
    age: calculateAge(y.birthDate),
    sex: sexMap[y.sex],
    createdAt: y.createdAt ? (toLocalISODate(y.createdAt) + " " + toLocalISOTime(y.createdAt)) : "",
    groupName: y.groups.map(g => g.name).join(", "),
})

export const youthSearchFilter = (search: string) => ({name}: Youth | ListYouth) => searchFilter({
    name: name,
}, search);

export default Youth;
