import React from "react";
import BaseContainer from "../../components/containers/BaseContainer";
import {Box, IconButton, ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import OverviewModule from "../../models/OverviewModel";
import More2FillIcon from "remixicon-react/More2FillIcon";
import Menu from "@material-ui/core/Menu";
import EditBoxLineIcon from "remixicon-react/EditBoxLineIcon";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import {useDispatch} from "react-redux";
import {overviewActions} from "../../store/OverviewState";
import useCurrentProject from "../../hooks/useCurrentProject";
import ChatPlot from "../../components/charts/ChatPlot";
import SurveyPlot from "../../components/charts/SurveyPlot";

interface OverviewTileProps {
    module: OverviewModule;
    onEditClick: (module: OverviewModule) => void;
}

const serviceMap: { [p: string]: string } = {
    survey: "Spørgeskema",
    chat: "Chat",
}

const OverviewTile: React.FC<OverviewTileProps> = ({module, onEditClick}) => {

    const currentProject = useCurrentProject();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleDelete = () => {
        dispatch(overviewActions.deleteElement(currentProject.id, module))
        handleMenuClose();
    }

    const handleEditClick = () => {
        onEditClick(module);
        handleMenuClose();
    }

    return (
        <BaseContainer background={module.color}>
            <Box width='100%' height='100%' position='relative'>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    <Box width='100%' height='100%' position='relative'>
                        <Box p={2}>
                            <Box display='flex' alignItems='center'>
                                <Box flex={1}>
                                <span style={{color: 'grey'}}>
                                    {module.service && serviceMap[module.service]}
                                </span>
                                    <h3 style={{margin: 0}}>
                                        {module.name}
                                    </h3>
                                </Box>
                                <Box>
                                    <IconButton onClick={handleMenuClick}>
                                        <More2FillIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box p={2}>
                            <Box width='calc(100% - 32px)' height='calc(100% - 32px)'>
                                {module.service === "chat" && <ChatPlot/>}
                                {module.service === "survey" && <SurveyPlot module={module}/>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*TODO SEE ABOVE*/}
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEditClick}>
                    <ListItemIcon>
                        <EditBoxLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">{"Rediger modul"}</Typography>
                </MenuItem>
                <DeleteMenuItem
                    title="Slet modul"
                    message="Er du sikker på, at du vil slette dette modul?"
                    onConfirm={handleDelete}
                />
            </Menu>
        </BaseContainer>
    )
}


export default OverviewTile;