import React from "react";
import useCurrentProject from "../../hooks/useCurrentProject";
import Grid from "@material-ui/core/Grid";
import AvatarInput from "../../components/inputs/AvatarInput";
import services from "../../services/rest/services";
import {useDispatch} from "react-redux";
import {projectActions} from "../../store/ProjectState";
import randomInt from "../../lib/randomInt";


const ProjectLogoPage: React.FC = () => {
    const dispatch = useDispatch();
    const currentProject = useCurrentProject();

    return (
        <Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                <AvatarInput
                    name={currentProject.name}
                    src={currentProject.logo}
                    onChange={async (file) => {
                        const response = await services.projects.updateImage(currentProject.id, file)
                        const url = response.success ? response.value.logo : '';
                        const updatedProject = {
                            ...currentProject,
                            logo: url + "?rand" + randomInt(),
                        };
                        dispatch(projectActions.updateElement(updatedProject));
                    }}/>
            </Grid>
        </Grid>
    );
}

export default ProjectLogoPage;