import React from "react";
import {Field, FieldArray, Form, useFormikContext} from "formik";
import {Survey} from "../../models/Survey";
import {Box, Grid, IconButton} from "@material-ui/core";
import {emptySurveyQuestion, SurveyQuestion} from "../../models/SurveyQuestion";
import Tooltip from "@material-ui/core/Tooltip";
import DragDropButton from "../../components/material/DragDropButton";
import TextField from "@material-ui/core/TextField";
import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

interface SurveyFormViewProps {
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

const SurveyFormFields: React.FC<SurveyFormViewProps> = ({submitButtonRef}) => {

    const {values, errors, touched} = useFormikContext<Survey>();

    return (
        <Box display='flex' width='100%'>
            <Form style={{flex: 1}} onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }}>
                <Box>
                    <Grid container spacing={1}>
                        <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                error={errors.name && touched.name && values.name?.length !== 0}
                                helperText={values.name?.length !== 0 && errors.name}
                                fullWidth
                                id="name"
                                variant='filled'
                                placeholder="Spørgeskema titel"
                                name="name"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                error={errors.description && touched.description && values.description?.length !== 0}
                                helperText={values.description?.length !== 0 && errors.description}
                                fullWidth
                                rowsMax={100}
                                row={4}
                                id="description"
                                multiline
                                variant='filled'
                                placeholder="Spørgeskema beskrivelse"
                                name="description"
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <FieldArray
                    name="questions"
                    render={arrayHelpers => (
                        <Box pt={2}>
                            {values.questions?.map((question: SurveyQuestion, index: number) => (
                                <Box pb={1}>
                                    <Box display='flex' alignItems='center'>
                                        <Field
                                            as={TextField}
                                            id={`questions[${index}].title`}
                                            name={`questions[${index}].title`}
                                            placeholder={'Spørgsmål ' + (index + 1)}
                                            type="text"
                                            variant='filled'
                                            style={{flex: 1}}
                                        />
                                        <Tooltip title={'Slet spørgsmål'}>
                                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                                                <DeleteBin7LineIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <FieldArray
                                        name={`questions[${index}].properties.choices`}
                                        render={arrayHelpers => question.properties.choices.map((choice, optionIndex) =>
                                            <Box key={optionIndex} display='flex' alignItems='center' pl={4} pt={1}>
                                                <Field
                                                    as={TextField}
                                                    name={`questions[${index}].properties.choices[${optionIndex}]`}
                                                    placeholder={`Valgmulighed ${optionIndex + 1}`}
                                                    error={errors.questions?.[index] === undefined ? undefined : (errors.questions[index] as SurveyQuestion).properties.choices[optionIndex]}
                                                    helperText={errors.questions?.[index] === undefined ? undefined : (errors.questions[index] as SurveyQuestion).properties.choices[optionIndex]}
                                                    type="text"
                                                    variant='filled'
                                                    validate={(s?: string) => {
                                                        if (s === undefined || s.trim().length === 0) {
                                                            return "Svarmuligheden kan ikke være tom";
                                                        }
                                                    }}
                                                    style={{flex: 1}}
                                                    onClick={() => {
                                                        if (optionIndex + 1 === question.properties.choices.length) {
                                                            arrayHelpers.push('');
                                                        }
                                                    }}
                                                />
                                                {(question.properties.choices.length !== 1) && <Box>
                                                    <Tooltip title={'Slet valgmulighed'}>
                                                        <IconButton onClick={() => arrayHelpers.remove(optionIndex)}>
                                                            <CloseLineIcon fontSize={"small"} style={{fontSize: 16}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>}
                                            </Box>
                                        )}
                                    />
                                </Box>
                            ))}
                            <Box pt={2}/>
                            <DragDropButton onClick={() => arrayHelpers.push(emptySurveyQuestion)} fullWidth>
                                Tilføj spørgsmål
                            </DragDropButton>
                        </Box>
                    )}
                />
            </Form>
        </Box>

    )
}

export default SurveyFormFields;