import services from "../services/rest/services";
import Youth from "../models/Youth";
import {crudActionTypes, crudReducer, CrudState, initialCrudState, KnownCrudAction} from "./CrudState";
import {AppThunkAction} from "./index";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";

const stateName = 'YOUTH_STATE';

export type YouthState = CrudState<Required<Youth>>;

export const initialYouthState = initialCrudState<Required<Youth>>();

export type YouthActions = CrudRestPropertyActionType<Youth> & {
    updateAll: (projectId: string, youths: Youth[]) => AppThunkAction<KnownCrudAction<Youth>>
};

export const projectYouthActions: YouthActions = {
    ...crudRestPropertyActions<Youth>(stateName, services.youth),
    updateAll: (projectId, youths): AppThunkAction<KnownCrudAction<Youth>> => {
        return async (dispatch) => {
            dispatch({name: stateName, type: crudActionTypes.LOADING});
            const response = await services.youth.updateAll(projectId, youths);
            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.REFRESH, elements: response.value});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback})
            }
        };
    },
};

export const youthReducer = crudReducer<Required<Youth>>(stateName, initialYouthState);

export default youthReducer;
