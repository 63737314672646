import {AuthState} from '../store/AuthState';
import {AppState, initialAppState} from "../store";
import services from "./rest/services";

const AUTH_STATE_KEY = 'AUTH_STATE';
const PROJECT_STATE_KEY = 'PROJECT_STATE';

export const loadState = <S>(key: string): S => {
    const serializedState = localStorage.getItem(key) ?? sessionStorage.getItem(key);
    return serializedState === null ? undefined : JSON.parse(serializedState);
};

export const saveState = (key: string, state: unknown): void => {
    const serializedState = JSON.stringify(state);
    const remember = services.client.getRemember();
    remember ? localStorage.setItem(key, serializedState)
        : sessionStorage.setItem(key, serializedState);
};

export const loadAppState = (): AppState => {
    return {
        ...initialAppState,
        auth: loadAuthState(),
        project: loadState(PROJECT_STATE_KEY),
    }
}

export const saveAppState = (state: AppState): void => {
    saveAuthState(state.auth);
    saveState(PROJECT_STATE_KEY, state.project);
};

export const loadAuthState = (): AuthState => {
    const state = loadState<AuthState>(AUTH_STATE_KEY);
    if (state !== undefined) {
        state.loading = false;
    }
    return state;
};

export const saveAuthState = (state: AuthState): void => {
    saveState(AUTH_STATE_KEY, state);
    services.client.setRemember(state.remember);
};


export const clearStorage = (): void => {
    console.log('clearStorage');
    sessionStorage.clear();
    localStorage.clear();
};