import {CallHistoryMethodAction} from "connected-react-router";
import {HubConnection} from "@microsoft/signalr";
import {
    CallRemoteState,
    emptyMessage,
    emptyRTCPeer,
    LocalRTCPeerState,
    Message,
    RTCPeer,
    RTCPeerState,
    Signal,
    StreamType
} from "./models";
import {getOrSetDeviceUUID, isStreamEnabled} from "./lib";
import SimplePeer from "simple-peer";
import config from "./config";
import {appSignalClient, isClientConnectedState, startSignalRConnection, stopSignalRConnection} from "./client";
import {AppState, AppThunkAction} from "../index";

export interface CallActionTypes {
    readonly LOADING: 'CALL_LOADING';
    readonly CONNECT: 'CALL_CONNECT';
    readonly JOIN: 'CALL_JOIN';
    readonly RESET: 'CALL_RESET';
    readonly DISCONNECT: 'CALL_DISCONNECT';
    readonly REFRESH_STREAM: 'CALL_REFRESH_STREAM';
    readonly UPDATE_STREAM: 'CALL_UPDATE_STREAM';
    readonly REFRESH_REMOTE_STATE: 'CALL_REFRESH_REMOTE_STATE';
    readonly JOIN_RTC_PEER: 'CALL_JOIN_RTC_PEER';
    readonly SET_RTC_PEER_STREAM: 'CALL_SET_RTC_PEER_STREAM';
    readonly LEFT_RTC_PEER: 'CALL_LEFT_RTC_PEER';
    readonly READ_RTC_PEER_STATE: 'CALL_READ_RTC_PEER_STATE';
    readonly SET_SELECTED_ID: 'CALL_SET_SELECTED_ID';
    readonly SET_AUTO_SELECTED_ID: 'CALL_SET_AUTO_SELECTED_ID';
    readonly SET_ACTIVE_STREAM: 'CALL_SET_ACTIVE_STREAM';
    readonly READ_MESSAGE: 'CALL_READ_MESSAGE';
}

export const callActionsNames: CallActionTypes = {
    LOADING: 'CALL_LOADING',
    CONNECT: 'CALL_CONNECT',
    JOIN: 'CALL_JOIN',
    RESET: 'CALL_RESET',
    DISCONNECT: 'CALL_DISCONNECT',
    REFRESH_STREAM: 'CALL_REFRESH_STREAM',
    UPDATE_STREAM: 'CALL_UPDATE_STREAM',
    REFRESH_REMOTE_STATE: 'CALL_REFRESH_REMOTE_STATE',
    JOIN_RTC_PEER: 'CALL_JOIN_RTC_PEER',
    SET_RTC_PEER_STREAM: 'CALL_SET_RTC_PEER_STREAM',
    LEFT_RTC_PEER: 'CALL_LEFT_RTC_PEER',
    READ_RTC_PEER_STATE: 'CALL_READ_RTC_PEER_STATE',
    SET_SELECTED_ID: 'CALL_SET_SELECTED_ID',
    SET_AUTO_SELECTED_ID: 'CALL_SET_AUTO_SELECTED_ID',
    SET_ACTIVE_STREAM: 'CALL_SET_ACTIVE_STREAM',
    READ_MESSAGE: 'CALL_READ_MESSAGE',
};

export interface CallLoadingAction {
    type: 'CALL_LOADING';
}

export interface CallConnectAction {
    type: 'CALL_CONNECT';
    id: string;
    deviceId: string;
    connectionId: string;
}

export interface CallJoinAction {
    type: 'CALL_JOIN';
}

export interface CallResetAction {
    type: 'CALL_RESET';
}

export interface CallDisconnectAction {
    type: 'CALL_DISCONNECT';
}

export interface CallRefreshStreamAction {
    type: 'CALL_REFRESH_STREAM';
    stream: MediaStream;
}

export interface CallUpdateStreamAction {
    type: 'CALL_UPDATE_STREAM';
    stream: MediaStream;
}

export interface CallRefreshRemoteStateAction {
    type: 'CALL_REFRESH_REMOTE_STATE';
    remoteState: CallRemoteState;
}

export interface CallJoinRTCPeerAction {
    type: 'CALL_JOIN_RTC_PEER';
    rtcPeer: RTCPeer;
}

export interface CallSetRTCPeerStreamAction {
    type: 'CALL_SET_RTC_PEER_STREAM';
    callerId: string;
    stream: MediaStream;
}

export interface CallLeftRTCPeerAction {
    type: 'CALL_LEFT_RTC_PEER';
    callerId: string;
}

export interface CallReadRTCPeerStateAction {
    type: 'CALL_READ_RTC_PEER_STATE';
    rtcPeerState: RTCPeerState;
}

export interface CallSetSelectedIdAction {
    type: 'CALL_SET_SELECTED_ID';
    id?: string;
}

export interface CallSetAutoSelectedIdAction {
    type: 'CALL_SET_AUTO_SELECTED_ID';
    id?: string;
}

export interface CallSetActiveStreamAction {
    type: 'CALL_SET_ACTIVE_STREAM';
    stream?: MediaStream;
}

export interface CallReadMessageAction {
    type: 'CALL_READ_MESSAGE';
    message: Message;
}

export type CallAction =
    | CallLoadingAction
    | CallConnectAction
    | CallJoinAction
    | CallResetAction
    | CallDisconnectAction
    | CallRefreshStreamAction
    | CallUpdateStreamAction
    | CallRefreshRemoteStateAction
    | CallJoinRTCPeerAction
    | CallSetRTCPeerStreamAction
    | CallLeftRTCPeerAction
    | CallReadRTCPeerStateAction
    | CallSetSelectedIdAction
    | CallSetAutoSelectedIdAction
    | CallSetActiveStreamAction
    | CallReadMessageAction;

interface CallActions {
    connect(callId: string): AppThunkAction<CallAction>;

    join(): AppThunkAction<CallAction, AppState>;

    disconnect(callId: string): AppThunkAction<CallAction | CallHistoryMethodAction>;

    setVideoStream(stream: MediaStream): AppThunkAction<CallAction>;

    updateStream(type: StreamType): AppThunkAction<CallAction | CallHistoryMethodAction, AppState>;

    createMessage(content: string): AppThunkAction<CallAction>;

    setSelectedId(id?: string): AppThunkAction<CallAction>;

    setAutoSelectedId(id?: string): AppThunkAction<CallAction>;

    setActiveStream(stream?: MediaStream): AppThunkAction<CallAction>;
}

let signalClient: HubConnection;

const setPeerStateFromStream = (stream: MediaStream, id?: string): LocalRTCPeerState => {
    return {
        id: id,
        audioEnabled: isStreamEnabled(StreamType.audio, stream),
        videoEnabled: isStreamEnabled(StreamType.video, stream),
    };
};

export const callActions: CallActions = {
    connect: (callId) => {
        return async (dispatch, getState) => {
            await dispatch({type: callActionsNames.LOADING});

            const hubPath = 'callHub';
            await stopSignalRConnection(hubPath, signalClient);

            const uuid = getOrSetDeviceUUID(callId);
            signalClient = appSignalClient(hubPath, 'callId=' + callId + '&deviceId=' + uuid);

            await startSignalRConnection(hubPath, signalClient);
            const connectionId = signalClient.connectionId ?? '';

            await dispatch({
                type: callActionsNames.CONNECT,
                id: callId,
                deviceId: uuid,
                connectionId,
            });
        };
    },
    join: () => {
        return async (dispatch, getState) => {
            const callState = getState().call;

            signalClient.on('readLeave', (callerId: string) => {
                dispatch({type: callActionsNames.LEFT_RTC_PEER, callerId});
            });

            signalClient.on('readPeerState', (peer: RTCPeerState) => {
                dispatch({type: callActionsNames.READ_RTC_PEER_STATE, rtcPeerState: peer});
            });

            signalClient.on('readMessage', (message: Message) => {
                dispatch({type: callActionsNames.READ_MESSAGE, message});
            });

            signalClient.on('readRemoteState', (callRemoteState: CallRemoteState) => {
                const stream = callState.localStream;

                callRemoteState.peers.forEach((rtcPeerState) => {
                    const newPeer = new SimplePeer({
                        initiator: true,
                        config: config.webRTCConfig,
                        stream,
                    });

                    newPeer.on('signal', (signal) => {
                        signalClient.invoke('signal', rtcPeerState.id, 'readJoin', signal);
                    });

                    newPeer.on('stream', (stream) => {
                        dispatch({
                            type: callActionsNames.SET_RTC_PEER_STREAM,
                            callerId: rtcPeerState.id,
                            stream,
                        });
                    });

                    dispatch({
                        type: callActionsNames.JOIN_RTC_PEER,
                        rtcPeer: {
                            ...emptyRTCPeer,
                            id: rtcPeerState.id,
                            rtc: newPeer,
                        },
                    });
                });

                dispatch({type: callActionsNames.REFRESH_REMOTE_STATE, remoteState: callRemoteState});
            });

            signalClient.on('readJoin', (joinSignal: Signal) => {
                const callState = getState().call;
                const rtcPeer = callState.rtcPeers.find((rtcPeer: { id: string; }) => rtcPeer.id === joinSignal.callerId);

                if (rtcPeer) {
                    rtcPeer.rtc.signal(joinSignal.signal);
                    return;
                }

                const newPeer = new SimplePeer({
                    initiator: false,
                    config: config.webRTCConfig,
                    stream: callState.localStream,
                });

                newPeer.on('signal', (signal) => {
                    signalClient.invoke('signal', joinSignal.callerId, 'readSignal', signal);
                });

                newPeer.signal(joinSignal.signal);

                newPeer.on('stream', (stream) => {
                    dispatch({
                        type: callActionsNames.SET_RTC_PEER_STREAM,
                        callerId: joinSignal.callerId,
                        stream,
                    });
                });

                dispatch({
                    type: callActionsNames.JOIN_RTC_PEER,
                    rtcPeer: {
                        ...emptyRTCPeer,
                        id: joinSignal.callerId,
                        rtc: newPeer,
                    },
                });
            });

            signalClient.on('readSignal', (signal: Signal) => {
                const rtcPeer = getState().call.rtcPeers.find((rtcPeer: { id: string; }) => rtcPeer.id === signal.callerId);
                if (rtcPeer) rtcPeer.rtc.signal(signal.signal);
            });

            const peer = setPeerStateFromStream(callState.localStream, callState.localId);
            await signalClient.invoke('join', peer);
            await dispatch({type: callActionsNames.JOIN});
        };
    },
    disconnect: (callId: string) => {
        return async (dispatch, getState) => {
            dispatch({type: callActionsNames.DISCONNECT});
            if (isClientConnectedState(signalClient)) await signalClient.stop();
        };
    },
    setVideoStream: (stream: MediaStream) => {
        return async (dispatch) => {
            await dispatch({type: callActionsNames.REFRESH_STREAM, stream: stream});
        };
    },
    updateStream: (type) => {
        return async (dispatch, getState) => {
            const callState = getState().call;

            if (!callState.localStream) return;

            if (type === StreamType.video) {
                callState.localStream?.getVideoTracks().forEach((track: { enabled: boolean; }) => {
                    track.enabled = !track.enabled;
                });
            }

            if (type === StreamType.audio) {
                callState.localStream.getAudioTracks().forEach((track: { enabled: boolean; }) => {
                    track.enabled = !track.enabled;
                });
            }

            await dispatch({type: callActionsNames.UPDATE_STREAM, stream: callState.localStream});

            if (callState.joined) {
                const peer = setPeerStateFromStream(callState.localStream, callState.localId);
                await signalClient.invoke('updatePeer', peer);
            }
        };
    },
    createMessage(content: string) {
        return async () => {
            const message: Message = {
                ...emptyMessage,
                callerId: signalClient.connectionId ?? '',
                content,
            };
            await signalClient.invoke('createMessage', message);
        };
    },
    setSelectedId: (id?: string) => {
        return async (dispatch) => {
            dispatch({type: callActionsNames.SET_SELECTED_ID, id});
        };
    },
    setAutoSelectedId: (id?: string) => {
        return async (dispatch) => {
            dispatch({type: callActionsNames.SET_AUTO_SELECTED_ID, id});
        };
    },
    setActiveStream: (stream?: MediaStream) => {
        return async (dispatch) => {
            dispatch({type: callActionsNames.SET_ACTIVE_STREAM, stream});
        };
    },
};