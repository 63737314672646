import clsx from "clsx";
import Box from "@material-ui/core/Box";
import VideoMessage from "../pages/chat/VideoMessage";
import React from "react";
import {ChatMessage} from "../models/ChatThread";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Message} from "../store/CallState/models";
import {toLocalDateTimeString} from "../lib/toLocalISO";

const useStyles = makeStyles((theme) => ({
    message: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    me: { alignItems: 'flex-end' },
    msgContent: {
        display: 'flex',
        textAlign: 'start',
        borderRadius: 20,
        padding: '16px 16px',
        fontSize: 16,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        flexDirection: 'row',
        backgroundColor: '#efefef',
        color: undefined,
        marginTop: 1,
        marginBottom: 1,
    },
    myContent: {
        flexDirection: 'row-reverse' ,
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    timestamp: {fontSize: 14, color: "#84909c", padding: "0 8px", alignSelf: "center"},
    author: {fontSize: 16, color: "#84909c", padding: "4px 16px"},
    isMe: {
        flexDirection: "row-reverse",
        marginRight: theme.spacing(2)
    },
    isNotMe: {
        flexDirection: "row",
        marginLeft: theme.spacing(2),
    }
}))

interface MessageBlobProps {
    showDate?: boolean;
    showAuthor?: boolean;
    lastMessageOfAuthor?: boolean;
    message: ChatMessage | Message;
    me: boolean;
}

const MessageBlob: React.FC<MessageBlobProps> = ({showDate, showAuthor, lastMessageOfAuthor, message, me }) => {
    const classes = useStyles();

    const radius = 20;
    const smallRadius = 4;

    const isVideo = (message as ChatMessage)?.type === "video";

    return (
        <div className={clsx(classes.message, me && classes.me)}>
            {showDate && <span className={classes.author} style={{alignSelf: "center"}}>
                {toLocalDateTimeString(message.createdAt)}
                </span>}
            {(showAuthor || showDate) && <span className={classes.author}>
                {(message as ChatMessage)?.author.name}
            </span>}
            <Box
                display="flex"
                alignItems='flex-end'
                className={me ? classes.isMe : classes.isNotMe}
            >
                <div className={clsx(classes.msgContent, me && classes.myContent)}
                     style={me ?
                         {borderTopRightRadius: showDate || showAuthor ? radius : smallRadius, borderBottomRightRadius: lastMessageOfAuthor ? radius : smallRadius}
                         :
                         {borderTopLeftRadius: showDate || showAuthor ? radius : smallRadius, borderBottomLeftRadius: lastMessageOfAuthor ? radius : smallRadius}}
                >
                    {!isVideo &&  message.content}
                    {isVideo && <VideoMessage message={message} />}
                </div>
                {/*<span className={classes.timestamp}>*/}
                {/*    {toLocalISOTime(message.createdAt as Date)}*/}
                {/*</span>*/}
            </Box>
            {/*<span style={{*/}
            {/*    display: 'none',*/}
            {/*    fontSize: 14,*/}
            {/*    color: theme.palette.grey['700'],*/}
            {/*    padding: theme.spacing(2),*/}
            {/*    paddingTop: theme.spacing(0.5),*/}
            {/*    paddingBottom: 0,*/}
            {/*    textAlign: 'center'*/}
            {/*}}>*/}
            {/*    {subtitle}*/}
            {/*</span>*/}
        </div>
    )
}
export default MessageBlob;
