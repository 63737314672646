import {Harker} from "hark";
import SimplePeer from 'simple-peer';

export enum StreamType {
    'video',
    'audio',
}

export interface RTCPeerState extends RTCPeerStreamState {
    id: string;
}

export interface LocalRTCPeerState extends RTCPeerStreamState {
    id?: string;
}

export interface RTCPeerStreamState {
    audioEnabled: boolean;
    videoEnabled: boolean;
}

export interface RTCPeer {
    id: string;
    rtc: SimplePeer.Instance;
    stream: MediaStream | undefined;
    hark: Harker | undefined;
}
export const emptyRTCPeer = {
    id: '',
    stream: undefined,
    hark: undefined,
};

export interface Message {
    id: string;
    callerId: string;
    deviceId: string;
    content: string;
    createdAt: Date;
}
export const emptyMessage: Message = {
    id: '',
    callerId: '',
    deviceId: '',
    content: '',
    createdAt: new Date(),
};

export interface CallRemoteState {
    id: string;
    peers: RTCPeerState[];
    messages: Message[];
}

export type SignalType = 'offer' | 'signal';

export interface Signal {
    callerId: string;
    type: SignalType;
    signal: string;
}

export type SocketFeedback = {
    loading: boolean;
    success: boolean;
    title?: string;
};

export const initialSocketFeedback: SocketFeedback = {
    loading: true,
    success: false,
    title: undefined,
};

export type MediaFeedback = {
    loading: boolean;
    success: boolean;
    title?: string;
    code?: MediaFeedbackCode;
};

export enum MediaFeedbackCode {
    'initial',
    'cameraOff',
    'notWebRTCCompatible',
    'permissions',
    'empty',
}

export const mediaFeedbackMap: { [p: string]: MediaFeedback } = {
    [MediaFeedbackCode.initial]: {
        loading: true,
        success: false,
        title: 'Klargør ...',
        code: MediaFeedbackCode.initial,
    },
    [MediaFeedbackCode.notWebRTCCompatible]: {
        loading: false,
        success: false,
        title: 'Din browser understøtter ikke videosamtaler',
        code: MediaFeedbackCode.notWebRTCCompatible,
    },
    [MediaFeedbackCode.permissions]: {
        loading: false,
        success: false,
        title: 'Vi kunne ikke få adgang til dit kamera',
        code: MediaFeedbackCode.permissions,
    },
    [MediaFeedbackCode.cameraOff]: {
        loading: false,
        success: true,
        title: 'Kameraet er slukket',
        code: MediaFeedbackCode.cameraOff,
    },
    [MediaFeedbackCode.empty]: {
        loading: false,
        success: true,
        title: undefined,
        code: MediaFeedbackCode.empty,
    },
};