const calculateAge = (birthDate: Date | string | null) => { // birthday is a date
    if(birthDate == null){
        return null;
    }
    const bd = new Date(birthDate);
    var ageDifMs = Date.now() - bd.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default calculateAge;
