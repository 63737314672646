import React from 'react'
import {Bar} from '@reactchartjs/react-chart.js'
import {ModuleData} from "../../models/OverviewModel";
import {Box} from "@material-ui/core";

const data = (moduleData: ModuleData) => {
    return (
        {
            labels: moduleData.labels,
            datasets: moduleData.datasets
        }
    )
}

const options = () => {
    return ({
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        // color: '#fff4',
                        drawBorder: false,
                        offsetGridLines: true
                    },
                    ticks: {
                        beginAtZero: true,
                        // fontColor: "#fff",
                        stepSize: 1,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        // fontColor: ""
                    },
                },
            ],
        },
    })
}


interface VerticalBarProps {
    verticalBarModuleData?: ModuleData;
}

const VerticalBar: React.FC<VerticalBarProps> = ({verticalBarModuleData}) => (
    verticalBarModuleData ?
        <Bar data={data(verticalBarModuleData)} options={options()} type="bar"/>
        :
        <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
            Ingen data
        </Box>
)

export default VerticalBar