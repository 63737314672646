import Tag from "../../models/Tag";
import authService, {AuthServiceType} from "./authService";
import projectService, {ProjectServiceType} from "./projectService";
import ProjectUser from "../../models/ProjectUser";
import meService, {MeServiceType} from "./meService";
import {videoService, VideoService} from "./videoService";
import {surveyService, SurveyServiceType} from "./surveyService";
import youthService, {YouthServiceType} from "./youthService";
import overviewService, {OverviewServiceType} from "./overviewService";
import eventService, {EventServiceType} from "./eventService";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import {RequestMethod, restClient, RestClient, RestRequest} from "./restClient";
import {crudService, CrudServiceType} from "./crudService";
import {RestErrorResponse} from "../../models/RestResponse";
import Authorization from "../../models/Authorization";
import snackbar from "../snackbar";
import journalService, {JournalServiceType} from "./journalService";
import {adminService, AdminServiceType} from "./adminService";

export const API_ENV: string | undefined = process.env.REACT_APP_API_ENV || 'development';

// export const isProductionMode = API_ENV === 'production';
export const isDevelopmentMode = API_ENV === 'development' || API_ENV === 'staging';
export const isLocalhostMode = API_ENV === 'localhost';

const PROD_HOST = 'https://api.diti.dk';
const STAGING_HOST = 'https://dev.api.diti.dk';
const LOCAL_HOST = 'http://localhost:5000';
export const HOST = isLocalhostMode ? LOCAL_HOST : isDevelopmentMode ? STAGING_HOST : PROD_HOST;

export interface Services {
    readonly client: RestClient;
    readonly auth: AuthServiceType;
    readonly me: MeServiceType;
    readonly projects: ProjectServiceType;
    readonly journals: JournalServiceType;
    readonly events: EventServiceType;
    readonly projectUsers: CrudPropertyServiceType<ProjectUser>;
    readonly surveys: SurveyServiceType;
    readonly tags: CrudServiceType<Tag>;
    readonly video: VideoService;
    readonly youth: YouthServiceType;
    readonly overview: OverviewServiceType;
    readonly admin: AdminServiceType;
}

const client = {
    ...restClient(HOST),
    onHttpError: async <T>(request: RestRequest, response: RestErrorResponse) => {


        // refresh authorization flow
        if (response.status === 401) {
            if (request.headers?.['no-refresh']) {
                snackbar.showMessage(response.feedback.message, response.feedback.severity);
                return response;
            }

            const authorization = client.getAuthorization();
            const refreshResponse = await client.fetchJSON<Authorization>(RequestMethod.POST, "/api/web/v1/auth/refresh", {refreshToken: authorization.refreshToken})
            if (refreshResponse?.success) {
                client.setAuthorization({
                    ...authorization,
                    accessToken: refreshResponse.value.accessToken,
                });
                return client.fetchRequest<T>({
                    ...request,
                    headers: {
                        ...request.headers,
                        'no-refresh': 'true',
                    }
                });
            }
        }

        return response;
    }
};

export const services = {
    client,
    auth: authService(client),
    me: meService(client),
    projects: projectService(client),
    projectUsers: crudPropertyService<ProjectUser>(client, '/api/web/v1/projects', 'users'),
    journals: journalService(client),
    surveys: surveyService(client),
    events: eventService(client),
    tags: crudService<Tag>(client, '/api/web/v1/tags'),
    video: videoService(client),
    youth: youthService(client),
    overview: overviewService(client),
    admin: adminService(client),
};

export default services;
