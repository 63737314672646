import React, {useState} from "react";
import MenuTable from "../../components/tables/MenuTable";
import Menu from "@material-ui/core/Menu";
import HeadItem from "../../components/tables/HeadItem";
import Youth, {ListYouth, youthToList} from "../../models/Youth";
import {ListItemIcon, MenuItem} from "@material-ui/core";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Typography from "@material-ui/core/Typography";
import {projectYouthActions} from "../../store/YouthState";
import {useDispatch} from "react-redux";
import useCurrentProject from "../../hooks/useCurrentProject";

const heads: HeadItem<ListYouth>[] = [
    {id: "name", numeric: false, disablePadding: false, label: "Navn"},
    {id: "sex", numeric: false, disablePadding: false, label: "Køn"},
    {id: "age", numeric: true, disablePadding: false, label: "Alder"},
    {id: "municipalityName", numeric: false, disablePadding: false, label: "Kommune"},
]

interface YouthGroupTableProps {
    youths: Youth[];
    selectedGroupName: string | undefined;
}

const YouthGroupTable: React.FC<YouthGroupTableProps> = ({youths, selectedGroupName}) => {
    const dispatch = useDispatch();
    const currentProjectId = useCurrentProject().id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedYouthId, setSelectedYouthId] = useState("");

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorEl(e.currentTarget);
        setSelectedYouthId(id);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedYouthId("");
    }
    const handleRemoveYouth = () => {
        if (selectedGroupName) {
            const youth = youths.find(y => y.id === selectedYouthId);
            if (youth) {
                dispatch(projectYouthActions.updateElement(currentProjectId, {
                    ...youth,
                    groups: youth.groups.filter(g => g.name !== selectedGroupName),
                }));
            }
        }
        handleMenuClose();
    }

    const formattedYouths = youths.map(youthToList);

    return (
        <React.Fragment>
            <MenuTable
                heads={heads}
                rows={formattedYouths}
                onMenuClick={handleMenuClick}
            />
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleRemoveYouth}>
                    <ListItemIcon>
                        <CloseLineIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Fjern ung</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default YouthGroupTable;
