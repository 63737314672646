import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Routes from '../../constants/Routes';
import {navigationList, utilityNavList} from "../../constants/NavigationList";
import useCurrentUser from "../../hooks/useCurrentUser";
import {isPermitted} from "../../hooks/usePermitted";
import ChatPage from "../chat/ChatPage";
import JournalPage from "../journal/JournalPage";
import SurveyFormik from "../survey/SurveyFormik";

const DashboardSwitch: React.FC = () => {
    const currentUser = useCurrentUser();

    return (
        <Switch>

            <Route path={Routes.chat + '/:chatId'} component={ChatPage}/>
            <Route path={Routes.journal + '/:journalId'} component={JournalPage}/>
            <Route path={Routes.surveys + '/:surveyId'} component={SurveyFormik}/>

            {[...navigationList, ...utilityNavList].map(e => {
                const permitted = !e.permission || isPermitted(currentUser, e.permission);
                return (
                    <Route key={e.path} path={e.path}>
                        {permitted ? <e.component/> : <Redirect to={Routes.dashboard}/>}
                    </Route>
                )
            })}


            <Route path={Routes.dashboard}>
                <Redirect to={Routes.chat}/>
            </Route>
        </Switch>
    );
}

export default DashboardSwitch;


