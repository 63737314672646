import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import More2FillIcon from "remixicon-react/More2FillIcon";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import {Button, ListItemIcon, MenuItem} from "@material-ui/core";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import {journalActions} from "../../store/JournalState";
import useCurrentUser from "../../hooks/useCurrentUser";
import {useDispatch} from "react-redux";
import Journal from "../../models/Journal";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import Typography from "@material-ui/core/Typography";
import FormDrawer from "../../components/drawers/FormDrawer";
import JournalForm from "../../forms/JournalForm";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 20,
        paddingRight: 20,
    },
    sections: {
        fontSize: "initial",
        paddingLeft: 30,
        paddingRight: 30,
    },
    active: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.primary.contrastText,
            textDecoration: "underline",
        },
    },
}));

interface JournalViewHeaderProps {
    journal: Journal;
    activeIndex: number;
    setActiveIndex: (index: number) => void;
};

const JournalViewHeader: React.FC<JournalViewHeaderProps> = ({journal, activeIndex, setActiveIndex}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUserId = useCurrentUser().id;

    const [journalMenuAnchorEl, setJournalMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [editJournal, setEditJournal] = useState(false);
    const [submitJournalButtonRef, handleSaveJournalClick] = useSubmitButtonRef();

    const handleJournalMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        setJournalMenuAnchorEl(e.currentTarget);
    }

    const handleDeleteJournal = () => {
        setJournalMenuAnchorEl(null);
        dispatch(journalActions.deleteElement(currentUserId, journal));
    }

    const handleEditJournal = () => {
        setJournalMenuAnchorEl(null);
        setEditJournal(true);
    }

    const submitJournal = (journal: Journal) => {
        dispatch(journalActions.updateElement(currentUserId, journal))
    }

    return (
        <div>
            <div className={classes.root}>
                <h2>{"Journal for " + journal.name}</h2>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Button style={{textTransform: "none"}} onClick={() => setActiveIndex(0)}>
                        <span className={clsx(classes.sections, activeIndex === 0 ? classes.active : "")}>{"Generalt"}</span>
                    </Button>
                    <Button style={{textTransform: "none"}} onClick={() => setActiveIndex(1)}>
                        <span className={clsx(classes.sections, activeIndex === 1 ? classes.active : "")}>{"Notater"}</span>
                    </Button>
                    <IconButton
                        aria-label="more"
                        aria-controls="more-menu"
                        aria-haspopup="true"
                        onClick={(e: React.MouseEvent<HTMLElement>) => handleJournalMenuClick(e)}
                    >
                        <More2FillIcon />
                    </IconButton>
                </div>
            </div>
            <Divider/>
            <Menu
                id="more-menu"
                anchorEl={journalMenuAnchorEl}
                keepMounted
                open={Boolean(journalMenuAnchorEl)}
                onClick={() => setJournalMenuAnchorEl(null)}
                onClose={() => {
                    setJournalMenuAnchorEl(null);
                }}
            >
                <MenuItem onClick={() => handleEditJournal()}>
                    <ListItemIcon>
                        <Edit2LineIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Rediger journal</Typography>
                </MenuItem>
                <DeleteMenuItem
                    title="Slet journal"
                    message="Er du sikker på, at du vil slette denne journal?"
                    onConfirm={() => handleDeleteJournal()}
                />
            </Menu>
            <FormDrawer
                title={"Redigere journal"}
                open={editJournal}
                onCancel={() => setEditJournal(false)}
                onSave={handleSaveJournalClick}
            >
                <JournalForm
                    submitButtonRef={submitJournalButtonRef}
                    onSubmit={submitJournal}
                    initial={journal}
                />
            </FormDrawer>
        </div>
    );
}


export default JournalViewHeader;


