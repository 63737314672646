import OverviewModule from "../../models/OverviewModel";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import {RestClient} from "./restClient";

export type OverviewServiceType = CrudPropertyServiceType<OverviewModule>;

export const overviewService = (client: RestClient): OverviewServiceType => {
    return {
        ...crudPropertyService(client, '/api/web/v1/projects', 'overview')
    };
};

export default overviewService;
