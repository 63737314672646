import React from "react";
import BasePage from "../../components/containers/BasePage";
import useIsMobile from "../../hooks/useIsMobile";
import Grid from "@material-ui/core/Grid";
import BaseContainer from "./BaseContainer";
import ExpandedGrid from "../material/ExpandedGrid";

interface DualSplitPageProps {
    children: [React.ReactElement, React.ReactElement];
}

const DualSplitPage: React.FC<DualSplitPageProps> = ({children: [leftChild, rightChild]}) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return leftChild;
    }

    return (
        <BasePage>
            <ExpandedGrid container spacing={1}>
                <Grid item xs={12} md={3}>
                    <BaseContainer>
                        {leftChild}
                    </BaseContainer>
                </Grid>
                <Grid item xs={12} md={9}>
                    <BaseContainer>
                        {rightChild}
                    </BaseContainer>
                </Grid>
            </ExpandedGrid>
        </BasePage>
    );
}

export default DualSplitPage;