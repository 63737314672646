import React from "react";
import Center from "./Center";
import {CircularProgress} from "@material-ui/core";
import {CrudState} from "../../store/CrudState";


export interface ContentContainerProps {
    state: CrudState<unknown>,
    empty?: React.ReactNode
}


export const ContentContainer: React.FC<ContentContainerProps> =
    ({
         state: {elements, loading, feedback},
         empty,
         children,
     }) => {

        if (loading) return (
            <Center>
                <CircularProgress/>
            </Center>
        )

        if (feedback?.severity === 'error') return (
            <Center>{feedback?.message ?? "Error"}</Center>
        )

        if (elements.length === 0) return (
            <Center>{empty}</Center>
        )

        return <>{children}</>;
    }

export default ContentContainer;
