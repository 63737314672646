import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import {Field, Form, Formik, FormikHelpers, useFormikContext} from 'formik';
import TextField from '@material-ui/core/TextField/TextField';
import AutocompleteInput from '../components/inputs/AutocompleteInput';
import { CustomTheme } from '../constants/theme';
import Project, {emptyProject} from "../models/Project";
import Grid from "@material-ui/core/Grid";
import services from "../services/rest/services";
import {dawa} from "../services/rest/dawaService";
import FormikErrorDisplay from "../components/displays/FormikErrorDisplay";
import FormProps from "../models/FormProps";
import Tag from "../models/Tag";
import SelectNamed from "../components/inputs/SelectNamed";
import toValueList from "../lib/toValueList";
import toIdList from "../lib/toIdList";
import randomInt from "../lib/randomInt";
import Validators from "../lib/Validators";
import FeatureNames from "../constants/FeatureNames";
import AvatarInput from "../components/inputs/AvatarInput";
import useRequestList, {useRequestState} from "../hooks/useRequestList";
import Region from "../models/Region";
import Municipality from "../models/Municipality";
import {Tooltip} from "@material-ui/core";

export type ProjectFormProps = FormProps<Project> & {
    hideId?: boolean;
};
export const ProjectForm: React.FC<ProjectFormProps> = ({submitButtonRef, initial, onSubmit, hideId}) => {

    const tags = useRequestList<Tag>(services.tags.getAll);

    const handleSubmit = async (values: Project, {setSubmitting, resetForm}: FormikHelpers<Project>) => {
        const additionalPostalNumbers = values.additionalPostalNumbersOptions?.length ? values.additionalPostalNumbersOptions?.map(e => e.value) : undefined;

        await onSubmit({
            ...values,
            features: values.featureIds.reduce((result: { [p: string]: boolean }, val) => {
                result[val] = true;
                return result;
            }, {}),
            tags: toValueList(tags, values.tagIds),
            location: values.postalCode ? values.location : undefined,
            additionalPostalNumbersOptions: undefined,
            additionalPostalNumbers,
        });
        setSubmitting(false);
        resetForm();
    };

    return (
        <Formik<Project>
            initialValues={{
                ...emptyProject,
                ...initial,
                groupId: initial?.groupId ?? "",
                featureIds: Object.keys({...initial?.features}),
                tagIds: toIdList(initial?.tags),
                regionId: initial?.location?.region?.id,
                municipalityId: initial?.location?.municipality?.id,
                postalCode: initial?.location?.postal?.number.toString() ?? "",
                additionalPostalNumbers: initial?.additionalPostalNumbers ?? [],
                additionalPostalNumbersOptions: initial?.additionalPostalNumbers ? initial?.additionalPostalNumbers.map(e => ({
                    title: e.toString(),
                    value: e.toString()
                })) : [],
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            <Form>
                <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                <ProjectFormFields hideId={hideId} tags={tags}/>
            </Form>
        </Formik>
    );
}

const useStyles = makeStyles<CustomTheme>((theme) => ({
    addAllAdditionalPostalNumbers: {
        borderRadius: 25,
    },
}));

export const ProjectFormFields: React.FC<{ hideId?: boolean, tags: Tag[] }> = ({hideId, tags}) => {
    const classes = useStyles();

    const {values, errors, touched, setFieldValue} = useFormikContext<Project>();

    const regions = useRequestList<Region>(dawa.getRegions);
    const municipalities = useRequestList<Municipality>(dawa.getMunicipalities);

    const [postalData, setPostalData] = useRequestState(() => dawa.getPostalData(values.postalCode ?? ""));
    const [municipalitiesNames, setMunicipalitiesNames] = useRequestState(() => dawa.getMunicipalitiesNamesFromPostalCode(values.postalCode ?? ""));
    const locationMunicipalityName = values.location?.municipality?.name;
    const uniqueMunicipalitiesNames = useMemo(() => {
        if (locationMunicipalityName) {
            return [locationMunicipalityName];
        }

        return Array.from(
            new Set(municipalitiesNames ?? [])
        ).filter(e => e);
    }, [municipalitiesNames, locationMunicipalityName]);
    const getAutocompletePostalData = useCallback(async () =>
        dawa.getAutocompletePostalData(
            uniqueMunicipalitiesNames
        ), [uniqueMunicipalitiesNames]);

    const [autocompletePostalData, setAutocompletePostalData] = useRequestState(getAutocompletePostalData);

    useEffect(() => {
        setPostalData(undefined);
    }, [setPostalData, values]);

    const autocompletePostalDataDependency = JSON.stringify(uniqueMunicipalitiesNames.sort());
    useEffect(() => {
        setAutocompletePostalData(undefined);
    }, [setAutocompletePostalData, autocompletePostalDataDependency]);

    useEffect(() => {
        setMunicipalitiesNames(undefined);
    }, [setMunicipalitiesNames, values.postalCode]);

    useEffect(() => {
        setAutocompletePostalData(undefined);
        setMunicipalitiesNames(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.id]);

    return (

        <Grid container spacing={3}>

            {!hideId && <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}} hidden={hideId}>
                <AvatarInput
                    src={values.logo}
                    onChange={async (file) => {
                        const response = await services.projects.updateImage(values.id, file)
                        const url = response.success ? response.value.logo : '';
                        setFieldValue('logo', url + "?rand=" + randomInt());
                    }}/>
            </Grid>}

            <Grid item xs={12} md={6} hidden={hideId}>
                <Field
                    as={TextField}
                    name="id"
                    type="id"
                    label="Id"
                    required
                    fullWidth
                    variant='filled'
                    validate={Validators.notEmpty}
                    error={errors.id && touched.id}
                    helperText={values.id.length !== 0 && errors.id}
                />
            </Grid>

            <Grid item xs={12} md={6} hidden={hideId}>
                <Field
                    as={TextField}
                    name="groupId"
                    type="groupId"
                    label="GroupId"
                    fullWidth
                    variant='filled'
                    error={errors.groupId && touched.groupId}
                    helperText={values.groupId?.length !== 0 && errors.groupId}
                />
            </Grid>

            <Grid item xs={12} hidden={hideId}>
                <Field
                    as={SelectNamed}
                    options={FeatureNames.options}
                    name="featureIds"
                    label="Features"
                    multiple
                />
            </Grid>

            <Grid item xs={12} md={9}>
                <Field
                    as={TextField}
                    name="name"
                    type="name"
                    label="Navn"
                    required
                    fullWidth
                    variant='filled'
                    validate={Validators.notEmpty}
                    error={errors.name && touched.name}
                    helperText={values.name.length !== 0 && errors.name}
                />
            </Grid>
            <Grid item xs={6} md={3} style={{ display: 'flex' }}>
                <Field
                    name="isAccredited"
                    label="Akkrediteret"
                    render={({ field, form, ...props }: any) => (
                        <FormControlLabel
                            label="Akkrediteret"
                            control={<Checkbox
                                {...field}
                                {...props}
                                color="primary"
                                checked={field.value}
                                onChange={e => {
                                    form.setFieldValue(field.name, e.target.checked);
                                }}
                            />}
                            style={{
                                overflowWrap: 'anywhere',
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={TextField}
                    name="colorHex"
                    type="color"
                    label="Farve"
                    fullWidth
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    name="slogan"
                    type="slogan"
                    label="Slogan"
                    required
                    fullWidth
                    variant='filled'
                    validate={Validators.notEmpty}
                    error={errors.slogan && touched.slogan && values.slogan.length !== 0}
                    helperText={values.slogan.length !== 0 && errors.slogan}
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    as={SelectNamed}
                    options={tags}
                    name="tagIds"
                    label="Tags"
                    multiple
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    as={TextField}
                    name="postalCode"
                    type="postalCode"
                    label="Post nr."
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;

                        if (!/^\d*$/.test(value)) {
                            e.preventDefault();
                            return;
                        }

                        const postalNumber = parseInt(value);

                        setFieldValue('postalCode', value);
                        setFieldValue('location.postal.number', postalNumber);
                        if (value && value.length === 4) {
                            setPostalData(undefined);
                        }
                    }}
                    fullWidth
                    variant='filled'
                    error={errors.postalCode && touched.postalCode}
                    helperText={values.postalCode?.length !== 0 && errors.postalCode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Field
                    as={SelectNamed}
                    disabled={!(postalData && postalData[0]?.municipalities)}
                    options={postalData && postalData[0]?.municipalities}
                    id="municipalityId"
                    name="municipalityId"
                    label="Kommune"
                    value={values.municipalityId}
                    onChange={(e: { target: { value: string; }; }) => {
                        const municipalityId = e.target.value;
                        const municipality = municipalities.find(e => e.id === municipalityId);
                        setFieldValue('municipalityId', municipalityId);
                        setFieldValue('location.municipality', municipality);
                        setFieldValue('location.region', regions.find(e => e.id === municipality?.region.id));
                        postalData && setFieldValue('location.postal.name', postalData[0]?.name);
                        postalData && setFieldValue('location.postal.number', parseInt(postalData[0]?.id));
                        console.log(values.location);

                        setAutocompletePostalData(undefined);
                        setFieldValue('additionalPostalNumbersOptions', []);
                    }}
                />
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', gap: '0.5rem' }}>
                <Field
                    as={AutocompleteInput}
                    name="additionalPostalNumbersOptions"
                    label="Tillægs postnumre"
                    disabled={!autocompletePostalData && values.postalCode?.length !== 4}
                    options={autocompletePostalData ?? []}
                    limitTags={7}
                    validateOnChange={(v: string) => {
                        console.log('the v', v);
                        return /^\d{4}$/.test(v);
                    }}
                    style={{
                        flex: 1
                    }}
                />
                <Button
                    className={classes.addAllAdditionalPostalNumbers}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setFieldValue('additionalPostalNumbersOptions', autocompletePostalData ?? []);
                    }}
                >
                    tilføje alle
                </Button>
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={TextField}
                    name="description"
                    type="description"
                    label="Offentlig beskrivelse"
                    fullWidth
                    multiline
                    rows={8}
                    variant='filled'
                />
            </Grid>

            <Grid item xs={12}>
                <Tooltip title={'Indsæt denne kode på en hjemmeside for at oprette en "Start Chat" knap, der gennem DiTi web er koblet på jeres projekt'}>
                <Field
                    as={TextField}
                    name="iframe"
                    type="iframe"
                    label="Indlejret kode"
                    value={values.id === null || values.id === '' ? '' : `<iframe src="https://app.diti.dk/iframe?projectId=${values.id}"/>`}
                    inputProps={
                        {readOnly: true,}
                    }
                    fullWidth
                    variant='filled'
                />
                </Tooltip>
            </Grid>
            <FormikErrorDisplay errors={errors}/>
        </Grid>
    );

}


export default ProjectForm;

