import React from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import Center from "../../components/containers/Center";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChatThread, {chatThreadSearchFilter} from "../../models/ChatThread";
import SearchListView from "../../components/SearchListView";
import useTypedSelector from "../../hooks/useTypedSelector";
import {useParams} from "react-router-dom";
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import ChatView from "./ChatView";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        backgroundColor: 'white',
        boxShadow: '0 1px 4px rgba(0,0,0,.09)',
        borderRadius: 4,
        overflow: 'hidden',
        [theme.breakpoints.down("sm")]: {
            boxShadow: "none",
            borderRadius: 0,
        },
    },
    container: {
        height: '100%',
        position: 'absolute',
        padding: theme.spacing(0, 2, 2, 2),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        },
    },
    listContainer: {
        height: "100%",
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        flex: 1,
        marginRight: 8,
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
        },
    },
    hideDown: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    divider: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    }
}));


const ChatPage: React.FC = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {chatId} = useParams<{ chatId: string }>();

    const state = useTypedSelector(state => state.chat);

    const handleChatClick = (chat: ChatThread) => {
        dispatch(push(Routes.chat + '/' + chat?.id));
    }

    const handleChatReadClick = (chat: ChatThread) => {
        state.client?.setRead(chat.id, !chat.read);
    }

    const handleRenderSubtitle = (t: ChatThread) => toLocalDateTimeString(t.messages[t.messages.length - 1].createdAt)

    if (state.loading) return <Center><CircularProgress size={40}/></Center>;

    return (
        <Box height='100%' width='100%'>
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={4} lg={3}
                      className={clsx(classes.paper, classes.listContainer, chatId && classes.hideDown)}>
                    <SearchListView<ChatThread>
                        elements={state.elements}
                        selectedId={chatId}
                        searchFilter={chatThreadSearchFilter}
                        onElementClick={handleChatClick}
                        onElementReadClick={handleChatReadClick}
                        renderSubtitle={handleRenderSubtitle}
                    />
                </Grid>
                <Divider variant="fullWidth" orientation="vertical" className={classes.divider}/>
                <Grid item xs={12} sm={8} lg={9} className={clsx(classes.paper, !chatId && classes.hideDown)}>
                    <ChatView/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default ChatPage;