import RestResponse, {RestErrorResponse, RestSuccessResponse} from "../../models/RestResponse";
import {RequestMethod, restClient, RestClient} from "./restClient";


interface Call {
    id: string;
    used: boolean;
    startedAt: Date | undefined;
    endedAt: Date | undefined;
    durationInMilliSeconds: number | undefined;
    redirects: { [p: string]: string };
    createdAt: Date;
    updatedAt: Date;
}

export type VideoService = {
    createCall: (arg: { [p in string]: string }) => RestResponse<Call>;
}

export function videoService(client: RestClient): VideoService {
    return {
        createCall: async function (redirects): Promise<RestSuccessResponse<Call> | RestErrorResponse> {
            return await client.fetchJSON(RequestMethod.POST, '/api/web/v1/video', redirects);
        }
    }
}

export const video = videoService(restClient('https://dev.api.danmark.ai'));
export default video;