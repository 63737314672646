import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {History} from 'history';
import {AppState, reducers} from './';
import {loadAppState, saveAppState} from "../services/localStorage";

export const configureStore = (history: History) => {

    const initialState: AppState = loadAppState();
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));

    store.subscribe(() => {
        const state = store.getState();
        saveAppState(state as AppState);
    });

    return store;
};

export default configureStore;
