import {Field, Form, Formik} from "formik";
import TextField from "@material-ui/core/TextField/TextField";
import React from "react";
import {Grid} from "@material-ui/core";
import FormProps from "../models/FormProps";
import Validators from "../lib/Validators";
import {UserIdentification} from "../models/User";


export type ProfileFormProps = FormProps<UserIdentification>;
export const ProfileForm: React.FC<ProfileFormProps> = ({submitButtonRef, initial, onSubmit}) => {
    return (
        <Formik<UserIdentification>
            onSubmit={onSubmit}
            initialValues={{
                id: initial?.id ?? '',
                firstName: initial?.firstName ?? '',
                lastName: initial?.lastName ?? '',
                email: initial?.email ?? '',
                phoneNumber: initial?.phoneNumber ?? '',
            }}
        >
            {({values, errors, touched}) => {
                return (
                    <Form>
                        <button
                            aria-label="submit"
                            type="submit"
                            style={{display: 'none'}}
                            ref={submitButtonRef}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Field
                                    as={TextField}
                                    validate={Validators.name}
                                    error={errors.firstName && touched.firstName && values.firstName.length !== 0}
                                    helperText={values.firstName.length !== 0 && errors.firstName}
                                    fullWidth
                                    variant='filled'
                                    id="firstName"
                                    label="Fornavn"
                                    name="firstName"
                                    type="text"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Field
                                    as={TextField}
                                    validate={Validators.name}
                                    error={errors.lastName && touched.lastName && values.lastName.length !== 0}
                                    helperText={values.lastName.length !== 0 && errors.lastName}
                                    fullWidth
                                    variant='filled'
                                    id="lastName"
                                    label="Efternavn"
                                    name="lastName"
                                    type="text"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Field
                                    as={TextField}
                                    validate={Validators.email}
                                    error={errors.email && touched.email && values.email.length !== 0}
                                    helperText={values.email.length !== 0 && errors.email}
                                    required
                                    fullWidth
                                    variant='filled'
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete='email'
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Field
                                    as={TextField}
                                    validate={() => Validators.phoneNumber(values.phoneNumber)}
                                    error={errors.phoneNumber && touched.phoneNumber}
                                    helperText={errors.phoneNumber}
                                    fullWidth
                                    variant='filled'
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Telefonnummer"
                                    type="phone"
                                    autoComplete='phone'
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    )
}
export default ProfileForm;