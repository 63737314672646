import React from 'react';

import {Calendar} from "@fullcalendar/core";
import FullCalendar, {ViewApi} from "@fullcalendar/react";
import {Box} from "@material-ui/core";
import {DatePickerProps} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {toIdValueMap} from "../../lib/toIdValueMap";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PopoverDatePicker from "../../components/pickers/PopoverDatePicker";
import SelectNamed, {SelectNamedProps} from "../../components/inputs/SelectNamed";
import {initialView} from "./CalendarView";
import ColoredIconButton from "../../components/buttons/ColoredIconButton";
import AddLineIcon from "remixicon-react/AddLineIcon";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import ArrowRightSLineIcon from "remixicon-react/ArrowRightSLineIcon";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon";
import {toWeekNumber} from "../../lib/toLocalISO";

const useStyles = makeStyles(theme => ({
    titleBox: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(0, 1, 0, 1),
        cursor: "pointer",
        fontWeight: 600,
        whiteSpace: 'nowrap',
    },
    viewSelect: {
        minWidth: '124px',
    },
    weekNumber: {
        borderRadius: 4,
        backgroundColor: '#f1f3f4',
        color: '#3c4043',
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontWeight: 500,
        height: 22,
        letterSpacing: .3,
        padding: '4px 8px',
    },
}))

const calendarViewMap = toIdValueMap([{
    id: 'dayGridDay',
    name: 'Dag',
}, {
    id: 'dayGridWeek',
    name: 'Uge',
}, {
    id: 'dayGridFourDay',
    name: 'Arbejdsuge',
}]);


interface ShiftPlannerToolbarProps {
    calendarRef: React.RefObject<FullCalendar>;
    view: Partial<ViewApi>;
    onChangeView: (view: Partial<ViewApi>) => void;
    onCreateClick?: () => void;
}

const CalendarToolbar: React.FC<ShiftPlannerToolbarProps> = ({calendarRef, view, onChangeView, onCreateClick}) => {
    const classes = useStyles();

    // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    // const handleCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(e.currentTarget);
    // const handleClose = () => setAnchorEl(null);

    const calendar = () => calendarRef?.current?.getApi() ?? {} as Calendar;
    // const currentUser = useCurrentUser();
    // const permittedToCRUD = checkPermission(currentUser, "Permissions.Calendar.Create");

    // const checkToFetchNewEvents = (date: Date) => {
    //     // if (centerId !== undefined && date.getMonth() !== monthMid) {
    //     //     setMonthMid(date.getMonth());
    //     //     dispatch(eventActions.getAllByCenterIdForDate(centerId, date));
    //     // }
    // }

    const handleChangeView: SelectNamedProps["onChange"] = (event) => {
        const typeId = event.target.value;
        if (typeId && typeof typeId === "string") {
            calendar().changeView(typeId);
            const updatedView = calendar().view;
            onChangeView({
                title: updatedView.title,
                currentStart: updatedView.currentStart,
                currentEnd: updatedView.currentEnd,
                type: typeId
            })
        }
    };

    // const handleRenderViewText = (id: string) => calendarViewMap[id].name;

    const handlePrev = () => {
        calendar().prev();
        onChangeView({...calendar().view});
    }
    const handleNext = () => {
        calendar().next();
        onChangeView({...calendar().view});
    }

    const handleDateChange: DatePickerProps["onChange"] = date => {
        calendar().gotoDate(date);
        onChangeView({...calendar().view});
    }

    const handleAddClick = () => {
        onCreateClick && onCreateClick();
        // handleClose();
    };

    const getTypeReadableName = (type?: string): string => {
        if (!type || !calendarViewMap[type]) return 'uge';
        return calendarViewMap[type].name.toLowerCase()
    }

    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
            <Box display={'flex'} alignItems={'center'}>
                <Tooltip title={'Forrige ' + getTypeReadableName(view.type)}>
                    <IconButton onClick={handlePrev}>
                        <ArrowLeftSLineIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Næste ' + getTypeReadableName(view.type)}>
                    <IconButton onClick={handleNext}>
                        <ArrowRightSLineIcon/>
                    </IconButton>
                </Tooltip>
                <PopoverDatePicker value={view.currentStart} onChange={handleDateChange}>
                    <Box className={classes.titleBox}>
                        <Typography variant={"button"}>
                            {view?.title}
                        </Typography>
                        <ArrowDownSLineIcon/>
                    </Box>
                </PopoverDatePicker>
                <div className={classes.weekNumber}>
                    Uge {toWeekNumber(view.currentStart)}
                </div>
            </Box>
            <Box display={'flex'} alignItems={'center'} pl={2} pr={2}>
                <SelectNamed
                    className={classes.viewSelect}
                    options={Object.values(calendarViewMap)}
                    value={view.type || initialView}
                    onChange={handleChangeView}
                    variant={"outlined"}
                />

                <Box ml={2}>
                    <ColoredIconButton onClick={handleAddClick}>
                        <AddLineIcon color="white"/>
                    </ColoredIconButton>
                </Box>
            </Box>
        </Box>
    )
}

export default CalendarToolbar;
