import {User} from "../../models/User";
import SignInResponse from "../../models/SignInRespose";
import {RegisterUserRequest} from "../../pages/auth/RegisterUserForm";
import ProjectAuthResponse from "../../models/ProjectAuthResponse";
import RestResponse from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";

export interface AuthServiceType {
    register: (request: RegisterUserRequest, token: string) => RestResponse<Required<User>>;
    signInWithEmail: (email: string, password: string) => RestResponse<SignInResponse>;
    signInWithProject: (projectId: string) => RestResponse<ProjectAuthResponse>;
    forgotPassword: (email: string) => RestResponse<undefined>;
    resetPassword: (password: string, token: string) => RestResponse<undefined>;
    fetchAuthorization: () => RestResponse<Required<SignInResponse>>;
}

export const authService = (client: RestClient): AuthServiceType => {
    const basePath = '/api/web/v1/auth';
    return {
        register: (request, token) => {
            return client.fetchJSON(RequestMethod.POST, basePath + '/register', {...request}, {
                'Authorization': 'Bearer ' + token,
            });
        },
        signInWithProject: async (projectId) => {
            const response = await client.fetchJSON<ProjectAuthResponse>(RequestMethod.POST, basePath + "/project/" + projectId);
            if (response.success) {
                client.setAuthorization({
                    ...client.getAuthorization(),
                    ...response.value.authorization
                })
            }
            return response;
        },
        fetchAuthorization: () => {
            return client.fetchJSON(RequestMethod.GET, basePath);
        },
        signInWithEmail: async (email, password) => {
            const response = await client.fetchJSON<SignInResponse>(RequestMethod.POST, basePath, {
                email: email,
                password: password,
            });
            if (response.success) {
                client.setAuthorization(response.value.authorization)
            }
            return response;
        },
        forgotPassword: async (email: string) => {
            return await client.fetchJSON(RequestMethod.POST, basePath + '/forgot-password', {
                email: email,
            });
        },
        resetPassword: async (password: string, token: string) => {
            const authorization = 'Bearer ' + token;
            return await client.fetchJSON(RequestMethod.POST, basePath + '/reset-password', {
                password: password,
            }, {
                Authorization: authorization,
            });
        },
    }
};

export default authService;