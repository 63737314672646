import React from "react";
import {Formik} from "formik";
import OverviewModuleFormFields from "./OverviewModuleFormFields";
import OverviewModule, {emptyOverviewModule} from "../../models/OverviewModel";
import * as Yup from 'yup';

const OverviewModuleSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    type: Yup.string()
        .required('Required'),
    color: Yup.string()
        .required('Required'),
    service: Yup.string()
        .required('Required'),
    aggregation: Yup.string()
        .required('Required')

});

interface OverviewModuleFormProps {
    initial?: any;
    onSubmit: (overviewModule: OverviewModule) => Promise<void>;
    submitButtonRef?: React.RefObject<HTMLButtonElement>;
}

const OverviewModuleForm: React.FC<OverviewModuleFormProps> = ({submitButtonRef, onSubmit, initial}) => {

    const initialValues: OverviewModule = {
        ...emptyOverviewModule,
        ...initial,
    }

    return (
        <Formik<OverviewModule>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={OverviewModuleSchema}
        >
            {() => (
                <OverviewModuleFormFields submitButtonRef={submitButtonRef}/>
            )}
        </Formik>
    )
}

export default OverviewModuleForm;