import useCurrentUser from "./useCurrentUser";
import User from "../models/User";

export const usePermitted = (permission: string) => {
    const currentUser = useCurrentUser();
    return isPermitted(currentUser, permission);
}

export const isPermitted = (currentUser: User | undefined, permission: string) => {
    const permissionMap = currentUser?.permissions ?? {};
    return Boolean(permissionMap[permission]);
}

export default usePermitted;