import React from 'react';
import * as history from "history";
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {Route, Switch} from 'react-router-dom';
import NotFoundPage from "./pages/error/NotFoundPage";
import Routes from "./constants/Routes";
import LoginPage from "./pages/auth/LoginPage";
import AdminPage from "./pages/admin/AdminPage";
import ErrorPage from "./pages/error/ErrorPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import ChatView from "./pages/chat/ChatView";
import RegisterUserPage from "./pages/auth/RegisterUserPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import SurveyAnswerPage from "./pages/survey/SurveyAnswerPage";
import useTypedSelector from "./hooks/useTypedSelector";
import {ThemeProvider} from "@material-ui/core";
import {createTheme, CustomTheme} from "./constants/theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import daLocale from "date-fns/locale/da";
import VideoPage from "./pages/video/VideoPage";
import {NotificationProvider} from "./services/snackbar";
import {TestPage} from "./pages/test/TestPage";

const browserHistory = history.createBrowserHistory();
const store = configureStore(browserHistory);


function App() {
    return (
        <Provider store={store}>
            <ConnectedThemeProvider>
                <ConnectedRouter history={browserHistory}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                        <NotificationProvider>
                            <Switch>
                                {/*AUTH*/}
                                <Route exact path={Routes.landing} component={LoginPage}/>
                                <Route path={Routes.resetPassword} component={ResetPasswordPage}/>
                                <Route path={Routes.register} component={RegisterUserPage}/>

                                {/*PRIVATE*/}
                                <AuthenticatedRoute path={Routes.dashboard} component={DashboardPage}/>
                                <AuthenticatedRoute path={Routes.chatMobile + '/:id'} component={ChatView}/>
                                <AuthenticatedRoute path={Routes.admin} component={AdminPage}/>
                                <AuthenticatedRoute path={Routes.video + '/:id'} component={VideoPage}/>

                                {/*PUBLIC*/}
                                <Route path={Routes.surveyAnswer} component={SurveyAnswerPage}/>

                                {/*ERROR*/}
                                <Route path={Routes.error} component={ErrorPage}/>
                                <Route path={Routes.test} component={TestPage}/>
                                <Route path="*" component={NotFoundPage}/>
                            </Switch>
                        </NotificationProvider>
                    </MuiPickersUtilsProvider>
                </ConnectedRouter>
            </ConnectedThemeProvider>
        </Provider>
    );
}


const ConnectedThemeProvider: React.FC = ({children}) => {
    const project = useTypedSelector(state => state.project.selectedElement);
    return (
        <ThemeProvider<CustomTheme> theme={createTheme(project)}>
            {children}
        </ThemeProvider>
    )
}

export default App;



