
export interface SurveyQuestion {
    id: string;
    surveyId: string;
    type: 'choice';
    title: string;
    createdAt: Date;
    updatedAt: Date;
    properties: {
        choices: string[];
    }


}

export const emptySurveyQuestion: SurveyQuestion = {
    id: "",
    surveyId: "",
    type: 'choice',
    title: "",
    updatedAt: new Date(),
    createdAt: new Date(),
    properties: {
        choices: [''],
    },
}


export type SurveyQuestionContent = SurveyQuestionText | SurveyQuestionScale;

export interface SurveyQuestionText {
    title: string;
}

export const emptySurveyQuestionText: SurveyQuestionText = {
    title: ""
}

export interface SurveyQuestionScale {
    title: string;
    maxRange: number;
    minRange: number;
    labels: string[]
}

export const emptySurveyQuestionScale: SurveyQuestionScale = {
    title: "",
    minRange: 0,
    maxRange: 5,
    labels: []
}

// export const emptySurveyQuestion: (emptySurveyQuestionField: SurveyQuestionContent) =>
//     SurveyQuestion = (emptySurveyQuestionField) => {
//     return (
//         {
//             id: "",
//             surveyId: "",
//             index: 0,
//             type: "text",
//             content: emptySurveyQuestionField,
//             createdAt: new Date(),
//             updatedAt: new Date()
//         }
//     )
// }

export const typeToEmpty = {
    text: emptySurveyQuestionText,
    scale: emptySurveyQuestionScale
}


export const typeToName = {
    text: "Kort text",
    scale: "Opinionsskala"
}
