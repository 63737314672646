import React from "react";
import ChatThread, {ChatMessage} from "../../models/ChatThread";
import useCurrentUser from "../../hooks/useCurrentUser";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MessageBlob from "../../components/MessageBlob";

const useStyles = makeStyles(() => ({
    root: {
        overflowY: 'auto',
        flex: 1,
        paddingBottom: 16
    },
}))

interface MessageListViewProps {
    thread: ChatThread
    endRef: React.MutableRefObject<null | HTMLDivElement>;
}

export const MessageListView: React.FC<MessageListViewProps> = ({thread, endRef}) => {
    const classes = useStyles();
    const currentUser = useCurrentUser();

    const shouldShowDate = (prevMessage:ChatMessage, message:ChatMessage) => {
        const isAfter = (new Date(message.createdAt).getTime() > new Date (new Date(prevMessage.createdAt).setMinutes(new Date(message.createdAt).getMinutes() + 10)).getTime());
        return isAfter;
    };



    return (
        <div className={classes.root}>
            {thread.messages?.map((message) => {
                const index = thread.messages.findIndex((m) => m === message);
                const prevMessage = index > 0 ? thread.messages[index - 1] : undefined;
                const nextMessage = index < thread.messages.length - 1 ? thread.messages[index + 1] : undefined;
                return(
                    <MessageBlob
                        key={message.id}
                        showDate={prevMessage === undefined ? true : shouldShowDate(prevMessage, message)}
                        showAuthor={message.author.id !== prevMessage?.author?.id}
                        lastMessageOfAuthor={message.author.id !==
                            nextMessage?.author?.id ||
                            nextMessage === undefined ?
                            true : shouldShowDate(message, nextMessage)
                        }
                        message={message}
                        me={message.author.id === currentUser?.id} />
                );
            })}
            <div ref={endRef}/>
        </div>
    )
}

export default MessageListView;