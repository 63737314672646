import ChatThread, {ChatMessage} from "../../models/ChatThread";
import services, {HOST} from "../rest/services";
import Author from "../../models/Author";
import {SocketClient} from "./socketClient";
import signalRConnection from "./signalRConnection";


export interface ChatMessageRequest {
    threadId: string,
    projectId: string,
    content: string,
    author: {
        id: string,
        name: string,
    }
}

export interface ChatClient extends SocketClient {
    author?: Author;
    sendVideoLink: (thread: ChatThread, text: string) => Promise<ChatMessage>;
    sendMessage: (threadId: string, text: string) => Promise<ChatMessage>;
    setRead: (threadId: string, read: boolean) => Promise<ChatThread>;
    setName: (threadId: string, name: string) => Promise<ChatThread>;
    deleteThread: (threadId: string) => Promise<void>;
}


export const chatClient = (): ChatClient => {
    const accessToken = services.client.getAccessToken();
    const url = HOST + "/web/chat?access_token=" + accessToken;
    const connection = signalRConnection(url);

    return {
        connection,
        sendVideoLink: (thread, text) => {
            console.log("sendMessage", '\n')
            return connection.invoke("SendMessage", thread.id, {
                type: 'video',
                content: text,
            }).catch((error: unknown) => console.error(error));
        },
        sendMessage: (threadId, text) => {
            console.log("sendMessage:", threadId, text)
            return connection.invoke("SendMessage", threadId, {
                content: text,
            }).catch((error: unknown) => console.error(error));
        },
        setRead: (threadId: string, read: boolean) => {
            console.log("setRead", '\n', threadId, read)
            return connection.invoke("setRead", threadId, read)
                .catch((error: unknown) => console.error(error));
        },
        setName: (threadId: string, name: string) => {
            console.log("setRead", '\n', threadId, name)
            return connection.invoke("setName", threadId, name)
                .catch((error: unknown) => console.error(error));
        },
        deleteThread: (threadId) => {
            console.log("deleteThread", "\n", threadId);
            return connection.invoke("deleteThread", threadId).catch((err: unknown) => console.error(err))
        }
    }
}

export default chatClient;
