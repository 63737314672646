import Municipality, {DawaMunicipality, municipalityFromDawa} from "../../models/Municipality";
import Region, {DawaRegion, regionFromDawa} from "../../models/Region";
import {AutocompletePostalData, DawaAutocompletePostalData, DawaPostalData, PostalData, postalDataFromDawa} from "../../models/PostalData";
import {RequestMethod, restClient, RestClient} from "./restClient";
import {IdListRequestFunction, IdsListRequestFunction, ListRequestFunction} from "./crudService";

export interface DawaServiceType {
    client: RestClient;
    getMunicipalities: ListRequestFunction<Required<Municipality>>;
    getRegions: ListRequestFunction<Required<Region>>;
    getPostalData: IdListRequestFunction<Required<PostalData>>;
    getAutocompletePostalData: IdsListRequestFunction<Required<AutocompletePostalData[number]>>;
    getMunicipalitiesNamesFromPostalCode: IdListRequestFunction<string>;
    getAllPostalData: ListRequestFunction<Required<PostalData>>;
}

export function dawaService(): DawaServiceType {
    const client = restClient("https://dawa.aws.dk", {
        cache: "force-cache",
    });
    return {
        client: client,
        async getMunicipalities() {
            const response = await client.fetchJSON<DawaMunicipality[]>(RequestMethod.GET, "/kommuner");
            const value = response.success ? response.value.map(e => municipalityFromDawa(e)) : [];
            return {
                ...response,
                value: value,
            };
        },
        async getRegions() {
            const response = await client.fetchJSON<DawaRegion[]>(RequestMethod.GET, "/regioner");
            const value = response.success ? response.value.map(e => regionFromDawa(e)) : [];
            return {
                ...response,
                value: value,
            };
        },
        async getPostalData(postalCode: string) {
            if (postalCode.length !== 4) return {
                status: 404,
                success: false,
                feedback: {
                    severity: "warning",
                    message: "Not fully typed postal code",
                },
                value: undefined,
            };

            const response = await client.fetchJSON<DawaPostalData[]>(RequestMethod.GET, "/postnumre?nr=" + postalCode);
            const value = response.success ? response.value.map(e => postalDataFromDawa(e)) : [];
            return {
                ...response,
                value: value,
            };
        },
        async getAutocompletePostalData(municipalities: string[]) {
            const validMunicipalities = municipalities.filter(e => e.length > 0);

            if(validMunicipalities.length < 1) return {
                status: 404,
                success: false,
                feedback: {
                    severity: "warning",
                    message: "Not fully typed municipality",
                },
                value: undefined,
            };

            const values = await Promise.all(validMunicipalities.map(async (municipality) => {
                const response = await client.fetchJSON<DawaAutocompletePostalData>(RequestMethod.GET, "/postnumre/autocomplete?q=" + municipality + "&per_side=99999");
                const value = response.success ? response.value.filter(e => e.postnummer.navn.includes(municipality)).map(e => {
                    return {
                        title: e.postnummer.nr,
                        value: e.postnummer.nr,
                        tooltip: e.postnummer.navn,
                    }
                }) : [];
                value.sort((a, b) => a.value.localeCompare(b.value));
                return {
                    ...response,
                    value: value,
                }
            }));

            const validValues = values.filter(e => e.success && e.value.length > 0);

            const value = validValues.flatMap(e => e.value);

            return {
                status: 200,
                success: true,
                feedback: {
                    severity: "success",
                    message: "Success",
                },
                value: value,
            }
        },
        async getMunicipalitiesNamesFromPostalCode(postalCode: string) {
            if(postalCode.length !== 4) return {
                status: 404,
                success: false,
                feedback: {
                    severity: "warning",
                    message: "Not fully typed postal code",
                },
                value: undefined,
            };

            const response = await client.fetchJSON<DawaPostalData[]>(RequestMethod.GET, "/postnumre?nr=" + postalCode + "&per_side=99999");

            const value = response.success ? response.value.flatMap(e => e.kommuner.map(k => k.navn)) : [];

            value.sort((a, b) => a.localeCompare(b));


            return {
                ...response,
                value,
            }
        },
        async getAllPostalData() {
            const response = await client.fetchJSON<DawaPostalData[]>(RequestMethod.GET, "/postnumre");
            const value = response.success ? response.value.map(e => postalDataFromDawa(e)) : [];
            return {
                ...response,
                value: value,
            };
        }
    };
}

export const dawa = dawaService();