import Authorization from "../models/Authorization";
import {emptyUser, User, UserIdentification} from "../models/User";
import {CallHistoryMethodAction, push} from 'connected-react-router';
import services from "../services/rest/services";
import Routes from "../constants/Routes";
import {Action, Reducer} from 'redux';
import {clearStorage} from '../services/localStorage';
import {projectStateName} from "./ProjectState";
import UserProject from "../models/UserProject";
import roles from "../constants/Roles";
import RequestFeedback from "../models/ResponseFeedback";
import {AppThunkAction} from "./index";
import {crudActionTypes, KnownCrudAction} from "./CrudState";
import {RestErrorResponse, RestSuccessResponse} from "../models/RestResponse";
import snackbar from "../services/snackbar";

export interface AuthState {
    authorization: Authorization | undefined;
    currentUser: User | undefined;
    // currentProject: Project | undefined;
    authenticated: boolean;
    loading: boolean;
    feedback: RequestFeedback | undefined;
    remember: boolean;
}

export const initialAuthState: AuthState = {
    authorization: undefined,
    currentUser: undefined,
    authenticated: false,
    loading: false,
    feedback: undefined,
    remember: false,
};

interface AuthActionTypes {
    readonly LOADING: 'AUTH_LOADING';
    readonly ERROR: 'AUTH_ERROR';
    readonly SIGN_IN: 'AUTH_SIGN_IN';
    readonly SIGN_OUT: 'AUTH_SIGN_OUT';
    readonly SET_REMEMBER: 'AUTH_UPDATE_REMEMBER';
    readonly SET_CURRENT_USER: 'AUTH_UPDATE_USER';
    readonly SET_CURRENT_PROJECT: 'AUTH_UPDATE_PROJECT';
    readonly UPDATE_TOKEN: 'UPDATE_TOKEN';

    // static readonly LOGIN = 'AUTH_LOGIN';
    // static readonly LOGOUT = 'AUTH_LOGOUT';
}

export const authActionsTypes: AuthActionTypes = {
    LOADING: 'AUTH_LOADING',
    ERROR: 'AUTH_ERROR',
    SIGN_IN: 'AUTH_SIGN_IN',
    SIGN_OUT: 'AUTH_SIGN_OUT',
    SET_REMEMBER: 'AUTH_UPDATE_REMEMBER',
    SET_CURRENT_USER: 'AUTH_UPDATE_USER',
    SET_CURRENT_PROJECT: 'AUTH_UPDATE_PROJECT',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
};

export interface AuthLoadingAction {
    type: 'AUTH_LOADING';
}

export interface AuthErrorAction {
    type: 'AUTH_ERROR';
    feedback: RequestFeedback;
}

export interface AuthSignInAction {
    type: 'AUTH_SIGN_IN';
    authorization: Authorization;
    user: User;
}

export interface AuthSignOutAction {
    type: 'AUTH_SIGN_OUT';
}

export interface AuthUpdateRememberAction {
    type: 'AUTH_UPDATE_REMEMBER';
    remember: boolean;
}

export interface AuthUpdateUserAction {
    type: 'AUTH_UPDATE_USER';
    user: User;
}

export interface AuthUpdateProjectAction {
    type: 'AUTH_UPDATE_PROJECT';
    projectId: string;
}

export interface AuthUpdateTokenAction {
    type: 'UPDATE_TOKEN';
    authorization: Authorization;
    user: User;
}


// Declare a 'discriminated union' variant. This guarantees that all references to 'variant' properties contain one of the
// declared variant strings (and not any other arbitrary string).
export type KnownAuthAction =
    AuthLoadingAction
    | AuthErrorAction
    | AuthSignInAction
    | AuthSignOutAction
    | AuthUpdateRememberAction
    | AuthUpdateUserAction
    | AuthUpdateProjectAction
    | AuthUpdateTokenAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const authActions = {
    signInWithEmail: (email: string, password: string): AppThunkAction<KnownAuthAction | CallHistoryMethodAction | KnownCrudAction<UserProject>> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.LOADING});
            const response = await services.auth.signInWithEmail(email, password);
            if (!response.success) {
                return dispatch({type: authActionsTypes.ERROR, feedback: response.feedback});
            }

            const currentUser = response.value.user;
            const projectResponse = await services.auth.signInWithProject(currentUser.projects[0]?.id);
            if (!projectResponse.success) {
                return dispatch({type: authActionsTypes.ERROR, feedback: projectResponse.feedback});
            }

            // TODO dont
            currentUser.permissions = roles[projectResponse.value.user.roleId]?.permissions ?? {};

            const authorization = response.value.authorization;
            authorization.accessToken = projectResponse.value.authorization.accessToken;

            await dispatch({
                type: authActionsTypes.SIGN_IN,
                authorization: authorization,
                user: currentUser,
            });

            await dispatch({
                name: projectStateName,
                type: crudActionTypes.REFRESH,
                elements: currentUser.projects,
            });

            await dispatch({
                name: projectStateName,
                type: crudActionTypes.SELECT,
                element: projectResponse.value.project,
            });

            await dispatch(push(Routes.dashboard));

        };
    },

    signOut: (): AppThunkAction<KnownAuthAction | Action | CallHistoryMethodAction> => {
        return async (dispatch, getState) => {
            const state = getState();
            await state.chat.client?.connection?.stop()
            await dispatch({type: crudActionTypes.CLEAR});
            await dispatch({type: authActionsTypes.SIGN_OUT});
            await dispatch(push(Routes.landing));
        };
    },

    setRemember: (remember: boolean): AppThunkAction<KnownAuthAction> => {
        return async (dispatch) => {
            await dispatch({type: authActionsTypes.SET_REMEMBER, remember: remember});
        };
    },


    updateCurrentUser(values: UserIdentification): AppThunkAction<KnownAuthAction> {
        return async (dispatch, getState) => {
            const state = getState();
            const currentUser = state.auth.currentUser;
            if (currentUser === undefined) return;


            const response = await services.me.update(values);
            if (!response.success) {
                return snackbar.showFeedback(response.feedback);
            }

            await dispatch({
                type: authActionsTypes.SET_CURRENT_USER,
                user: {
                    ...currentUser,
                    firstName: response.value.firstName,
                    lastName: response.value.lastName,
                    email: response.value.email,
                    phoneNumber: response.value.phoneNumber,
                },
            });
            snackbar.showFeedback({
                ...response.feedback,
                message: 'Dine oplysninger er blevet opdateret'
            })



        }
    },

    signInWithToken(): AppThunkAction<KnownAuthAction | KnownCrudAction<UserProject> | CallHistoryMethodAction> {
        return async (dispatch) => {
            const response = await services.auth.fetchAuthorization();
            if (!response.success) {
                return;
            }

            const currentUser = response.value.user;
            const projectResponse = await services.auth.signInWithProject(currentUser.projects[0]?.id);
            if (!projectResponse.success) {
                return dispatch({type: authActionsTypes.ERROR, feedback: projectResponse.feedback});
            }
            const authorization = response.value.authorization;
            authorization.accessToken = projectResponse.value.authorization.accessToken;
            const currentProject = projectResponse.value.project;

            await dispatch({
                type: authActionsTypes.SIGN_IN,
                authorization: services.client.getAuthorization(),
                user: currentUser,
            });

            await dispatch({
                name: projectStateName,
                type: crudActionTypes.REFRESH,
                elements: currentUser.projects,
            });

            await dispatch({
                name: projectStateName,
                type: crudActionTypes.SELECT,
                element: currentProject,
            });

            dispatch(push(Routes.dashboard));
        }
    },
    updateToken: function (projectId?: string): AppThunkAction<KnownAuthAction | KnownCrudAction<UserProject>> {
        return async (dispatch, getState) => {
            if (projectId === undefined) return;
            const currentUser = getState().auth.currentUser;

            const response = await services.auth.signInWithProject(projectId);
            if (!response.success) {
                return;
            }

            const currentProject = response.value.project;
            await dispatch({
                name: projectStateName,
                type: crudActionTypes.SELECT,
                element: currentProject,
            });


            const user = {...emptyUser, ...currentUser};
            user.permissions = roles[response.value.user.roleId]?.permissions ?? {};
            await dispatch({
                type: authActionsTypes.SET_CURRENT_USER,
                user: user,
            })

        }
    },
    forgotPassword(email: string): AppThunkAction<RestErrorResponse | RestSuccessResponse<undefined>> {
        return async () => {
            return await services.auth.forgotPassword(email);
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const authReducer: Reducer<AuthState> = (state: AuthState | undefined, incomingAction: Action): AuthState => {
    if (state === undefined) {
        return initialAuthState;
    }

    const action = incomingAction as KnownAuthAction;
    switch (action.type) {
        case authActionsTypes.LOADING:
            return {
                ...state,
                loading: true,
            };
        case authActionsTypes.ERROR:
            return {
                ...state,
                loading: false,
                feedback: action.feedback,
            };
        case authActionsTypes.SIGN_IN: {
            return {
                ...state,
                loading: false,
                feedback: undefined,
                currentUser: action.user,
                authenticated: !!(action.user?.id),
                authorization: action.authorization,
            };
        }
        case authActionsTypes.SIGN_OUT: {
            clearStorage();
            return {
                ...initialAuthState
            };
        }
        case authActionsTypes.SET_REMEMBER:
            return {
                ...state,
                remember: action.remember,
            };
        case authActionsTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.user,
            };
        case authActionsTypes.UPDATE_TOKEN:
            return {
                ...state,
                authorization: action.authorization,
                currentUser: action.user,
            }
        default:
            return state;
    }
};


export default authReducer;