import Youth from "../../models/Youth";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import RestResponse, {responseFromError} from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";

export type YouthServiceType = CrudPropertyServiceType<Youth> & {
    updateAll: (projectId: string, youth: Youth[]) => RestResponse<Youth[]>;
};

export const youthService = (client: RestClient): YouthServiceType => {
    const basePath = '/api/web/v1/projects';
    return {
        ...crudPropertyService<Youth>(client, basePath, "youth"),
        updateAll: async (projectId, youths) => {
            if (!youths.length) return responseFromError(400);
            const path = basePath + "/" + projectId + "/youth";
            return client.fetchJSON<Youth[]>(RequestMethod.PUT, path, {youths});
        }
    };
};

export default youthService;
