import React, {useState} from 'react';
import {IconButton, IconButtonProps, Tooltip} from '@material-ui/core';
import VideoIcon from 'remixicon-react/VideoAddFillIcon'
import useStartCall from "../../hooks/useStartCall";
import VideoLinkDialog from "../dialogs/VideoLinkDialog";

export interface VideoLinkButtonProps {
    callId: string;
    onVideo?: () => void;
    ButtonProps?: IconButtonProps;
}

const VideoLinkButton: React.FC<VideoLinkButtonProps> = ({callId, onVideo, ButtonProps}) => {
    const [handleStartCall, mediaFeedback] = useStartCall(callId);
    const [open, setOpen] = useState<boolean>(false);

    const handleButtonClick = async () => {
        setOpen(true)
        await handleStartCall();
    }

    return (
        <React.Fragment>
            <Tooltip title="Start videosamtale">
                <IconButton color='primary' onClick={handleButtonClick} {...ButtonProps}>
                    <VideoIcon/>
                </IconButton>
            </Tooltip>
            <VideoLinkDialog
                callId={callId}
                onOpen={setOpen}
                onStart={onVideo}
                open={open}
                loading={mediaFeedback.loading}
            />
        </React.Fragment>
    )
};


export default VideoLinkButton;
