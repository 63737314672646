import React from "react";
import {Formik, FormikHelpers} from "formik";
import {emptySurvey, Survey} from "../../models/Survey";
import {useDispatch} from "react-redux";
import {surveyActions} from "../../store/SurveyState";
import SurveyFormFields from "./SurveyFormFields";
import useCurrentProject from "../../hooks/useCurrentProject";

interface SurveyCreatePageProps {
    onSubmit: () => void;
    submitButtonRef: React.RefObject<HTMLButtonElement>;
    initial?: Survey;
}

export const SurveyFormik: React.FC<SurveyCreatePageProps> =
    ({
         submitButtonRef,
         initial,
         onSubmit,
     }) => {

        const dispatch = useDispatch();
        const currentProject = useCurrentProject();

        const handleSubmit = async (survey: Survey, {setSubmitting, resetForm}: FormikHelpers<Survey>) => {
            await dispatch(surveyActions.updateElement(currentProject?.id, survey))
            setSubmitting(false);
            resetForm();
            onSubmit();
        }

        const initialValues: Survey = {
            ...emptySurvey,
            ...initial,
        }

        return (
            <Formik<Survey>
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({values}) => (
                    <SurveyFormFields submitButtonRef={submitButtonRef}/>
                )}
            </Formik>
        )
    }


export default SurveyFormik;




