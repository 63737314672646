import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import UserIcon from 'remixicon-react/UserLineIcon';
import ColoredIconButton from "../../components/buttons/ColoredIconButton";
import useCurrentUser from "../../hooks/useCurrentUser";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 40,
        cursor: 'pointer',
        color: "black",
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    img: {
        height: 48,
        width: 48,
        borderRadius: '50%',
        pointerEvents: 'none'
    }
}))

export const ProfileButton: React.FC = () => {
    const classes = useStyles();
    const currentUser = useCurrentUser();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();
    const handleClick = () => dispatch(push(Routes.profile));

    return (
        <Box onClick={handleClick} className={classes.root}>
            {!mobile && currentUser?.firstName && (
                <Box component="span" mr={1}>{currentUser.firstName}</Box>
            )}
            <ColoredIconButton inverse={mobile}
                               flat
                               style={{
                                   padding: 0,
                                   height: 48,
                                   width: 48,
                                   background: currentUser?.imageUrl ? "white" : "inheret"
                               }}
            >
                {currentUser?.imageUrl ? (
                    <img alt="Profil" className={classes.img} src={currentUser.imageUrl}/>
                ) : (
                    <UserIcon/>
                )}
            </ColoredIconButton>
        </Box>
    )
}

export default ProfileButton;