import Project from "../../models/Project";
import ProjectChannel from "../../models/ProjectChannel";
import {crudService, CrudServiceType} from "./crudService";
import RestResponse, {responseFromError} from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";


export type ProjectServiceType = CrudServiceType<Project> & {
    updateImage: (id?: string, logo?: File) => RestResponse<Required<Project>>;
    updateChannels: (id: string, channels: ProjectChannel[]) => RestResponse<Required<Project>>;
};

export const projectService = (client: RestClient): ProjectServiceType => {
    const basePath = '/api/web/v1/projects';
    return {
        ...crudService<Project>(client, basePath),
        updateImage: async (id, file) => {
            if (!id?.length || file === undefined) return responseFromError(400);
            const requestBody = new FormData();
            requestBody.append("file", file);
            return client.fetchForm(RequestMethod.PUT, basePath + '/' + id + '/image', requestBody)
        },
        updateChannels: async (id, channels) => {
            if (!id?.length || channels === undefined) return responseFromError(400);
            return client.fetchJSON(RequestMethod.PUT, basePath + '/' + id + '/project-channels', channels as any)
        },
    };
};

export default projectService;


