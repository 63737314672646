import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import Routes from "../../constants/Routes";
import ProjectUser, {emptyProjectUser, projectUserSearchFilter} from "../../models/ProjectUser";
import useProjectUserState from "../../hooks/useProjectUserState";
import usePermitted from "../../hooks/usePermitted";
import {permissions, RoleMap} from "../../constants/Roles";
import BasePage from "../../components/containers/BasePage";
import {ListItemIcon, MenuItem} from "@material-ui/core";
import BasePageToolbar from "../../components/containers/BasePageToolbar";
import {projectUserActions} from "../../store/ProjectUserState";
import useCurrentProject from "../../hooks/useCurrentProject";
import MenuTable from "../../components/tables/MenuTable";
import HeadItem from "../../components/tables/HeadItem";
import FormDrawer from "../../components/drawers/FormDrawer";
import ProjectUserForm from "../../forms/ProjectUserForm";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";

const heads: HeadItem<ProjectUser>[] = [
    {id: "name", numeric: false, disablePadding: false, label: "Navn"},
    {id: "email", numeric: false, disablePadding: false, label: "Email"},
    {id: "phoneNumber", numeric: false, disablePadding: false, label: "Telefonnr."},
    {id: "roleId", numeric: false, disablePadding: false, label: "Systemrolle"}
]

export const UsersPage = () => {
    const dispatch = useDispatch();
    const currentProjectId = useCurrentProject().id;
    const state = useProjectUserState();
    const userWritePermission = usePermitted(permissions.users.write);

    // hooks for list menu and user create/edit/delete
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userId, setUserId] = useState('');
    const [user, setUser] = useState<ProjectUser>();
    const [submitButtonRef, handleSubmitClick] = useSubmitButtonRef();
    const handleOpen = () => setUser(emptyProjectUser);
    const handleClose = () => setUser(undefined)

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorEl(e.currentTarget);
        setUserId(id);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setUserId("");
    }
    const handleEdit = () => {
        const user = state.elements.find(y => y.id === userId);
        setUser(user);
        handleMenuClose();
    }
    const handleDelete = () => {
        const user = state.elements.find(y => y.id === userId);
        dispatch(projectUserActions.deleteElement(currentProjectId, user))
        handleMenuClose();
    }
    const onSubmit = async (values: ProjectUser) => {
        await dispatch(projectUserActions.updateElement(currentProjectId, values));
        dispatch(push(Routes.users))
        handleClose();
    }

    const [search, setSearch] = useState('');
    const filteredElements = state.elements.filter(projectUserSearchFilter(search));
    const formattedUsers: ProjectUser[] = filteredElements.map(u => ({...u, roleId: RoleMap[u.roleId]}))

    return (
        <BasePage>
            <BasePageToolbar
                search={search}
                onSearch={setSearch}
                permitted={userWritePermission}
                onCreateClick={handleOpen}
            />
            <MenuTable
                heads={heads}
                rows={formattedUsers}
                onMenuClick={handleMenuClick}
            />
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <Edit2LineIcon/>
                    </ListItemIcon>
                    <Typography variant="inherit">Rediger bruger</Typography>
                </MenuItem>
                <DeleteMenuItem
                    title="Slet bruger"
                    message="Er du sikker på, at du vil slette denne bruger?"
                    onConfirm={handleDelete}
                />
            </Menu>
            <FormDrawer
                title="Opret bruger"
                open={Boolean(user)}
                onSave={handleSubmitClick}
                onCancel={handleClose}
                loading={state.loading}
                feedback={state.feedback}
            >
                <ProjectUserForm
                    submitButtonRef={submitButtonRef}
                    onSubmit={onSubmit}
                    initial={user}
                />
            </FormDrawer>
        </BasePage>
    )
}

export default UsersPage;