import React from 'react';
import {Field, Form, Formik} from 'formik';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import {Box} from "@material-ui/core";

import {toLocalISODate, toLocalISOTime} from "../../lib/toLocalISO";
import CalendarEvent, {CalendarEventFormValues} from "../../models/CalendarEvent";
import Checkbox from "@material-ui/core/Checkbox";
import EventTimeFields from "./EventTimeFields";
import EventParticipantField from "./EventParticipantField";
import {urlRegex} from "../../constants/regex";
import Tooltip from "@material-ui/core/Tooltip";
import InformationIcon from 'remixicon-react/InformationLineIcon';
import EventRepeatField from "./EventRepeatField";

const useStyles = makeStyles(({spacing, palette}) => ({
    title: {
        padding: spacing(2, 0),
        '& label': {
            fontSize: '28px',
        },
        '& input': {
            height: 44,
            fontSize: '28px',
            color: palette.text.primary,
        }
    },
    label: {
        minWidth: spacing(6),
        fontSize: 18,
    },
    checkboxLabel: {
        minWidth: spacing(6),
        fontSize: 18,
        '& .MuiCheckbox-root': {
            marginLeft: '-9px',
        }
    },
    header: {
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    row: {
        display: "flex",
        alignItems: "center",
        paddingBottom: spacing(2),
    }
}));

export interface EventFormProps {
    event?: CalendarEvent;
    onSubmit: (event: CalendarEvent) => void;
    submitButtonRef: React.RefObject<HTMLButtonElement>;
    eventType?: string;
}


export const EventForm: React.FC<EventFormProps> = ({event, onSubmit, submitButtonRef}) => {
    const classes = useStyles();

    if (!event) {
        return <div/>
    }

    const initialValues: CalendarEventFormValues = {
        title: event?.title ?? '',
        description: event?.description ?? '',
        location: event?.location ?? '',
        streamUrl: event?.streamUrl ?? '',
        start: new Date(event?.start ?? ''),
        end: new Date(event?.end ?? ''),
        startDate: toLocalISODate(event?.start ?? ''),
        endDate: toLocalISODate(event?.end ?? ''),
        allDay: false, //event.allDay,
        status: event?.isCancelled ?? false,
        isPublic: event?.isPublic ?? false,
        participantInput: '',
        participants: event?.participants ?? [],
        maxParticipants: !event?.maxParticipants ? undefined : event.maxParticipants,
        occurrences: event?.occurrences ?? undefined,
        groupId: event?.groupId ?? undefined,
    };

    const handleSubmit = (values: CalendarEventFormValues) => {
        const start = new Date(values.startDate + "T" + toLocalISOTime(values.start));
        // use startDate because end date input is removed
        const end = new Date(values.startDate + "T" + toLocalISOTime(values.end));


        onSubmit({
            id: event?.id,
            title: values.title,
            description: values.description,
            location: values.location,
            createdBy: event?.createdBy,
            start: start,
            end: end,
            streamUrl: values.streamUrl,
            allDay: values.allDay,
            isCancelled: values.status,
            isPublic: values.isPublic,
            participants: values.participants,
            maxParticipants: values.maxParticipants,
            occurrences: values.occurrences,
            groupId: event?.groupId,
        });
    };

    return (
        <Formik<CalendarEventFormValues> onSubmit={handleSubmit} initialValues={initialValues}>
            {({values, setFieldValue, errors}) => (
                <Form style={{height: "100%"}}>
                    <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                    <Box display="flex" flexDirection="column" height="100%" className="MuiDialogContent-root">

                        <Field
                            as={TextField}
                            className={classes.title}
                            variant="standard"
                            required
                            fullWidth
                            id="title"
                            name="title"
                            placeholder={'Tilføj titel'}
                            type="text"
                            autoFocus={true}
                        />

                        <EventTimeFields values={values} setFieldValue={setFieldValue}/>

                        <div className={classes.row}>
                            <Checkbox
                                color="primary"
                                name="isPublic"
                                id="isPublic"
                                checked={values.isPublic}
                                onChange={(event) => setFieldValue('isPublic', event.target.checked, false)}
                            />
                            <span>Gør begivenheden åben</span>
                        </div>

                        {!event?.id && (
                            <div className={classes.row}>
                                <EventRepeatField
                                    occurrences={values.occurrences}
                                    startDate={values.startDate}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        )}

                        <div className={classes.row}>
                            <Field
                                as={TextField}
                                error={errors.streamUrl}
                                helperText={errors.streamUrl}
                                id="streamUrl"
                                name="streamUrl"
                                type="url"
                                placeholder='Tilføj streaming URL'
                                validate={(s: string) => {
                                    if (!s || !s.trim()) return '';
                                    return s.trim().match(urlRegex) ? '' : 'Linket er ikke gyldigt';
                                }}
                                fullWidth
                            />
                            <Tooltip
                                title={'Indsæt link til live-stream hvis begivenheden ikke er en video-rådgivning'}
                            >
                                <div>
                                    <InformationIcon/>
                                </div>
                            </Tooltip>
                        </div>

                        <div className={classes.row}>
                            <Field
                                as={TextField}
                                id="location"
                                name="location"
                                type="location"
                                placeholder='Tilføj lokation'
                                fullWidth
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                as={TextField}
                                multiline={true}
                                rows={4}
                                id="description"
                                name="description"
                                type="description"
                                placeholder='Tilføj beskrivelse'
                                fullWidth
                            />
                        </div>

                        <div className={classes.row}>
                            <Field
                                as={TextField}
                                color={'secondary'}
                                id="maxParticipants"
                                name="maxParticipants"
                                type="number"
                                placeholder='Max deltagere'
                                fullWidth
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("maxParticipants", Math.max(0, parseInt(e.target.value)));
                                }}
                            />
                        </div>

                        <div className={classes.row}>
                            <EventParticipantField
                                participants={values.participants}
                                input={values.participantInput}
                                setFieldValue={setFieldValue}
                            />
                        </div>

                    </Box>
                </Form>
            )}
        </Formik>
    )
};

export default EventForm;




