import React, {useCallback, useEffect} from "react";
import useStartCall, {CallStreamConfig} from "../../hooks/useStartCall";
import {useParams} from "react-router-dom";
import useTypedSelector from "../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {Box} from "@material-ui/core";
import useQueryParams from "../../hooks/useQueryParams";
import VideoActions from "./VideoActions";
import {callActions} from "../../store/CallState/actions";
import {StreamType} from "../../store/CallState/models";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MiniMe from "./MiniMe";
import Stream from "./Stream";
import PeerController from "./PeerController";
import ChatDrawer from "./ChatDrawer";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#111111",
    },
}))

const VideoPage = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        localStream,
        localAudioEnabled,
        localVideoEnabled,
        rtcPeers,
        rtcPeerStates
    } = useTypedSelector(state => state.call);

    const {id} = useParams<{ id: string }>();
    const query = useQueryParams();
    const audio = query.get("audio") === "true";
    const video = query.get("video") === "true";

    const config: CallStreamConfig = {audio, video};
    const [handleStartCall] = useStartCall(id, config);

    const handleToggleAudio = useCallback(() => dispatch(callActions.updateStream(StreamType.audio)), [dispatch]);
    const handleToggleVideo = useCallback(() => dispatch(callActions.updateStream(StreamType.video)), [dispatch]);

    const handleEndCall = useCallback(() => {
        dispatch(callActions.disconnect(id));
        window.close();
    }, [dispatch, id]);

    useEffect(() => {
        const joinCall = async () => {
            await handleStartCall();
            dispatch(callActions.join());
        }
        joinCall();
    }, [audio, dispatch, handleStartCall, video]);

    return (
        <React.Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                height="unset"
                maxHeight="100vh"
                width="100%"
                position="relative"
                className={classes.root}
            >
                <MiniMe stream={localStream} video={localVideoEnabled} audio={localAudioEnabled}/>
                {rtcPeers.length === 0 && (
                    <Box
                        height="100vh"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Stream stream={localStream} video={localVideoEnabled} audio={localAudioEnabled} me/>
                    </Box>
                )}
                {rtcPeers.length > 0 && (
                    <PeerController
                        peers={rtcPeers}
                        states={rtcPeerStates}
                    />
                )}
                <VideoActions
                    audioEnabled={localAudioEnabled}
                    videoEnabled={localVideoEnabled}
                    onToggleAudio={handleToggleAudio}
                    onToggleVideo={handleToggleVideo}
                    onEndCall={handleEndCall}
                />
            </Box>
            <ChatDrawer/>
        </React.Fragment>
    )
}


export default VideoPage;

