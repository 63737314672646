import React from "react";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Stream, {StreamProps} from "./Stream";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        zIndex: 10,
        position: 'absolute',
        top: 16,
        right: 16,
        height: 90,
        width: 120,
        borderRadius: 5,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    you: {
        zIndex: 2,
        position: 'absolute',
        bottom: 8,
        left: 8,
        color: 'white',
        fontSize: 14,
    },
}))

type MiniMeProps = Pick<StreamProps, "stream" | "video" | "audio">

const MiniMe: React.FC<MiniMeProps> = ({stream, audio, video}) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <span className={classes.you}>
                dig
            </span>
            <Stream
                stream={stream}
                audio={audio}
                video={video}
                small
                me
            />
        </Box>
    )
}

export default MiniMe;
