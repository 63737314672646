import {Field} from "formik";
import TextField from "@material-ui/core/TextField/TextField";
import {toLocalISODate} from "../../lib/toLocalISO";
import {Box} from "@material-ui/core";
import {KeyboardTimePicker} from "@material-ui/pickers";
import React from "react";
import {CalendarEventFormValues} from "../../models/CalendarEvent";
import CalendarEventLineIcon from "remixicon-react/CalendarEventLineIcon";

interface EventTimeFieldsProps {
    values: CalendarEventFormValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const EventTimeFields: React.FC<EventTimeFieldsProps> = ({values, setFieldValue}) => {


    const keyboardButtonProps = {
        'aria-label': 'skift tidspunkt',
    };

    const handleDate = (event: any) => {
        const date = event.target.value;
        setFieldValue("startDate", date)
        if (date !== null && date !== undefined) {
            const diffTime = new Date(date).getTime() - new Date(values.startDate).getTime();
            const endDate = new Date(new Date(values.endDate).getTime() + diffTime);
            setFieldValue("endDate", toLocalISODate(endDate))
        }
    }
    const handleStartTime = (date: any) => setFieldValue('start', date);
    const handleEndTime = (date: any) => setFieldValue('end', date);

    return (
        <Box display="flex" alignItems="baseline">
            <Field
                as={TextField}
                required
                id="startDate"
                name="startDate"
                type="date"
                fullWidth
                onChange={handleDate}
            />
            <Box paddingRight={2}/>
            <Field
                as={KeyboardTimePicker}
                required
                id="start"
                name="start"
                margin="normal"
                ampm={false}
                fullWidth
                KeyboardButtonProps={keyboardButtonProps}
                onChange={handleStartTime}
                keyboardIcon={<CalendarEventLineIcon size={16}/>}
                invalidDateMessage="Ugyldigt format"
            />
            <Box paddingLeft={2} paddingRight={2} component="span">til</Box>
            <Field
                as={KeyboardTimePicker}
                required
                id="end"
                name="end"
                margin="normal"
                ampm={false}
                fullWidth
                KeyboardButtonProps={keyboardButtonProps}
                onChange={handleEndTime}
                keyboardIcon={<CalendarEventLineIcon size={16}/>}
                invalidDateMessage="Ugyldigt format"
            />
        </Box>
    )
}

export default EventTimeFields;
