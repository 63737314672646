import {Box, List, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import More2FillIcon from "remixicon-react/More2FillIcon";
import React, {useState} from "react";
import {GroupBy} from "../../lib/groupBy";
import Youth from "../../models/Youth";
import YouthGroupMenu from "./YouthGroupMenu";

interface YouthGroupListProps {
    groupsOfYouths: GroupBy<Youth>[];
    selectedGroupName: string | undefined;
    onSelect: (name: string) => void;
    search: string;
}

const YouthGroupList: React.FC<YouthGroupListProps> = ({groupsOfYouths, selectedGroupName, search, onSelect}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedMenuGroupName, setSelectedMenuGroupName] = useState<string>();

    const handleMenuClose = () => {
        setAnchorEl(null);
        // setSelectedMenuGroupName(undefined);
    }
    const handleMenuClick = (groupName: string) => (e: React.MouseEvent<HTMLElement>) => {
        setSelectedMenuGroupName(groupName);
        setAnchorEl(e.currentTarget);
    }
    const handleSelect = (groupName: string) => () => onSelect(groupName);

    const menuGroupMembers = groupsOfYouths.find(g => g.name === selectedMenuGroupName)?.items ?? [];
    const filteredGroups = groupsOfYouths.filter(g => g.name.toLowerCase().includes(search));


    return (
        <List disablePadding={true}>
            {filteredGroups.map(group => (
                <Box key={group.name} width={256} pb={2} pr={1}>
                    <ListItem
                        button
                        selected={group.name === selectedGroupName}
                        onClick={handleSelect(group.name)}
                    >
                        <ListItemText
                            primary={group.name}
                            primaryTypographyProps={{noWrap: true}}
                            secondary={group?.items.map(y => y.name).join(", ")}
                            secondaryTypographyProps={{noWrap: true}}
                        />
                        <ListItemSecondaryAction onClick={handleMenuClick(group.name)}>
                            <IconButton>
                                <More2FillIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </Box>
            ))}
            <YouthGroupMenu
                groupName={selectedMenuGroupName}
                members={menuGroupMembers}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            />
        </List>
    )
}

export default YouthGroupList;
