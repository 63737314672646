import NamedValue from "../models/NamedValue";

export class FeatureNames {
    public static readonly contact = "contact";
    public static readonly journal = "journal";
    public static readonly survey = "survey";
    public static readonly overview = "overview";
    // public static readonly analytics = "analytics";

    public static readonly all = [FeatureNames.contact, FeatureNames.journal, FeatureNames.survey];
    public static readonly options: NamedValue[] = [{
        id: FeatureNames.contact,
        name: "Henvendelser",
    }, {
        id: FeatureNames.journal,
        name: "Journalføring",
    }, {
        id: FeatureNames.survey,
        name: "Spørgeskema",
    }];
}

export default FeatureNames;

