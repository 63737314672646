import React from "react";
import Button from "@material-ui/core/Button";


export const TestPage: React.FC = () => {

    // const [state, setState] = useState();

    const onButtonClick = async () => {
    }

    return (
        <div>
            TEST
            <Button onClick={onButtonClick}>
                CLICK ME
            </Button>
            {/*{JSON.stringify(state, null, 2)}*/}
        </div>
    )
}