class Routes {

    // admin
    static readonly admin = '/admin';

    // auth
    static readonly landing = '/';
    static readonly forgotPassword = '#forgot-password';
    static readonly resetPassword = '/reset-password';
    static readonly register = '/register';
    static readonly registerAuth = '/register/auth';
    static readonly registerPicture = '/register/picture';

    // error
    static readonly error = '/error';
    static readonly test = '/test';

    // dashboard
    static readonly dashboard = '/dashboard';
    static readonly home = '/dashboard/home';
    static readonly chat = '/dashboard/chat';
    static readonly chatMobile = '/chat';
    static readonly journal = '/dashboard/journal';
    static readonly calendar = '/dashboard/calendar';
    static readonly profile = '/dashboard/profile';


    // overview
    static readonly overview = '/dashboard/overview';
    static readonly overviewPreview = '/dashboard/overview/preview';

    // survey
    static readonly surveys = '/dashboard/surveys';
    static readonly surveyAnswer = '/project/:projectId/surveys/:surveyId';

    // home
    static readonly post = '/dashboard/home/post';
    static readonly agenda = '/dashboard/home/agenda';
    static readonly inbox = '/dashboard/home/inbox';

    // settings
    static readonly projectSettings = '/dashboard/settings/project';
    static readonly users = '/dashboard/users';

    // static readonly roles = '/home/roles';

    // calendar sub dashboard
    static readonly shiftPlanner = '/dashboard/calendar/shift-planner';
    static readonly userTimeTable = '/dashboard/calendar/time-overview';

    // youth
    static readonly youth = '/dashboard/youth';
    static readonly youthGroup = '/dashboard/youth/group';

    // video
    static readonly video = '/video';
}

export default Routes;
