import {Survey, SurveyAnswers} from "../../models/Survey";
import {SurveyAnswerRequest} from "../../models/SurveyAnswer";
import CsvFileResponse from "../../models/CsvFileResponse";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import RestResponse from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";


export type SurveyServiceType = CrudPropertyServiceType<Survey> & {
    submitAnswer: (projectId: string, surveyId: string, request: SurveyAnswerRequest) => RestResponse<SurveyAnswerRequest>;
    getAnswers: (projectId: string, surveyId: string) => RestResponse<SurveyAnswers>;
    getAnswersCSV: (projectId: string, surveyId: string) => RestResponse<CsvFileResponse>;
};

const answerPath = (pid: string, sid: string) => '/api/web/v1/projects/' + pid + '/surveys/' + sid + '/answer';

export const surveyService = (client: RestClient): SurveyServiceType => {
    return {
        ...crudPropertyService(client, '/api/web/v1/projects', 'surveys'),
        submitAnswer: (projectId, surveyId, request) => {
            return client.fetchJSON(RequestMethod.POST, answerPath(projectId, surveyId), {...request})
        },
        getAnswers: (projectId, surveyId) => {
            return client.fetchJSON(RequestMethod.GET, answerPath(projectId, surveyId));
        },
        getAnswersCSV: (projectId, surveyId) => {
            return client.fetchJSON(RequestMethod.GET, answerPath(projectId, surveyId) + "/csv");
        }
    };
};

export default surveyService;
