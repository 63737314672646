import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import ProfileForm from "../../forms/ProfileForm";
import {authActions} from "../../store/AuthState";
import BasePage from "../../components/containers/BasePage";
import ExpandedGrid from "../../components/material/ExpandedGrid";
import Grid from "@material-ui/core/Grid";
import SettingsContainer from "../settings/SettingsContainer";
import AvatarInput from "../../components/inputs/AvatarInput";
import services from "../../services/rest/services";
import randomInt from "../../lib/randomInt";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import {UserIdentification} from "../../models/User";
import useCurrentProject from "../../hooks/useCurrentProject";
import {projectUserActions} from "../../store/ProjectUserState";

const ProfilePage: React.FC = () => {
    const dispatch = useDispatch();
    const [submitButtonRef, handleSaveClick] = useSubmitButtonRef();
    const currentUser = useSelector((state: AppState) => state.auth.currentUser);
    const [profileImage, setProfileImage] = useState<string | undefined>(currentUser?.imageUrl);
    const currentProjectId = useCurrentProject().id;

    const onSubmit = async (values: UserIdentification) => {
        await dispatch(authActions.updateCurrentUser(values));
        await dispatch(projectUserActions.refresh(currentProjectId));
    }

    return (
        <BasePage>
            <ExpandedGrid container spacing={2} style={{marginTop: 8, marginBottom: 8}}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                        <SettingsContainer
                            title="Informationer"
                            onSave={handleSaveClick}
                            disabled={false}
                        >
                            <ProfileForm
                                submitButtonRef={submitButtonRef}
                                initial={currentUser}
                                onSubmit={onSubmit}
                            />
                        </SettingsContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                        <SettingsContainer
                            title="Upload profilbillede"
                            onSave={handleSaveClick}
                            disabled={true}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <AvatarInput
                                    name={[currentUser?.firstName, currentUser?.lastName].join(' ')}
                                    src={profileImage}
                                    onChange={async (file: File) => {
                                        const response = await services.me.updateImage(file)
                                        const url = response.success ? response.value.imageUrl + "?rand=" + randomInt() : '';
                                        setProfileImage(url);
                                    }}
                                />
                            </div>
                        </SettingsContainer>
                    </Grid>
                </Grid>
            </ExpandedGrid>
        </BasePage>
    );
}

export default ProfilePage;
