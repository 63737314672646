import React from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';

import TextField from '@material-ui/core/TextField/TextField';
import Grid from "@material-ui/core/Grid";
import FormikErrorDisplay from "../components/displays/FormikErrorDisplay";
import FormProps from "../models/FormProps";
import ProjectChannel, {emptyProjectChannel} from "../models/ProjectChannel";
import SelectNamed from "../components/inputs/SelectNamed";
import Validators from "../lib/Validators";
import NamedValue from "../models/NamedValue";

export type ProjectChannelFormProps = FormProps<ProjectChannel> & {
    options: NamedValue[]
};
export const ProjectChannelForm: React.FC<ProjectChannelFormProps> = ({submitButtonRef, initial, onSubmit, options}) => {

    const handleSubmit = async (values: ProjectChannel, {setSubmitting, resetForm}: FormikHelpers<ProjectChannel>) => {
        await onSubmit({
            ...values,
        });
        setSubmitting(false);
        resetForm();
    };

    return (
        <Formik<ProjectChannel>
            initialValues={{
                ...emptyProjectChannel,
                ...initial,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({values, errors, touched, setFieldValue}) => (
                <Form>
                    <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <Field
                                as={SelectNamed}
                                options={options}
                                name="id"
                                label="Type"
                                required
                                validate={Validators.notEmpty}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="name"
                                type="name"
                                label="Navn"
                                fullWidth
                                variant='filled'
                                value={values.name ?? ""}
                                validate={Validators.notEmpty}
                                error={errors.name && touched.name}
                                //helperText={values.name?.length !== 0 && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="info"
                                type="channelInfo"
                                label="Åbningstider"
                                fullWidth
                                variant='filled'
                                value={values.info ?? ""}
                                validate={Validators.notEmpty}
                                error={errors.info && touched.info}
                                //helperText={values.info.length !== 0 && errors.info}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                name="url"
                                type="phoneOrUrl"
                                label="Telefon nummer, adresse eller URL"
                                fullWidth
                                variant='filled'
                                values={values.url ?? ""}
                                validate={Validators.notEmpty}
                                error={errors.url && touched.url && values.url.length !== 0}
                                //helperText={values.url?.length !== 0 && errors.url}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormikErrorDisplay errors={errors}/>
                        </Grid>

                    </Grid>
                </Form>
            )}
        </Formik>
    );
}


export default ProjectChannelForm;

