export const sexOptions = [
    { id: "male", name: "Mand" },
    { id: "female", name: "Kvinde" },
    { id: "other", name: "Andet" },
    { id: "do_not_want_to_indicate", name: "Vil ikke angive" },
];
export const sexMap: { [p: string]: string } = {
    "male": "Mand",
    "female": "Kvinde",
    "other": "Andet",
    "do_not_want_to_indicate": "Vil ikke angive",
}

export default sexOptions;