import {User, UserIdentification} from "../../models/User";
import UserProject from "../../models/UserProject";
import RestResponse, {responseFromError} from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";


export interface MeServiceType {
    getProjects: () => RestResponse<Required<UserProject[]>>;
    update: (user: UserIdentification) => RestResponse<User>
    updateImage: (image?: File) => RestResponse<Required<User>>;
}

export const meService = (client: RestClient): MeServiceType => {
    const basePath = '/api/web/v1/me';
    return {
        getProjects: async () => {
            return client.fetchForm(RequestMethod.GET, basePath + '/projects')
        },
        update: async (user: UserIdentification) => {
            return client.fetchJSON(RequestMethod.PUT, basePath, {...user})
        },
        updateImage: async (file) => {
            if (file === undefined) return responseFromError(400);
            const requestBody = new FormData();
            requestBody.append("file", file);
            return client.fetchForm(RequestMethod.PUT, basePath + '/image', requestBody)
        }
    };
};

export default meService;


