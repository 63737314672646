import {CallAction, callActionsNames} from "./actions";
import {CallState, initialCallState} from "./index";
import {Reducer} from "redux";
import Hark from 'hark';
import {StreamType} from "./models";
import {isStreamEnabled, isStreamEqualsStream} from "./lib";

export const callReducer: Reducer<CallState> = (state: CallState | undefined, action: CallAction): CallState => {
    if (state === undefined) {
        return initialCallState;
    }

    switch (action.type) {
        case callActionsNames.LOADING:
            return {
                ...state,
                loading: true,
            };
        case callActionsNames.RESET:
            return initialCallState;
        case callActionsNames.CONNECT:
            return {
                ...state,
                id: action.id,
                localId: action.connectionId,
                localDeviceId: action.deviceId,
                connected: true,
                loading: false,
            };
        case callActionsNames.JOIN:
            return {
                ...state,
                joined: true,
            };
        case callActionsNames.DISCONNECT:
            return { ...initialCallState };
        case callActionsNames.JOIN_RTC_PEER:
            return {
                ...state,
                rtcPeers: [action.rtcPeer, ...state.rtcPeers],
            };
        case callActionsNames.SET_RTC_PEER_STREAM: {
            const rtcPeers = [...state.rtcPeers];
            const rtcPeerIndex = rtcPeers.findIndex((rtcPeer) => rtcPeer.id === action.callerId);
            rtcPeers[rtcPeerIndex].stream = action.stream;
            rtcPeers[rtcPeerIndex].hark = Hark(action.stream);
            return {
                ...state,
                rtcPeers,
            };
        }
        case callActionsNames.LEFT_RTC_PEER: {
            // TODO: Remove rtcPeer from local state
            const rtcPeers = [...state.rtcPeers];
            const rtcPeerIndex = rtcPeers.findIndex((rtcPeer) => rtcPeer.id === action.callerId);
            if (rtcPeerIndex === -1) return state;
            rtcPeers[rtcPeerIndex].hark?.stop();
            rtcPeers[rtcPeerIndex].rtc.destroy();
            rtcPeers.splice(rtcPeerIndex, 1);

            // TODO: Remove rtcPeerState from local state
            const rtcPeerStates = [...state.rtcPeerStates];
            const rtcPeerStateIndex = rtcPeerStates.findIndex((rtcPeerState) => rtcPeerState.id === action.callerId);
            if (rtcPeerStateIndex === -1) return state;
            rtcPeerStates.splice(rtcPeerStateIndex, 1);

            return {
                ...state,
                rtcPeerStates: rtcPeerStates,
                rtcPeers: rtcPeers,
            };
        }
        case callActionsNames.READ_RTC_PEER_STATE: {
            const indexOfPeer = state.rtcPeerStates.findIndex(
                (rtcPeerState) => rtcPeerState.id === action.rtcPeerState.id,
            );

            if (indexOfPeer === -1)
                return {
                    ...state,
                    rtcPeerStates: [...state.rtcPeerStates, action.rtcPeerState],
                };

            const peers = [...state.rtcPeerStates];
            peers[indexOfPeer] = action.rtcPeerState;

            return {
                ...state,
                rtcPeerStates: peers,
            };
        }
        case callActionsNames.SET_SELECTED_ID:
            return {
                ...state,
                selectedId: action.id,
            };
        case callActionsNames.SET_AUTO_SELECTED_ID:
            return {
                ...state,
                autoSelectedId: action.id,
            };
        case callActionsNames.SET_ACTIVE_STREAM:
            if (isStreamEqualsStream(state.activeStream, action.stream)) return state;
            return {
                ...state,
                localStreamActive: isStreamEqualsStream(state.localStream, action.stream),
                activeStream: action.stream,
            };
        case callActionsNames.REFRESH_STREAM:
            return {
                ...state,
                localStream: action.stream,
                activeStream: action.stream,
            };
        case callActionsNames.UPDATE_STREAM:
            return {
                ...state,
                localAudioEnabled: isStreamEnabled(StreamType.audio, action.stream),
                localVideoEnabled: isStreamEnabled(StreamType.video, action.stream),
            };
        case callActionsNames.REFRESH_REMOTE_STATE:
            return {
                ...state,
                rtcPeerStates: action.remoteState.peers,
                messages: action.remoteState.messages,
            };
        case callActionsNames.READ_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.message],
            };
        default:
            return state;
    }
};

