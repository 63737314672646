import React, {useState} from "react";
import {Box, Button, ButtonGroup} from "@material-ui/core";
import FormProps from "../../models/FormProps";
import {Field, Form, Formik, useFormikContext} from "formik";
import {emptySurveyAnswer, SurveyAnswerRequest} from "../../models/SurveyAnswer";
import {Survey} from "../../models/Survey";
import Paper from "@material-ui/core/Paper";
import {Swiper, SwiperSlide} from 'swiper/react';
import clsx from 'clsx';
import 'swiper/swiper-bundle.min.css';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CustomTheme} from "../../constants/theme";
import SwiperClass from "swiper/types/swiper-class";
import TimelineView from "../../components/TimelineView";
import TextField from "@material-ui/core/TextField";
import * as Yup from 'yup';
import Center from "../../components/containers/Center";
import useIsMobile from "../../hooks/useIsMobile";

const SurveySchema = Yup.object().shape({
    userId: Yup.string().email('Ugyldig email adresse').required('Angiv email adresse'),
    answers: Yup.array().of(Yup.object().shape({
                properties: Yup.object().shape({
                    choice: Yup.string().required('Du skal vælge ét svar'),
                }),
            }))
})

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'space-between',
        },
    },
    swiperCard: {
        boxShadow: theme.custom.boxShadow,
        opacity: 0.8,
        height: 220,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 40,
        transition: "all 0.4s ease",
    },
    swiperCardActive: {
        opacity: 1,
        '@media (min-height: 400px)': {
            height: 220,
        },
        '@media (min-height: 500px)': {
            height: 300,
        },
        '@media (min-height: 600px)': {
            height: 380,
        },
        '@media (min-height: 700px)': {
            height: 480,
        },
        '@media (min-height: 800px)': {
            height: 580,
        },
        '@media (min-width: 500px)': {
            height: 320,
        },
    },
    shape: {
        borderRadius: 25,
        margin: 5,
    },
}));

export type SurveyAnswerFormProps = FormProps<SurveyAnswerRequest> & { survey: Survey };

export const SurveyAnswerForm: React.FC<SurveyAnswerFormProps> = ({survey, onSubmit}) => {
    const initialValues: SurveyAnswerRequest = {
        userId: '',
        answers: [
            ...survey.questions.map((question) => {
                return {
                    ...emptySurveyAnswer,
                    surveyId: question.surveyId,
                    questionId: question.id,
                    type: question.type
                }
            })
        ]
    }

    // TODO: only validate progression if its on
    return (
        <Formik<SurveyAnswerRequest>
            initialValues={initialValues}
            validationSchema={SurveySchema}
            onSubmit={onSubmit}
        >
            <SurveyAnswerView survey={survey}/>
        </Formik>
    )
}

interface SurveyAnswerViewProps {
    survey: Survey;
}

const SurveyAnswerView: React.FC<SurveyAnswerViewProps> = ({survey}) => {
    const classes = useStyles();

    const isMobil = useIsMobile();
    const {values, errors, setFieldValue, isSubmitting, isValid, dirty} = useFormikContext<SurveyAnswerRequest>();
    const disabled = isSubmitting || !(isValid && dirty);

    const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
    const [timelineIndex, setTimelineIndex] = useState<number|undefined>(0);

    const onContinueClick = () => {
        swiper?.slideNext(400);
        const index = swiper?.activeIndex;
        setTimelineIndex(index);
    };

    const onBackClick = () => {
        swiper?.slidePrev(400);
        const index = swiper?.activeIndex;
        setTimelineIndex(index);
    }

    return (
        <Box className={classes.root}>
            <Form style={{width: '100%'}}>
                <Box display='flex' justifyContent='center' style={{color: 'white'}}>
                    <h2 style={{alignSelf: 'flex-end'}}>{survey.name}</h2>
                </Box>
                <Box width='100%'>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        style={{overflow: 'inherit'}}
                        allowTouchMove={false}
                        onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
                    >
                        <SwiperSlide>
                            {({isActive}: any) => (
                                <Box className={clsx(classes.swiperCard, isActive ? classes.swiperCardActive : "")}
                                     justifyContent={'space-between'}
                                     component={Paper}
                                     style={{overflow: 'hidden'}}
                                >
                                    <Box style={{width: '100%', overflowY: 'auto', textAlign: 'center'}}>
                                        <h4 style={{paddingBottom: 10}}>{survey.description}</h4>
                                        <Field
                                            as={TextField}
                                            error={errors.userId}
                                            helperText={errors.userId}
                                            style={{alignSelf: 'center'}}
                                            required
                                            id="userId"
                                            name="userId"
                                            type="email"
                                            autoComplete="email"
                                            placeholder="Skriv din email her ..."
                                            autoFocus
                                        />
                                    </Box>
                                    <Button className={classes.shape} variant="contained" color="primary"
                                            onClick={() => onContinueClick()}>
                                        Start
                                    </Button>
                                </Box>
                            )}
                        </SwiperSlide>
                        {survey.questions.map((question, index) => {
                            return (
                                <SwiperSlide key={question.id} virtualIndex={index}>
                                    {({isActive}: any) => (
                                        <Box
                                            className={clsx(classes.swiperCard, isActive ? classes.swiperCardActive : "")}
                                            flex={1}
                                            flexDirection={'column'}
                                            justifyContent={'space-between'}
                                            component={Paper}
                                            mb={4} p={4}
                                            key={"" + index + question.id}
                                        >
                                            <Box
                                                display={'flex'}
                                                flex={1}
                                                flexDirection={'column'}
                                                justifyContent={'space-between'}
                                                style={{height: '100%', overflowY: 'auto'}}
                                            >
                                                <p style={{
                                                    fontSize: '1.3em',
                                                    paddingBottom: 5,
                                                    textAlign: 'center',
                                                }}>{question.title}</p>
                                                <Box
                                                    flexDirection={isMobil ? 'row-reverse' : 'column'}
                                                    alignSelf={'center'}
                                                >
                                                    <ButtonGroup
                                                        size={"large"}
                                                        orientation={isMobil ? "vertical" : "horizontal"}
                                                        aria-label="large outlined primary button group"
                                                    >
                                                        {question.properties.choices.map((choice, choiceIndex) => {
                                                            const selected = values.answers[index]?.properties.choice === choice;
                                                            return (
                                                                <Button
                                                                    key={"" + choiceIndex + choice}
                                                                    onClick={() => {
                                                                        setFieldValue(`answers[${index}].properties.choice`, choice);
                                                                        onContinueClick();
                                                                    }}
                                                                    style={selected ? {
                                                                        background: '#1976D2',
                                                                        color: 'white',
                                                                        minWidth: isMobil ? 200 : 80,
                                                                        height: isMobil ? 40 : 60
                                                                    } : {minWidth: isMobil ? 200 : 80, height: isMobil ? 40 : 60}}
                                                                    color={selected ? "primary" : "default"}
                                                                >
                                                                    {choice}
                                                                </Button>
                                                            );
                                                        })}
                                                    </ButtonGroup>
                                                </Box>
                                                <Box/>
                                            </Box>
                                            {index === (survey.questions.length - 1) ? (
                                                <Box display={'flex'} flexDirection={'column'}>
                                                    <Center style={{color: 'red', width: '100%', margin: 2}}>
                                                        {errors.userId}
                                                    </Center>
                                                    <Box>
                                                        <Button
                                                            className={classes.shape}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => onBackClick()}
                                                        >
                                                            Tilbage
                                                        </Button>
                                                        <Button
                                                            className={classes.shape}
                                                            color='secondary'
                                                            variant='contained'
                                                            disabled={disabled}
                                                            type={'submit'}
                                                        >
                                                            Afslut
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            ) : (values.answers[index]?.properties.choice) ? (
                                                <Box>
                                                    <Button className={classes.shape} variant="contained"
                                                            color="primary" onClick={() => onBackClick()}>
                                                        Tilbage
                                                    </Button>
                                                    <Button className={classes.shape} variant="contained"
                                                            color="primary" onClick={() => onContinueClick()}>
                                                        Videre
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <Button className={classes.shape} variant="contained" color="primary"
                                                        onClick={() => onBackClick()}>
                                                    Tilbage
                                                </Button>
                                            )}
                                        </Box>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Box>
            </Form>
            <TimelineView lenght={survey.questions.length + 1} index={timelineIndex}/>
        </Box>
    );
}

export default SurveyAnswerForm

