import Stream from "./Stream";
import React, {useMemo} from "react";
import {RTCPeer, RTCPeerState} from "../../store/CallState/models";
import makeStyles from "@material-ui/core/styles/makeStyles";
import VideoGrid from "./VideoGrid";

const useStyles = makeStyles(() => ({
    peer: {
        overflow: "hidden",
        width: "100%",
        height: "100vh",
        '& video': {
            '@media (orientation: landscape)': {
                width: "unset",
                height: "100vh",
            },
            '@media (orientation: portrait)': {
                width: "100vw",
                height: "unset",
            },
        }
    },
    peers: {
        height: "100vh",
    },
}))

interface PeerControllerProps {
    peers: RTCPeer[];
    states: RTCPeerState[];
}

interface StateMap {
    [id: string]: RTCPeerState;
}

const PeerController: React.FC<PeerControllerProps> = ({peers, states}) => {
    const classes = useStyles();
    const stateMap: StateMap = useMemo(() => states.reduce((prev: StateMap, next) => {
        prev[next.id] = next;
        return prev;
    }, {} as StateMap), [states]);

    return (
        <div className={peers.length === 1 ? classes.peer : classes.peers}>
            <VideoGrid>
                {peers.map((peer, i) =>
                    <Stream
                        key={peer.id + i}
                        stream={peer.stream as MediaStream}
                        video={stateMap[peer.id]?.videoEnabled}
                        audio={stateMap[peer.id]?.audioEnabled}
                    />
                )}
            </VideoGrid>
        </div>
    )
}

export default PeerController;
