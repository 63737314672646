// The top-level state object
import {RouterState} from 'connected-react-router';
import authReducer, {AuthState, initialAuthState} from './AuthState';
import projectUserReducer, {initialProjectUserState, ProjectUserState} from './ProjectUserState';
import chatReducer, {ChatState, initialChatState} from "./ChatState";
import projectReducer, {initialProjectState, ProjectState} from "./ProjectState";
import journalReducer, {initialJournalState, JournalState} from "./JournalState";
import {initialSurveyState, surveyReducer, SurveyState} from "./SurveyState";
import eventReducer, {EventState, initialEventState} from "./EventState";
import notificationReducer, {initialNotificationState, NotificationState} from "./NotificationState";
import youthReducer, {initialYouthState, YouthState} from "./YouthState";
import {CallState, initialCallState} from "./CallState";
import {callReducer} from "./CallState/reducer";
import overviewReducer, {initialOverviewState, OverviewState} from "./OverviewState";

export interface AppState {
    auth: AuthState;
    users: ProjectUserState;
    project: ProjectState;
    journal: JournalState;
    chat: ChatState;
    survey: SurveyState;
    event: EventState;
    notification: NotificationState;
    router?: RouterState;
    youth: YouthState;
    call: CallState;
    overview: OverviewState;
}

export const initialAppState: AppState = {
    auth: initialAuthState,
    project: initialProjectState,
    users: initialProjectUserState,
    journal: initialJournalState,
    chat: initialChatState,
    survey: initialSurveyState,
    notification: initialNotificationState,
    event: initialEventState,
    youth: initialYouthState,
    call: initialCallState,
    overview: initialOverviewState
};

export const reducers = {
    auth: authReducer,
    project: projectReducer,
    users: projectUserReducer,
    journal: journalReducer,
    chat: chatReducer,
    survey: surveyReducer,
    notification: notificationReducer,
    event: eventReducer,
    youth: youthReducer,
    call: callReducer,
    overview: overviewReducer
}

export interface AppThunkAction<TAction, TState = AppState> {
    (dispatch: (action: TAction) => void, getState: () => TState): void;
}
