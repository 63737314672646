import React, {useState} from "react";
import {AppState} from "../../store";
import useCurrentProject from "../../hooks/useCurrentProject";
import {surveyActions} from "../../store/SurveyState";
import SelectTable from "../../components/tables/SelectTable";
import HeadItem from "../../components/tables/HeadItem";
import {Survey} from "../../models/Survey";
import {Box} from "@material-ui/core";
import {useFormikContext} from "formik";
import OverviewModule from "../../models/OverviewModel";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";

const OverviewModuleSurveyForm = () => {
    const [state] = useReduxCrudPropertyState((state: AppState) => state.survey, surveyActions, useCurrentProject().id);
    const [selected, setSelected] = useState<string[]>([]);

    const {setFieldValue} = useFormikContext<OverviewModule>();

    const otherHeads: HeadItem<Survey>[] = [
        {id: 'name', numeric: false, disablePadding: false, label: 'Navn'},
    ];

    const handleSetSelected = (surveyIds: string[]) => {
        setSelected(surveyIds);
        setFieldValue("fields", surveyIds);
    }

    return (
        <Box mt={1}>
            <SelectTable heads={otherHeads} rows={state.elements} selected={selected} setSelected={handleSetSelected}/>
        </Box>
    )
}

export default OverviewModuleSurveyForm;