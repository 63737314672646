import React from "react";
import Youth, {emptyYouth} from "../models/Youth";
import {Field, Form, Formik} from "formik";
import SelectNamed from "../components/inputs/SelectNamed";
import {KeyboardDatePicker} from "@material-ui/pickers";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import Validators from "../lib/Validators";
import useCurrentProject from "../hooks/useCurrentProject";
import {dawa} from "../services/rest/dawaService";
import Box from "@material-ui/core/Box";
import sexOptions from "../constants/sexOptions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Input, TextField} from "@material-ui/core";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import FormProps from "../models/FormProps";
import {PostalMunicipality} from "../models/PostalData";
import clsx from 'clsx'
import useRequestList from "../hooks/useRequestList";

const useStyles = makeStyles(theme => ({
    welcome: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1.5),
        height: "100%",
        width: "100%",
        display: 'flex',
        alignItems: 'flex-start',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        fontSize: 14,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        marginBottom: theme.spacing(1),
        background: '#FAFAFA'
    },
    form: {
        height: "100%",
    },
    none: {
        display: "none",
    }
}))

const YouthForm: React.FC<FormProps<Youth>> = ({onSubmit, submitButtonRef, initial}) => {
    const classes = useStyles();
    const currentProject = useCurrentProject();
    const municipalities = useRequestList(() => dawa.getMunicipalities());

    return (
        <Formik<Youth>
            onSubmit={(values, {resetForm}) => {
                onSubmit({
                    ...values,
                });
                resetForm();
            }}
            initialValues={{
                ...(initial ?? emptyYouth),
                municipalityOptions: initial ? [{...initial?.municipality} as PostalMunicipality] : [],
                projectId: currentProject.id,
                projectName: currentProject.name,
            }}
        >
            {({values, setFieldValue}) => {
                return (
                    <Form className={classes.form}>
                        <button aria-label="submit" type="submit" className={classes.none} ref={submitButtonRef}/>
                        <Box display="flex" flexDirection="column" width="100%" height="100%">
                            <Field
                                as={TextField}
                                className={classes.input}
                                fullWidth
                                variant='filled'
                                name="name"
                                type="text"
                                placeholder="Kaldenavn"
                                autoFocus
                                required
                            />
                            <Field
                                as={SelectNamed}
                                className={classes.input}
                                options={sexOptions}
                                label="Køn"
                                name="sex"
                            />
                            <KeyboardDatePicker
                                inputVariant='filled'
                                className={classes.input}
                                value={values.birthDate}
                                onChange={(date: Date | MaterialUiPickersDate) => {
                                    setFieldValue('birthDate', date)
                                }}
                                disableFuture
                                keyboardIcon={<CalendarLineIcon/>}
                                label="Fødselsdag"
                                format="dd/MM/yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <Field
                                as={TextField}
                                className={classes.input}
                                fullWidth
                                variant='filled'
                                name="email"
                                placeholder="Email"
                                type="text"
                                required
                                validate={Validators.email}
                                style={{display: Boolean(initial) ? "none" : ""}}
                            />
                            <Field
                                as={TextField}
                                className={classes.input}
                                name="postalCode"
                                type="text"
                                variant='filled'
                                placeholder="Postnr."
                                values={values.postalCode}
                                onChange={async (e: { target: { value: string } }) => {
                                    const value = e.target.value ?? '';
                                    setFieldValue('postalCode', value);
                                    if (value.length !== 4) return;
                                    const response = await dawa.getPostalData(value);
                                    if (response.success && response.value && response.value.length > 0) {
                                        const options = response.value[0].municipalities;
                                        setFieldValue('municipalityOptions', options);
                                    }
                                }}
                            />
                            <Field
                                as={SelectNamed}
                                options={values.municipalityOptions}
                                disabled={values.postalCode?.toString()?.length !== 4}
                                name="municipality"
                                label="Kommune"
                                multiple={false}
                                value={values.municipality?.id}
                                onChange={(e: { target: { value: string } }) => {
                                    const municipalityId = e.target.value;
                                    const municipality = municipalities.find(m => m.id === municipalityId);
                                    setFieldValue('municipality', municipality);
                                }}
                            />
                            {!initial && (
                                <Field
                                    as={Input}
                                    className={clsx(classes.input, classes.welcome)}
                                    placeholder="Velkomstbesked"
                                    multiline
                                    variant='filled'
                                    fullWidth
                                    rows={4}
                                    name="message"
                                    type="text"
                                    required
                                />
                            )}
                        </Box>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default YouthForm;
