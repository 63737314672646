import { ListSubheader, Tooltip, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField/TextField';
import { Autocomplete, AutocompleteProps, createFilterOptions } from '@material-ui/lab';
import React, { Children, cloneElement, createContext, forwardRef, isValidElement, ReactNode, useContext, useEffect, useRef } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const filter = createFilterOptions<AutocompleteInputValue>();

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    return cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING
        }
    });
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if(ref.current != null){
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement>(function ListboxComponent(props, ref){
    const { children, ...other } = props;
    const itemData = Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: ReactNode) => {
        if(isValidElement(child) && child.type === ListSubheader){
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if(itemCount > 8){
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="div"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export type AutocompleteInputValue<T = any> = {
    title: string,
    value: T,
    tooltip?: string
    // @description If this value is user created
    created?: boolean
}

type AutocompleteInputProps<T> = Omit<AutocompleteProps<T, true, any, true>, 'renderInput' | 'onChange'> & {
    name: string;
    label?: string;
    onChange?: (value: any) => void;
    validateOnChange?: (value: any) => boolean;
};

const AutocompleteInput = ({ label, limitTags, name, onChange, validateOnChange = () => true, ...rest }: AutocompleteInputProps<AutocompleteInputValue>) => {

    return (
        <Autocomplete<AutocompleteInputValue, true, any, true>
            multiple
            freeSolo
            selectOnFocus
            clearOnBlur
            disableCloseOnSelect
            handleHomeEndKeys
            getOptionLabel={(option) => {
                if(option.value){
                    return option.value;
                }
                return option.title;
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if(params.inputValue !== ''){
                    filtered.push({
                        created: true,
                        value: params.inputValue,
                        title: `Add "${params.inputValue}"`
                    });
                }

                return filtered;
            }}
            renderTags={(value, getTagProps) => (
                limitTags ? (
                    <>
                        {value.slice(0, limitTags).map((option, index) => (
                            <Tooltip title={option.tooltip ?? ''} key={index}>
                                <span {...getTagProps({ index })}>{option.value + ','}</span>
                            </Tooltip>
                        ))}
                        {value.length > limitTags && (
                            <Tooltip title={`+${value.slice(limitTags).length} more`}>
                                <span>+{value.slice(limitTags).length}</span>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        {value.map((option, index) => (
                            <Tooltip title={option.tooltip ?? ''} key={index}>
                                <span {...getTagProps({ index })}>{option.value + ','}</span>
                            </Tooltip>
                        ))}
                    </>
                )
            )}
            renderOption={(option) => (
                <>
                    <Box flex={1}>{option.title}</Box>
                    {!option.created && (
                        <Checkbox
                            checked={(rest.value?.findIndex((v) =>
                                typeof v === 'string' ? v === option.value : v.value === option.value) ?? -1) > -1}
                            style={{ marginLeft: 8 }}
                        />
                    )}
                </>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="filled" label={label} name={name}/>
            )}
            {...rest}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            onChange={(event, newValue, reason, details) => {
                let values = newValue.map((v) => {
                    if(typeof v === 'string'){
                        if(!validateOnChange(v)){
                            return undefined;
                        }

                        return {
                            title: v,
                            value: v
                        };
                    }

                    if(v.created){
                        console.log('created', v.value);
                        if(!validateOnChange(v.value)){
                            return undefined;
                        }
                    }

                    return v;
                }).filter((v): v is AutocompleteInputValue => v !== undefined);

                const detailsValue = typeof details?.option === 'string' ? details.option : details?.option?.value;

                if(detailsValue){
                    const index = rest.value?.findIndex((v) =>
                        typeof v === 'string' ? v === detailsValue : v.value === detailsValue) ?? -1;
                    if(index > -1){
                        if(reason !== 'create-option' && !details?.option?.created){
                            values = values.filter((v) => v.value !== detailsValue);
                        }else{
                            values.splice(index, 1);
                        }
                    }
                }

                onChange?.({
                    persist: () => {},
                    target: {
                        name,
                        value: values,
                        type: 'change'
                    }
                });
            }}
        />
    );
};

export default AutocompleteInput;