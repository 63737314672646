import React, {useState} from "react";
import Center from "../../components/containers/Center";
import {makeStyles} from "@material-ui/core/styles";
import JournalEntryListView from "./JournalEntryListView";
import {journalActions} from "../../store/JournalState";
import {useDispatch} from "react-redux";
import Journal, {emptyJournalEntry, JournalEntry} from "../../models/Journal";
import JournalViewHeader from "./JournalViewHeader";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import FormDrawer from "../../components/drawers/FormDrawer";
import JournalEntryForm from "../../forms/JournalEntryForm";
import JournalGeneral from "./JournalGeneral";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100vh',
            marginLeft: '0',
        },
    },
}));

const JournalView: React.FC<{journal?: Journal}> = ({journal}) => {
    const classes = useStyles();
    const dispatch = useDispatch();


    const [openDrawer, setOpenDrawer] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedEntry, setSelectedEntry] = useState<JournalEntry|undefined>(undefined);
    const [activeIndex, setActiveIndex] = useState(1);

    const [submitEntryButtonRef, handleSaveEntryClick] = useSubmitButtonRef();

    const handleEntrySubmit = (entry: JournalEntry) => {
        dispatch(journalActions.updateEntry(journal, {
            ...entry,
        }));
        setOpenDrawer(false);
    }

    const sortEntries = (b: boolean) => {
        if (b) {
            journal?.entries.sort((a, b) => a.occurrenceDate <= b.occurrenceDate ? 1 : -1);
        } else {
            journal?.entries.sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1);

        }
        dispatch(journalActions.updateEntryOrder(journal));
    }


    if (!journal) {
        return (
            <div className={classes.root}>
                <Center>
                    Vi kunne ikke finde den valgte journal
                </Center>
            </div>
        );
    }


    if (activeIndex === 0) {
        return (
            <div className={classes.root}>
                <JournalViewHeader
                    journal={journal}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                />
                <JournalGeneral
                    journal={journal}
                />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <JournalViewHeader
                journal={journal}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            />
            <JournalEntryListView
                journal={journal}
                openDrawer={setOpenDrawer}
                setSelectedEntry={setSelectedEntry}
                search={search}
                onSearch={setSearch}
                sortEntries={sortEntries}
            />
            <FormDrawer
                title={selectedEntry ? "Redigere notat" : "Opret notat"}
                open={openDrawer}
                onCancel={() => {
                    setSelectedEntry(undefined);
                    setOpenDrawer(false);
                }}
                onSave={handleSaveEntryClick}
            >
                <JournalEntryForm
                    submitButtonRef={submitEntryButtonRef}
                    onSubmit={handleEntrySubmit}
                    initial={selectedEntry ? selectedEntry : emptyJournalEntry}
                />
            </FormDrawer>
        </div>
    );
}


export default JournalView;


