import React, {useState} from "react";
import BasePage from "../../components/containers/BasePage";
import {Box, Grid} from "@material-ui/core";
import OverviewTile from "./OverviewTile";
import DragDropButton from "../../components/material/DragDropButton";
import LineChartLineIcon from "remixicon-react/LineChartLineIcon";
import FormDrawer from "../../components/drawers/FormDrawer";
import OverviewModuleForm from "./OverviewModuleForm";
import CircleContainer from "../../components/containers/CircleContainer";
import OverviewModule from "../../models/OverviewModel";
import {AppState} from "../../store";
import useCurrentProject from "../../hooks/useCurrentProject";
import {overviewActions} from "../../store/OverviewState";
import {useDispatch} from "react-redux";
import ContentContainer from "../../components/containers/ContentContainer";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";

const OverviewPage = () => {
    const dispatch = useDispatch();
    const currentProject = useCurrentProject();
    const [open, setOpen] = useState<boolean>(false);
    const [initial, setInitial] = useState<OverviewModule>()
    const [state] = useReduxCrudPropertyState((state: AppState) => state.overview, overviewActions, useCurrentProject().id);
    const elements = state.elements;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setInitial(undefined);
        setOpen(false)
    };
    const [submitButtonRef, handleSubmitClick] = useSubmitButtonRef();

    const handleSubmit = async (overviewModule: OverviewModule) => {
        overviewModule.index = state.elements.length;
        await dispatch(overviewActions.updateElement(currentProject?.id, overviewModule));
        handleClose();
    }

    const handleEdit = (overviewModule: OverviewModule) => {
        setInitial(overviewModule);
        setOpen(true);
    }

    // TODO: create better first plot options
    const CreateButton = () => {
        return (
            <DragDropButton
                fullWidth
                style={{height: '100%', width: '100%'}}
                onClick={handleOpen}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                >
                    <CircleContainer>
                        <LineChartLineIcon size={25}/>
                    </CircleContainer>
                    <p style={{marginBottom: 0}}>
                        {elements.length !== 0 ? "Udvid overblik" : "Opret din første graf"}
                    </p>
                </Box>
            </DragDropButton>
        )
    }

    return (
        <BasePage>
            <Box pt={2}/>

            <ContentContainer state={state} empty={CreateButton}>
                <Grid spacing={1} container>
                    {elements.map((module: OverviewModule) =>
                        <Grid key={module.id} item md={6}>
                            <OverviewTile onEditClick={handleEdit} module={module}/>
                        </Grid>
                    )}
                    <Grid item md={6}>
                        <CreateButton/>
                    </Grid>
                </Grid>
            </ContentContainer>

            <FormDrawer
                title={initial ? "Rediger modul" : "Tilføj modul"}
                open={open}
                onCancel={handleClose}
                onSave={handleSubmitClick}
            >
                <OverviewModuleForm initial={initial} submitButtonRef={submitButtonRef} onSubmit={handleSubmit}/>
            </FormDrawer>
        </BasePage>
    )
}

export default OverviewPage;