import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CustomTheme} from "../../constants/theme";
import {Box, Divider} from "@material-ui/core";
import {NavItem} from "../../constants/NavigationList";
import NotificationBell from "./NotificationBell";
import ProfileButton from "./ProfileButton";
import {useLocation} from "react-router-dom";
import AppBarAction from "./AppBarAction";
import IconButton from "@material-ui/core/IconButton";
import MenuLineIcon from "remixicon-react/MenuLineIcon";

const useStyles = makeStyles<CustomTheme>((theme) => ({
    root: {
        position: 'relative',
        width: '100%'
    },
    appBar: {
        backgroundColor: '#F1F5F9',
        zIndex: 100,
        position: 'fixed',
        width: "calc(100% - " + theme.custom.drawerWidth + "px)",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.custom.boxShadow
        },
    },
    actionRow: {
        height: theme.custom.appBarHeight,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    startAdornment: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        '& svg': {
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.primary.contrastText
            },
        },
        '& h2': {
            padding: theme.spacing(0, 2),
            [theme.breakpoints.down('xs')]: {
                padding: 0,
            },
        },
    },
    activeIcon: {
        [theme.breakpoints.down('xs')]: {
            display: "none",
        },
    },
    bell: {
        background: 'white',
        boxShadow: theme.custom.boxShadow
    },
    spacer: {
        height: theme.custom.appBarHeight
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}))

interface DashboardAppBarProps {
    activeNavItem: NavItem;
    onMobileToggle: () => void;
}

export const DashboardAppBar: React.FC<DashboardAppBarProps> = ({activeNavItem, onMobileToggle}) => {
    const classes = useStyles();
    const {pathname} = useLocation();

    return (
        <div className={classes.root}>
            <div className={classes.appBar}>
                <Box className={classes.actionRow}>
                    <Box className={classes.startAdornment}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={onMobileToggle}
                            className={classes.menuButton}
                        >
                            <MenuLineIcon />
                        </IconButton>
                        <activeNavItem.icon size={30} className={classes.activeIcon}/>
                        <h2>{activeNavItem.name}</h2>
                        {activeNavItem.startActions && activeNavItem.startActions.map(sub => (
                            <AppBarAction key={sub.path} action={sub} pathname={pathname}/>
                        ))}
                    </Box>
                    {/*TODO */}
                    <Box display='flex' alignItems='center'>
                        {activeNavItem.endActions && activeNavItem.endActions.map(sub => (
                            <AppBarAction action={sub} pathname={pathname}/>
                        ))}
                        <NotificationBell/>
                        <ProfileButton/>
                    </Box>

                </Box>
                {activeNavItem.scroll && <Divider/>}
            </div>
            <div className={classes.spacer}/>
        </div>
    )
}

export default DashboardAppBar;