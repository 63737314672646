import React, {useState} from "react";
import MenuTable from "../../components/tables/MenuTable";
import Menu from "@material-ui/core/Menu";
import DeleteMenuItem from "../../components/menu/DeleteMenuItem";
import {useDispatch} from "react-redux";
import useCurrentProject from "../../hooks/useCurrentProject";
import {ListSurvey, Survey, SurveyAnswers} from "../../models/Survey";
import {surveyActions} from "../../store/SurveyState";
import {ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import EditBoxLineIcon from "remixicon-react/EditBoxLineIcon";
import LinksLineIcon from "remixicon-react/LinksLineIcon";
import HeadItem from "../../components/tables/HeadItem";
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import PieChartLineIcon from "remixicon-react/PieChartLineIcon";
import FormDrawer from "../../components/drawers/FormDrawer";
import services from "../../services/rest/services";
import Box from "@material-ui/core/Box";
import ChartComponent from "@reactchartjs/react-chart.js";
import DownloadLineIcon from "remixicon-react/DownloadLineIcon";
import {downloadAsCSVFile} from "../../lib/downloadFile";
import getChartColors from "../../lib/getChartColors";
import snackbar from "../../services/snackbar";

const heads: HeadItem<ListSurvey>[] = [
    {id: "name", numeric: false, disablePadding: false, label: "Titel"},
    {id: "description", numeric: false, disablePadding: false, label: "Beskrivelse"},
    {id: "createdAt", numeric: false, disablePadding: false, label: "Oprettelsesdato"},
    {id: "updatedAt", numeric: false, disablePadding: false, label: "Ændringsdato"},
]

interface SurveyTableProps {
    surveys: Survey[];
    handleEditClick: (survey: string) => void;
}

const SurveyTable: React.FC<SurveyTableProps> = (props) => {
    const dispatch = useDispatch();
    const [answers, setAnswers] = useState<SurveyAnswers>();
    const currentProjectId = useCurrentProject().id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [surveyId, setSurveyId] = useState("");

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorEl(e.currentTarget);
        setSurveyId(id);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSurveyId("");
    }
    const handleDelete = () => {
        const survey = props.surveys.find(s => s.id === surveyId);
        dispatch(surveyActions.deleteElement(currentProjectId, survey))
        handleMenuClose();
    }

    const handleCopyLink = () => {
        const host = window.location.origin; // http://localhost:3000 || https://app.dikom.dk
        const link = "/project/" + currentProjectId + "/surveys/" + surveyId;
        navigator.clipboard.writeText(host + link);
        snackbar.success('Linket er kopieret!')
        handleMenuClose();
    }
    const handleEditClick = () => {
        props.handleEditClick(surveyId);
        handleMenuClose();
    }
    const handleViewAnswersClick = async () => {
        if (surveyId) {
            const response = await services.surveys.getAnswers(currentProjectId, surveyId);
            if (response.success) {
                setAnswers(response.value);
            }
        }
    }

    const handleCSVClick = async () => {
        if (surveyId) {
            const response = await services.surveys.getAnswersCSV(currentProjectId, surveyId);
            if (response.success) {
                const survey = props.surveys.find(s => s.id === surveyId);
                const fileName = survey?.name ? survey?.name + ".csv" : response.value.fileDownloadName;
                downloadAsCSVFile(response.value.fileName, fileName);
                handleMenuClose();
            }
        }
    }

    const handleClose = () => setAnswers(undefined);

    const chartData = (answerCount: { [p: string]: number }) => {
        const values = Object.values(answerCount);
        return {
            labels: Object.keys(answerCount),
            datasets: [{
                backgroundColor: getChartColors(values.length),
                data: values,
            }]
        }
    };

    const formattedSurveys: ListSurvey[] = props.surveys.map(s => ({
        ...s,
        createdAt: toLocalDateTimeString(s.createdAt),
        updatedAt: toLocalDateTimeString(s.updatedAt),
    }));

    return (
        <React.Fragment>
            <MenuTable
                heads={heads}
                rows={formattedSurveys}
                onMenuClick={handleMenuClick}
            />
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleViewAnswersClick}>
                    <ListItemIcon>
                        <PieChartLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">Se svarfordeling</Typography>
                </MenuItem>
                <MenuItem onClick={handleCopyLink}>
                    <ListItemIcon>
                        <LinksLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">{"Kopier spørgeskema link"}</Typography>
                </MenuItem>
                <MenuItem onClick={handleCSVClick}>
                    <ListItemIcon>
                        <DownloadLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">{"Download CSV-fil"}</Typography>
                </MenuItem>
                <MenuItem onClick={handleEditClick}>
                    <ListItemIcon>
                        <EditBoxLineIcon size={20}/>
                    </ListItemIcon>
                    <Typography variant="inherit">{"Rediger spørgeskema"}</Typography>
                </MenuItem>
                <DeleteMenuItem
                    title="Slet spørgeskema"
                    message="Er du sikker på, at du vil slette dette spørgeskema?"
                    onConfirm={handleDelete}
                />
            </Menu>
            <FormDrawer
                title={props.surveys.find(s => s.id === surveyId)?.name ?? ""}
                open={Boolean(answers)}
                onCancel={handleClose}
            >
                {answers?.questions.map(q => (
                    <Box>
                        <Typography variant="h5">{q.title}</Typography>
                        <ChartComponent type="pie" data={chartData(q.answerCount)}/>
                    </Box>
                ))}
            </FormDrawer>
        </React.Fragment>
    )
}

export default SurveyTable;
