import useTypedSelector from "./useTypedSelector";
import Project, {emptyProject} from "../models/Project";


export const useCurrentProject = (): Project => {
    return useTypedSelector((state) => state.project.selectedElement) ?? emptyProject;
}

export default useCurrentProject;

