import React from 'react';
import DashboardSwitch from './DashboardSwitch';
import {useNavigationList, utilityNavList} from "../../constants/NavigationList";
import DashboardDrawer from "./DashboardDrawer";
import Center from "../../components/containers/Center";
import BoxButton from "../../components/buttons/BoxButton";
import {useDispatch} from "react-redux";
import {authActions} from "../../store/AuthState";
import DashboardAppBar from "./DashboardAppBar";
import useTypedSelector from "../../hooks/useTypedSelector";
import {useLocation} from "react-router-dom";
import {matchPath} from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import {useMediaQuery} from "@material-ui/core";
import useDashboardState from "./useBaseEffects";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
    content: {
        minHeight: '100vh',
        maxWidth: '100%',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#F1F5F9',
    },
    switch: {
        flex: 1,
        position: 'relative',
        display: 'flex'
    },
    list: {
        display: 'flex',
        background: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        boxShadow: '0 -2px 5px rgba(0,0,0,.09)',
        justifyContent: 'space-evenly'
    },
    signOut: {display: 'flex', flexDirection: 'column'},
}))

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useDashboardState();


    const [currentProject] = useTypedSelector(state => [state.project.selectedElement]);
    const location = useLocation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const navList = useNavigationList();
    const fallbackActive = navList[0];
    const activeNavigationItem = navList.concat(utilityNavList).find(
        (navItem) => !!matchPath(location.pathname, navItem.path)) ?? fallbackActive;

    const handleSignOut = () => dispatch(authActions.signOut());
    const handleDrawerToggle = () => {
        if (mobile) setMobileOpen(!mobileOpen);
    }


    if (currentProject?.id === undefined) {
        return (
            <Center>
                <div className={classes.signOut}>
                    Du er ikke tilknyttet et tilbud
                    <BoxButton onClick={handleSignOut}>
                        Logud
                    </BoxButton>
                </div>
            </Center>
        )
    }

    return (
        <div className={classes.root}>
            <DashboardDrawer mobileOpen={mobileOpen} onMobileToggle={handleDrawerToggle}/>
            <div className={classes.content}>
                <DashboardAppBar activeNavItem={activeNavigationItem} onMobileToggle={handleDrawerToggle}/>
                <div className={classes.switch}>
                    <DashboardSwitch/>
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;


