import {Participant} from "./Participant";
import {EventApi} from "@fullcalendar/react";
import searchFilter from "../lib/searchFilter";


export const eventStatusOptions = [{
    id: '',
    name: 'Ingen',
    tooltip: '',
}, {
    id: 'cancelled',
    name: 'Aflyst',
    tooltip: 'Begivenheden er blevet aflyst',
}, {
    id: 'moved',
    name: 'Flyttet',
    tooltip: 'Begivenheden er blevet flyttet',
}, {
    id: 'no-show',
    name: 'Udeblivelse',
    tooltip: 'Den unge udeblev fra begivenheden',
}]

export interface CalendarEvent {
    id: string;
    batchId?: string;
    title: string;
    description?: string;
    streamUrl?: string;
    location?: string;
    createdBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    start: Date;
    end: Date;
    allDay: boolean;
    isCancelled?: boolean;
    isPublic?: boolean;
    participants?: Participant[];
    maxParticipants?: number;

    // participants?: Participant[];
    // participantIds?: string[];
    // maxParticipants?: number;
    // isCancelled: boolean;
    occurrences?: number;
    groupId?: string;

    // visual attributes
    backgroundColor?: string;
    borderColor?: string;
}

export interface CalendarEventFormValues {
    title: string;
    description: string;
    streamUrl: string;
    location: string;
    startDate: string;
    start: Date;
    endDate: string;
    end: Date;
    allDay: boolean;
    status?: boolean;
    isPublic: boolean;

    // participant
    participantInput: string;
    participants: Participant[];
    maxParticipants?: number;

    occurrences?: number;
    groupId?: string;
    // typeId: string;
}

const dateFromCurrentHour = () => {
    const date = new Date();
    date.setMinutes(0, 0, 0);
    return date;
}
const dateFromNextHour = () => {
    const date = dateFromCurrentHour();
    date.setHours(date.getHours() + 1);
    return date;
}


export const emptyCalendarEvent: CalendarEvent = {
    id: '',
    title: '',
    description: '',
    location: '',
    isCancelled: false,
    start: dateFromCurrentHour(),
    end: dateFromNextHour(),
    allDay: false,
};

export function eventFromDate(date: Date | string, allDay: boolean) {
    const start = new Date(date);
    const m = start.getMinutes()
    const halfHourDiff = 30 - (m % 30);
    const minutes = m + halfHourDiff;
    start.setMinutes(minutes, 0, 0);

    const end = new Date(start);
    end.setHours(end.getHours() + 1);
    return {
        ...emptyCalendarEvent,
        id: '',
        start: start,
        end: end,
        allDay: allDay,
    };
}


export function eventFromEventAPI(event: EventApi): CalendarEvent {
    // console.log("eventFromEventAPI.event:", event)

    const start = event.start ?? new Date();
    const oneHourFromStart = new Date(start);
    oneHourFromStart.setHours(oneHourFromStart.getHours() + 1);


    return {
        id: event.id,
        groupId: event.groupId,
        title: event.title,
        start: start,
        end: event.end ?? oneHourFromStart,
        allDay: event.allDay,
        backgroundColor: event.backgroundColor,
        borderColor: event.borderColor,
        ...event.extendedProps
    }
}

export const eventSearchFilter = (search: string) => ({title, location, start, end}: CalendarEvent) => searchFilter({
    title: title ?? '',
    location: location ?? '',
    start: '' + start ?? '',
    end: '' + end ?? '',
}, search);


export default CalendarEvent;
