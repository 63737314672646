import React, {useState} from "react";
import useCurrentProject from "../../hooks/useCurrentProject";
import Grid from "@material-ui/core/Grid";
import ProjectChannelForm from "../../forms/ProjectChannelForm";
import {Box} from "@material-ui/core";
import Edit2LineIcon from "remixicon-react/Edit2LineIcon";
import LargeTooltip from "../../components/material/LargeTooltip";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ProjectChannel, {emptyProjectChannel, projectChannelIds} from "../../models/ProjectChannel";
import DeleteBin7LineIcon from "remixicon-react/DeleteBin7LineIcon";
import DragDropButton from "../../components/material/DragDropButton";
import CrudDrawer from "../../components/drawers/CrudDrawer";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {useDispatch} from "react-redux";
import {projectActions} from "../../store/ProjectState";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";

const ProjectChannelPage: React.FC = () => {
    const dispatch = useDispatch();
    const {id: projectId, channels} = useCurrentProject();
    const projectChannels = channels === null || channels === undefined ? [] : channels;
    const [submitChannelButtonRef, handleChannelSaveClick] = useSubmitButtonRef();

    const [openDelete, setOpenDelete] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<ProjectChannel | undefined>(undefined);


    const handleCreate = () => setSelectedChannel(emptyProjectChannel);
    const handleEdit = (channel: ProjectChannel) => setSelectedChannel(channel)
    const handleClose = () => {
        setSelectedChannel(undefined);
        setOpenDelete(false);
    }

    const handleDeleteChannel = async (channel: ProjectChannel) => {
        const channels = projectChannels.filter(pc => pc.id !== channel.id);
        dispatch(projectActions.updateChannels(projectId, channels));
    }
    const handleSubmit = async (channel: ProjectChannel) => {
        if (channel.id === selectedChannel?.id) {
            const updatedChannels = projectChannels.map(pc => pc.id === channel.id ? channel : pc);
            dispatch(projectActions.updateChannels(projectId, updatedChannels));
        } else {
            dispatch(projectActions.updateChannels(projectId, projectChannels.concat(channel) ?? [channel]));
        }
        setSelectedChannel(undefined);
    }


    const handleConfirmDelete = (channelId: string) => {
        dispatch(projectActions.updateChannels(projectId, projectChannels.filter((channel) => channel.id !== channelId)));
        setOpenDelete(false);
    }


    return (
        <Grid>
            {projectChannels.map((channel) => (
                <Grid item xs={12} key={channel.id}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        {channel.name}
                        <Box>
                            <LargeTooltip title="Rediger kanal">
                                <IconButton onClick={() => handleEdit(channel)}>
                                    <Edit2LineIcon fontSize={"small"}/>
                                </IconButton>
                            </LargeTooltip>
                            <LargeTooltip title={'Slet kanal'}>
                                <IconButton onClick={() => setOpenDelete(true)}>

                                    <DeleteBin7LineIcon fontSize={"small"}/>
                                </IconButton>
                            </LargeTooltip>
                            <ConfirmDialog
                                title={"Slet kanal"}
                                open={openDelete}
                                onClose={handleClose}
                                onConfirm={() => handleConfirmDelete(channel.id)}
                            >
                                Er du sikker på, at du vil slette dette komunikations kanal?
                            </ConfirmDialog>
                        </Box>
                    </Box>
                    <Divider/>
                </Grid>
            ))}
            <Grid item xs={12}>
                <DragDropButton onClick={handleCreate} fullWidth>
                    Tilføj kommunikationskanal
                </DragDropButton>
            </Grid>
            <CrudDrawer
                elementName="Kanal"
                open={Boolean(selectedChannel)}
                onCancel={handleClose}
                onSave={handleChannelSaveClick}
                element={selectedChannel}
                onDelete={handleDeleteChannel}
            >
                <ProjectChannelForm
                    submitButtonRef={submitChannelButtonRef}
                    onSubmit={handleSubmit}
                    initial={selectedChannel}
                    options={projectChannelIds.filter(c => !projectChannels.find(e => e.id === c.id))}
                />
            </CrudDrawer>
        </Grid>
    );
}

export default ProjectChannelPage;