import React, {useState} from "react";
import {Box, InputBaseProps} from "@material-ui/core";
import BasePage from "../../components/containers/BasePage";
import Youth, {YouthGroup, youthSearchFilter} from "../../models/Youth";
import CrudDrawer from "../../components/drawers/CrudDrawer";
import {AppState} from "../../store";
import {projectYouthActions} from "../../store/YouthState";
import useCurrentProject from "../../hooks/useCurrentProject";
import BasePageToolbar from "../../components/containers/BasePageToolbar";
import {useDispatch} from "react-redux";
import ContentContainer from "../../components/containers/ContentContainer";
import LabeledInput from "../../components/inputs/LabeledInput";
import Input from "@material-ui/core/Input";
import SearchInput from "../../components/inputs/SearchInput";
import HeadItem from "../../components/tables/HeadItem";
import SelectTable from "../../components/tables/SelectTable";
import YouthGroupView from "./YouthGroupView";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";

const drawerHead: HeadItem<Youth>[] = [{id: 'name', numeric: false, disablePadding: false, label: 'Navn'}];

export const YouthGroupPage = () => {
    const dispatch = useDispatch();
    const currentProjectId = useCurrentProject().id;
    const [selected, setSelected] = useState<string[]>([]);
    const [otherSearch, setOtherSearch] = useState('');
    const [groupName, setGroupName] = useState("");
    const [search, setSearch] = useState('');
    const [openGroup, setOpenGroup] = useState(false);
    const [youthState] = useReduxCrudPropertyState((state: AppState) => state.youth, projectYouthActions, currentProjectId);

    const handleGroupName: InputBaseProps["onChange"] = e => setGroupName(e.target.value);
    const handleSubmit = async () => {
        const group: YouthGroup = {
            id: groupName.toLowerCase().replace(" ", "_"),
            name: groupName,
        }
        const selectedYouths = selected.map(id => {
            const y = youthState.elements.find(y => y.id === id)
            y?.groups.push(group);
            return y;
        }).filter(y => y) as Youth[];
        await dispatch(projectYouthActions.updateAll(currentProjectId, selectedYouths));
        setSearch("");
        setSelected([]);
        setGroupName("");
        setOpenGroup(false);
    }

    const filteredYouth = youthState.elements.filter(youthSearchFilter(otherSearch));

    const handleGroupClose = () => setOpenGroup(false);
    const handleCreateClick = () => setOpenGroup(true);

    return (
        <BasePage>
            <BasePageToolbar
                search={search}
                onSearch={setSearch}
                permitted={true}
                onCreateClick={handleCreateClick}
            />
            <ContentContainer state={youthState}>
                <YouthGroupView
                    youths={filteredYouth}
                    search={search}
                />
            </ContentContainer>
            <CrudDrawer
                elementName="Gruppe"
                onCancel={handleGroupClose}
                onSave={handleSubmit}
                open={openGroup}
                element={undefined}
            >
                <Box display="flex" flexDirection="column">
                    <LabeledInput label="Indtast gruppenavn" id="group">
                        <Input placeholder="Gruppenavn" id="group" value={groupName} onChange={handleGroupName}/>
                    </LabeledInput>
                    <SearchInput search={otherSearch} onChange={setOtherSearch} placeholder="Find unge..." box/>
                </Box>
                <SelectTable heads={drawerHead} rows={filteredYouth} selected={selected} setSelected={setSelected}/>
            </CrudDrawer>
        </BasePage>
    )
}

export default YouthGroupPage;