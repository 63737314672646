import {Redirect, Route, RouteProps} from 'react-router';
import React from 'react';
import Routes from '../../constants/Routes';
import {useSelector} from 'react-redux';
import {AppState} from '../../store';


const AuthenticatedRoute: React.FC<RouteProps & { component: React.FC }> = ({component: Component, ...rest}) => {
    const auth = useSelector((state: AppState) => state.auth);
    if (!auth.authenticated) console.log("Redirect to landing");
    return <Route {...rest}>{auth.authenticated ? <Component/> : <Redirect to={Routes.landing}/>}</Route>;
};

export default AuthenticatedRoute;
