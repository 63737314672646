import {
    initialSocketFeedback,
    MediaFeedback,
    MediaFeedbackCode,
    mediaFeedbackMap,
    SocketFeedback,
    StreamType
} from "../store/CallState/models";
import {useDispatch} from "react-redux";
import {useCallback, useState} from "react";
import {handleUpdateStream, isWebRTCCompatible} from "../store/CallState/lib";
import {initialStreamConstraints} from "../store/CallState/config";
import {callActions} from "../store/CallState/actions";

export interface CallStreamConfig {
    video: boolean;
    audio: boolean;
}

const useStartCall = (id: string, config?: CallStreamConfig): [() => Promise<void>, MediaFeedback, SocketFeedback] => {
    const dispatch = useDispatch();

    const [mediaFeedback, setMediaFeedback] = useState<MediaFeedback>({
        ...mediaFeedbackMap[MediaFeedbackCode.initial],
    });
    const [socketFeedback, setSocketFeedback] = useState<SocketFeedback>({ ...initialSocketFeedback });

    const {audio, video} = config ?? {};
    const handleStartCall = useCallback(async () => {
        const compatible = isWebRTCCompatible();
        if (!compatible) {
            console.log("Browser not compatible");
            setMediaFeedback(mediaFeedbackMap[MediaFeedbackCode.notWebRTCCompatible]);
            return;
        }

        const updatedStream = await handleUpdateStream(new MediaStream(), initialStreamConstraints);
        if (!updatedStream.success) {
            console.log("Couldn't update stream");
            setMediaFeedback(mediaFeedbackMap[MediaFeedbackCode.permissions]);
            return;
        }
        await dispatch(callActions.setVideoStream(updatedStream.stream));

        if (audio !== undefined && video !== undefined) {
            if (!audio) dispatch(callActions.updateStream(StreamType.audio));
            if (!video) dispatch(callActions.updateStream(StreamType.video));
        }

        await setMediaFeedback({ loading: false, success: true });
        await dispatch(callActions.connect(id));
        await setSocketFeedback({ loading: false, success: true });
    }, [audio, dispatch, id, video]);

    return [handleStartCall, mediaFeedback, socketFeedback];
}

export default useStartCall;
