import RestResponse from "../../models/RestResponse";
import {RequestMethod, RestClient} from "./restClient";
import ProjectUser from "../../models/ProjectUser";


export interface AdminServiceType {
    addMe: (projectId:string) => RestResponse<Required<ProjectUser>>;
}

export const adminService = (client: RestClient): AdminServiceType => {
    const basePath = '/api/web/v1/admin';
    return {
        addMe: async (projectId) => {
            return client.fetchJSON(RequestMethod.POST, basePath + '/addme/' + projectId);
        },
    };
};

export default adminService;


