import services from "../services/rest/services";
import CalendarEvent from "../models/CalendarEvent";
import {crudActionTypes, crudReducer, CrudState, initialCrudState, KnownCrudAction} from "./CrudState";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";
import NamedAction from "../models/NamedAction";
import snackbar from "../services/snackbar";
import {AppThunkAction} from "./index";

const stateName = 'EVENT_STATE';
export const eventStateName = stateName;

export type EventState = CrudState<Required<CalendarEvent>>;

export const initialEventState = initialCrudState<Required<CalendarEvent>>();

export type CreateAllEventActionType = "REQUEST_CREATE_ALL_EVENTS";
export const CreateAllEventActionType = "REQUEST_CREATE_ALL_EVENTS";

export interface CreateAllEventAction {
    name: string;
    type: CreateAllEventActionType;
    elements: Required<CalendarEvent>[];
}

type KnownEventActions = KnownCrudAction<CalendarEvent> | CreateAllEventAction;

export type EventActions = CrudRestPropertyActionType<CalendarEvent> & {
    cancelEvent: (projectId: string, eventId: string) => AppThunkAction<KnownCrudAction<CalendarEvent>>;
};

export const eventActions: EventActions = {
    ...crudRestPropertyActions<CalendarEvent>(stateName, services.events),
    cancelEvent: (projectId, eventId) => {
        return async (dispatch) => {
            if (!projectId || !eventId) return;

            console.log("Hello");
            dispatch({name: stateName, type: crudActionTypes.LOADING});

            const response = await services.events.cancelEvent(projectId, eventId);

            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.UPDATE, element: response.value});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback});
                snackbar.showFeedback(response.feedback);
            }
        }
    },
    updateElement: (parentId, element) => {
        return async (dispatch) => {
            if (element === undefined) return;

            dispatch({name: stateName, type: crudActionTypes.LOADING});


            if (element && Boolean(element.occurrences)) {
                const groupResponse = await services.events.createGroup(parentId, element);
                if (!groupResponse.success) {
                    dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: groupResponse.feedback});
                    snackbar.showFeedback(groupResponse.feedback)
                    return;
                }
                dispatch({name: stateName, type: CreateAllEventActionType as any, elements: groupResponse.value});
                return;
            }


            const response = element?.id ? await services.events.update(parentId, element) : await services.events.create(parentId, element);
            if (response.success) {
                dispatch({name: stateName, type: crudActionTypes.UPDATE, element: response.value});
                dispatch({name: stateName, type: crudActionTypes.SELECT, element: undefined});
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback});
                snackbar.showFeedback(response.feedback);
            }
        };
    }
};

const createAllEventsReducer: (state: EventState, incomingAction: NamedAction) => EventState = (state, incomingAction) => {
    const action = incomingAction as CreateAllEventAction;
    switch (action.type) {
        case CreateAllEventActionType: {
            return {
                ...state,
                elements: state.elements.concat(action.elements),
                loading: false,
                error: undefined,
                feedback: undefined,
            };
        }
        default: {
            return state;
        }
    }
};

export const eventReducer = crudReducer<Required<CalendarEvent>>(stateName, initialEventState, createAllEventsReducer);

export default eventReducer;
