import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    root: {
        display: "grid",
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    size1: {
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr"
    },
    size2: {
        gridTemplateRows: "1fr",
        gridTemplateColumns: "repeat(2, 1fr)",
        '@media (orientation: portrait)': {
            gridTemplateRows: "repeat(2, 50%)",
            gridTemplateColumns: "1fr",
        }
    },
    size3: {
        gridTemplateRows: "1fr",
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    size4: {
        gridTemplateRows: "50% 50%",
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    size5: {
        gridTemplateRows: "40% 60%",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridTemplateAreas: `
            'a a b b c c'
            'd d d e e e'`,
        '@media (orientation: portrait)': {
            gridTemplateRows: "repeat(3, calc(100% / 3))",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateAreas: `
            'a a'
            'b c'
            'd e'`,
        },
        '& > :nth-child(1)': {gridArea: "a"},
        '& > :nth-child(2)': {gridArea: "b"},
        '& > :nth-child(3)': {gridArea: "c"},
        '& > :nth-child(4)': {gridArea: "d"},
        '& > :nth-child(5)': {gridArea: "e"},
    },
    size6: {
        gridTemplateRows: "repeat(2, 50%)",
        gridTemplateColumns: "repeat(3, 1fr)",
        '@media (orientation: portrait)': {
            gridTemplateRows: "repeat(3, calc(100% / 3))",
            gridTemplateColumns: "repeat(2, 1fr)",
        }
    },
    size7: {
        gridTemplateColumns: "repeat(12, 1fr)",
        gridTemplateRows: "repeat(2, 50%)",
        gridTemplateAreas: `
            'a a a b b b c c c d d d'
            'e e e e f f f f g g g g'`,
        '@media (orientation: portrait)': {
            gridTemplateColumns: "repeat(6, 1fr)",
            gridTemplateRows: "repeat(3, calc(100% / 3))",
            gridTemplateAreas: `
                'a a b b c c'
                'd d d e e e'
                'f f f g g g'`,
        },
        '& > :nth-child(1)': {gridArea: "a"},
        '& > :nth-child(2)': {gridArea: "b"},
        '& > :nth-child(3)': {gridArea: "c"},
        '& > :nth-child(4)': {gridArea: "d"},
        '& > :nth-child(5)': {gridArea: "e"},
        '& > :nth-child(6)': {gridArea: "f"},
        '& > :nth-child(7)': {gridArea: "g"},
    },
    size8: {
        gridTemplateRows: "repeat(2, 50%)",
        gridTemplateColumns: "repeat(4, 1fr)",
        '@media (orientation: portrait)': {
            gridTemplateRows: "repeat(4, 25%)",
            gridTemplateColumns: "repeat(2, 1fr)",
        }
    },
    size9: {
        gridTemplateRows: "repeat(3, calc(100% / 3)",
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    auto: {
        gridTemplateRows: "auto",
        gridTemplateColumns: "auto",
    }
}))

const VideoGrid: React.FC = ({children}) => {
    const classes = useStyles();
    const size = React.Children.count(children);
    const sizeClass = 0 < size && size < 10 ? classes['size'+size as keyof typeof classes] : classes.auto;
    return (
        <div className={clsx(classes.root, sizeClass)}>{children}</div>
    )
}

export default VideoGrid;
