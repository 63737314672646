import React, {useState} from 'react';
import FullCalendar from "@fullcalendar/react";
import {eventActions} from "../../store/EventState";
import CalendarView from "./CalendarView";
import BasePage from "../../components/containers/BasePage";
import BaseContainer from "../../components/containers/BaseContainer";
import CalendarEvent, {eventFromDate, eventFromEventAPI} from "../../models/CalendarEvent";
import EventForm from "./EventForm";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {EventClickArg, EventDropArg} from "@fullcalendar/common";
import {EventResizeDoneArg} from "@fullcalendar/interaction";
import CrudDrawer from "../../components/drawers/CrudDrawer";
import services from "../../services/rest/services";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";
import LinksLineIcon from "remixicon-react/LinksLineIcon";
import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";
import MailSendLineIcon from "remixicon-react/MailSendLineIcon";
import TextInputDialog from "../../components/dialogs/TextInputDialog";
import VideoAddLineIcon from "remixicon-react/VideoAddLineIcon";
import useStartCall from "../../hooks/useStartCall";
import VideoLinkDialog from "../../components/dialogs/VideoLinkDialog";


const CalendarPage: React.FC = () => {
    const calendarRef = React.createRef<FullCalendar>();
    const [submitButtonRef, handleSubmitClick] = useSubmitButtonRef();
    const currentProjectId = useSelector<AppState, string>((state) => state.project.selectedElement?.id ?? '')
    const [state, {setSelectedElement, updateElement, deleteElement, dispatch}] = useReduxCrudPropertyState((state: AppState) => state.event, eventActions, currentProjectId);

    const handleCreateClick = async () => setSelectedElement(eventFromDate(new Date(), false))
    const handleCancelClick = () => setSelectedElement(undefined)
    const handleSubmit = (event: CalendarEvent) => dispatch(updateElement(event))
    const handleEventClick = async (clickInfo: EventClickArg) => {
        const response = await services.events.get(currentProjectId, clickInfo.event.id);
        const event = response.success ? response.value : eventFromEventAPI(clickInfo.event);
        setSelectedElement(event);
    };
    const handleEventDrop = (dropInfo: EventDropArg) => updateElement(eventFromEventAPI(dropInfo.event));
    const handleEventResize = (resizeInfo: EventResizeDoneArg) => updateElement(eventFromEventAPI(resizeInfo.event))


    const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false)
    const [openVideoDialog, setOpenVideoDialog] = useState<boolean>(false);
    const [handleStartCall, mediaFeedback] = useStartCall(state.selectedElement?.id ?? 'temp');


    return (
        <BasePage>
            <BaseContainer>
                <CalendarView
                    calendarRef={calendarRef}
                    events={[...state.elements]}
                    eventClick={handleEventClick}
                    eventDrop={handleEventDrop}
                    eventResize={handleEventResize}
                    onCreateClick={handleCreateClick}
                />
                <CrudDrawer<CalendarEvent>
                    elementName={'Begivenhed'}
                    element={state.selectedElement}
                    loading={state.loading}
                    onCancel={handleCancelClick}
                    onSave={handleSubmitClick}
                    onDelete={deleteElement}
                    menuActions={[{
                        icon: LinksLineIcon,
                        title: 'Kopier begivenhed',
                        onClick: (event?: CalendarEvent) => {
                            event?.id && updateElement({...event, id: ''});
                            handleCancelClick();
                        },
                    }, {
                        icon: CloseCircleLineIcon,
                        title: state.selectedElement?.isCancelled ? 'Fjern aflysning' : 'Aflys',
                        onClick: (event?: CalendarEvent) => {
                            event?.id && dispatch(eventActions.cancelEvent(currentProjectId, event.id));
                            handleCancelClick();
                        }
                    }, {
                        icon: MailSendLineIcon,
                        title: 'Send besked til deltagere',
                        onClick: () => setOpenMessageDialog(true),
                    }, {
                        icon: VideoAddLineIcon,
                        title: 'Start videosamtale',
                        onClick: () => {
                            setOpenVideoDialog(true)
                            handleStartCall();
                        },
                    }]}
                >
                    <EventForm
                        event={state.selectedElement}
                        onSubmit={handleSubmit}
                        submitButtonRef={submitButtonRef}
                    />
                </CrudDrawer>
                <TextInputDialog
                    open={openMessageDialog}
                    title={'Send besked til deltagere'}
                    placeholder={'Skriv din besked her'}
                    onCancel={() => setOpenMessageDialog(false)}
                    onConfirm={(text) => {
                        services.events.sendMessage(currentProjectId, state.selectedElement?.id, {content: text})
                        setOpenMessageDialog(false);
                    }}
                />
                <VideoLinkDialog
                    callId={state.selectedElement?.id ?? 'temp'}
                    onOpen={setOpenVideoDialog}
                    open={openVideoDialog}
                    loading={mediaFeedback.loading}
                />
            </BaseContainer>
        </BasePage>
    );

};


export default CalendarPage;
