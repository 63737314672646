import {StreamType} from "./models";
import { v4 as uuidv4 } from "uuid";
import {RefObject} from "react";
import {detect} from "detect-browser";

export const isStreamEqualsStream = (streamA: MediaStream | undefined, streamB: MediaStream | undefined): boolean => {
    if (!streamA || !streamB) return true;
    return streamA.id === streamB.id;
};

export const isStreamEnabled = (type: StreamType, stream: MediaStream): boolean => {
    if (!stream.active) return false;
    let enabled = true;

    if (type === StreamType.video) {
        stream.getVideoTracks().forEach((track) => {
            enabled = track.enabled;
        });
    }

    if (type === StreamType.audio) {
        stream.getAudioTracks().forEach((track) => {
            enabled = track.enabled;
        });
    }

    return enabled;
};

export const getOrSetDeviceUUID = (callId: string): string => {
    let uuid = localStorage.getItem(callId);
    if (uuid === null) {
        uuid = uuidv4();
        localStorage.setItem(callId, uuid);
    }
    return uuid;
};

export const setVideoElement = (ref: RefObject<HTMLVideoElement>, stream: MediaStream | undefined) => {
    if (!stream) return;
    if (!ref.current) return;

    if (!('srcObject' in ref.current)) {
        (ref.current as HTMLVideoElement | { src: string }).src = window.URL.createObjectURL(stream);
        return;
    }

    if (isStreamIdEqualsRefStreamId(ref, stream)) return;

    ref.current.srcObject = stream;
};

export const isStreamIdEqualsRefStreamId = (
    ref: RefObject<HTMLVideoElement>,
    stream: MediaStream | undefined,
): boolean => {
    if (!stream) return true;
    if (!ref.current) return true;
    if (ref.current.srcObject instanceof MediaStream) {
        return ref.current.srcObject.id === stream.id;
    }
    return false;
};


const browserSupportWebRTCMap: { [p: string]: boolean } = {
    crios: false,
    'edge-ios': false,
    'ios-webview': false,
    facebook: false,
    instagram: false,
    'chromium-webview': false,
    firefox: true,
};

export const isWebRTCCompatible = (): boolean => {
    const browser = detect();
    const isWebRTCSupported = navigator.getUserMedia;
    if (browser?.name === undefined) return false;
    return browserSupportWebRTCMap[browser.name] ?? !!isWebRTCSupported;
};

export const handleUpdateStream = async (
    stream: MediaStream,
    constraints: MediaStreamConstraints,
): Promise<{ stream: MediaStream; success: boolean }> => {
    stream.getTracks().forEach((track) => stream.removeTrack(track));

    let success = true;
    let newStream: MediaStream | void = stream;

    try {
        newStream = await navigator.mediaDevices.getUserMedia(constraints).catch((e) => {
            success = false;
        });
    } catch (e) {
        return { stream, success: false };
    }

    if (newStream instanceof MediaStream) {
        newStream.getTracks().forEach((track) => stream.addTrack(track));
    }

    return { stream, success };
};
