import services from "../services/rest/services";
import Journal, {emptyJournal, JournalEntry} from "../models/Journal";
import {crudActionTypes, crudReducer, CrudState, initialCrudState, KnownCrudAction} from "./CrudState";
import {AppThunkAction} from "./index";
import {crudRestPropertyActions, CrudRestPropertyActionType} from "./CrudRestPropertyActions";
import {CallHistoryMethodAction, push} from "connected-react-router";
import Routes from "../constants/Routes";

const stateName = 'JOURNAL_STATE';
export const journalStateName = stateName;

export type JournalState = CrudState<Required<Journal>>;

export const initialJournalState: JournalState = initialCrudState<Required<Journal>>();

export type JournalActionsType = CrudRestPropertyActionType<Journal> & {
    updateEntry(journal?: Journal, element?: JournalEntry): AppThunkAction<KnownCrudAction<Journal>>;

    deleteEntry(journal?: Journal, entryId?: string): AppThunkAction<KnownCrudAction<Journal>>;

    selectJournal(userId?: string, youthId?: string): AppThunkAction<KnownCrudAction<Journal> | CallHistoryMethodAction>;

    updateEntryOrder(journal?: Journal): AppThunkAction<KnownCrudAction<Journal>>;
};

export const journalActions: JournalActionsType = {
    ...crudRestPropertyActions<Journal>(stateName, services.journals),
    updateEntry(journal, entry): AppThunkAction<KnownCrudAction<Journal>> {
        return async (dispatch) => {
            if (journal === undefined || entry === undefined) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});

            const response = await services.journals.updateEntry(journal.id, entry);
            if (!response.success) {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback});
                return;
            }

            if (!entry.id) {
                dispatch({
                    name: stateName, type: crudActionTypes.UPDATE, element: {
                        ...journal,
                        entries: [response.value,...(journal.entries??[])]
                    }
                });
                return;
            }


            const index = journal.entries.findIndex(e => e.id === entry.id);
            journal.entries[index] = response.value;
            dispatch({
                name: stateName, type: crudActionTypes.UPDATE, element: {
                    ...journal,
                    entries: journal.entries,
                }
            });


        };
    },
    deleteEntry(journal, entryId): AppThunkAction<KnownCrudAction<Journal>> {
        return async (dispatch) => {
            if (journal === undefined || entryId === undefined) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});


            const response = await services.journals.deleteEntry(journal.id, entryId);

            if (response.success) {
                console.log("Id in succes: " + entryId);
                dispatch({
                    name: stateName, type: crudActionTypes.UPDATE, element: {
                        ...journal,
                        entries: journal.entries.filter(e => e.id !== entryId),
                    }
                });
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback});
            }
        };
    },

    selectJournal(userId, youthId) {
        return async (dispatch) => {
            if (userId === undefined || youthId === undefined) return;
            dispatch({name: stateName, type: crudActionTypes.LOADING});

            const response = await services.journals.getJournalByYouthId(userId, youthId);
            if (response.success && response.value) {
                dispatch(push(Routes.journal + '/' + response.value.id));
            } else if (response.status === 404) {
                dispatch({
                    name: stateName, type: crudActionTypes.SELECT, element: {
                        ...emptyJournal,
                        youthId: youthId,
                    }
                });
            } else {
                dispatch({name: stateName, type: crudActionTypes.ERROR, feedback: response.feedback})
            }

        };
    },

    updateEntryOrder(journal) {
        return async (dispatch) => {
            if (!journal) return;
            dispatch({name: stateName, type: crudActionTypes.UPDATE, element: {...journal}});

        };
    },
};

export const journalReducer = crudReducer<Required<Journal>>(stateName, initialJournalState);

export default journalReducer;

