import {Route, Switch} from "react-router-dom";
import React from "react";
import Routes from "../../constants/Routes";
import NotFoundPage from "../error/NotFoundPage";
import YouthListPage from "./YouthListPage";
import YouthGroupPage from "./YouthGroupPage";


export const YouthPage = () => {


    // the order is important
    return (
        <Switch>
            <Route path={Routes.youthGroup} component={YouthGroupPage}/>
            <Route path={Routes.youth} component={YouthListPage}/>
            <Route path={Routes.youth}>
                <NotFoundPage/>
                {/*<Redirect to={Routes.youth}/>*/}
            </Route>
        </Switch>
    )
}
export default YouthPage;