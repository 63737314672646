import CalendarEvent from "../../models/CalendarEvent";
import {addDays} from "date-fns";
import RestResponse, {responseFromError} from "../../models/RestResponse";
import {crudPropertyService, CrudPropertyServiceType} from "./crudPropertyService";
import {RequestMethod, RestClient} from "./restClient";


export type EventServiceType = CrudPropertyServiceType<CalendarEvent> & {
    createGroup: (projectId?: string, event?: CalendarEvent) => RestResponse<Required<CalendarEvent>[]>;
    sendMessage: (projectId?: string, eventId?: string, message?: { content: string }) => RestResponse<unknown>;
    cancelEvent: (projectId?: string, eventId?: string) => RestResponse<CalendarEvent>;
};

export const eventService = (client: RestClient): EventServiceType => {
    return {
        ...crudPropertyService(client, '/api/web/v1/projects', 'events'),
        createGroup: async (projectId, event) => {
            if (projectId === undefined || event === undefined) return responseFromError(400);
            return await client.fetchJSON(RequestMethod.POST, `/api/web/v1/projects/${projectId}/events/group`, Array(event.occurrences).fill(event).map((e: CalendarEvent, i) => ({
                ...e,
                start: addDays(e.start, i * 7),
                end: addDays(e.end, i * 7),
            })) as any);
        },
        sendMessage: async (projectId, eventId, message) => {
            return await client.fetchJSON(RequestMethod.POST, `/api/web/v1/projects/${projectId}/events/${eventId}/message`, message);
        },
        cancelEvent: async (projectId, eventId) => {
            return await client.fetchJSON(RequestMethod.POST, `/api/web/v1/projects/${projectId}/events/${eventId}/cancel`);
        }
    };
};

export default eventService;
