import React, {useEffect, useRef} from 'react';
import {Backdrop, CircularProgress, IconButton} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import useTypedSelector from "../../hooks/useTypedSelector";
import {callActions} from "../../store/CallState/actions";
import {useDispatch} from "react-redux";
import PhoneFillIcon from "remixicon-react/PhoneFillIcon";
import Fab from "@material-ui/core/Fab";
import MicOffLineIcon from "remixicon-react/MicOffLineIcon";
import MicLineIcon from "remixicon-react/MicLineIcon";
import CameraOffLineIcon from "remixicon-react/CameraOffLineIcon";
import CameraLineIcon from "remixicon-react/CameraLineIcon";
import {setVideoElement} from "../../store/CallState/lib";
import {StreamType} from "../../store/CallState/models";
import FabToggleButton from "../buttons/FabToggleButton";

const useStyles = makeStyles((theme) => ({
    dialog: {
        display: "flex",
        flexDirection: "column",
    },
    video: {
        transform: 'rotateY(180deg)',
    },
    phone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.common.white,
        fontWeight: "bold",
        '& svg': {
            marginRight: theme.spacing(1),
        }
    },
}))

export interface VideoLinkDialog {
    callId: string;
    onStart?: () => void;
    onOpen: (b: boolean) => void;
    open: boolean;
    loading: boolean;
}

export const VideoLinkDialog: React.FC<VideoLinkDialog> = ({callId, onStart, onOpen, open, loading}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {localStream, connected, localVideoEnabled, localAudioEnabled} = useTypedSelector(state => state.call);

    const videoRef = useRef<HTMLVideoElement>(null);
    // const [handleStartCall, mediaFeedback] = useStartCall(callId);
    const handleAudio = () => dispatch(callActions.updateStream(StreamType.audio));
    const handleVideo = () => dispatch(callActions.updateStream(StreamType.video));
    
    const handleClose = async () => {
        await dispatch(callActions.disconnect(callId));
        localStream.getTracks().forEach(t => t.stop());
        // await dispatch(callActions.setVideoStream(localStream));
        onOpen(false);
    }

    // const handleButtonClick = async () => {
    //     onOpen(true)
    //     await handleStartCall();
    // }

    const handleCallClick = async () => {
        const query = "?video=" + localVideoEnabled + "&audio=" + localAudioEnabled;
        const url = "/video/" + callId + query;
        window.open(url, "_blank");
        onStart && onStart();
        await handleClose();
    }

    useEffect(() => {
        if (!localStream) return;
        setVideoElement(videoRef, localStream);
    }, [localStream]);


    return (
        <Dialog open={open} onClose={handleClose}>
            {loading && (
                <Backdrop open={true}>
                    <CircularProgress/>
                </Backdrop>
            )}
            <Box className={classes.dialog} style={{display: connected ? undefined : "none"}}>
                <video
                    className={classes.video}
                    ref={videoRef}
                    playsInline
                    autoPlay
                    muted={true}
                />
                {connected && (
                    <Box
                        position="absolute"
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        width="100%"
                        height="100%"
                    >
                        <Box display="flex" justifyContent="flex-end" p={1}>
                            <IconButton onClick={handleClose}>
                                <CloseLineIcon color="white"/>
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <FabToggleButton
                                onToggle={handleAudio}
                                on={localAudioEnabled}
                                offIcon={MicOffLineIcon}
                                onIcon={MicLineIcon}
                            />
                            <Fab onClick={handleCallClick} className={classes.phone} variant="extended">
                                <PhoneFillIcon color="white"/>Start opkald
                            </Fab>
                            <FabToggleButton
                                onToggle={handleVideo}
                                on={localVideoEnabled}
                                onIcon={CameraLineIcon}
                                offIcon={CameraOffLineIcon}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Dialog>
    )
};


export default VideoLinkDialog;
