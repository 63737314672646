
export const permissions = {
    users: {
        read: "Permissions.Users.Read",
        write: "Permissions.Users.Write",
    },
    inquiries: {
        read: "Permissions.Inquiries.Read",
        write: "Permissions.Inquiries.Write",
    },
    calendar: {
        read: "Permissions.Calendar.Read",
        write: "Permissions.Calendar.Write",
    },
    journals: {
        read: "Permissions.Journals.Read",
        write: "Permissions.Journals.Write",
    },
    surveys: {
        read: "Permissions.Surveys.Read",
        write: "Permissions.Surveys.Write"
    },
    settings: {
        read: undefined,
        write: "Permissions.Settings.Write",
    },
    youth: {
        read: "Permissions.Youth.Read",
        write: "Permissions.Youth.Write",
    }
}

export const StandardRole = {
    id: "std",
    permissions: {
        [permissions.users.read]: true,
        [permissions.inquiries.read]: true,
        [permissions.inquiries.write]: true,
        [permissions.journals.read]: true,
        [permissions.journals.write]: true,
        [permissions.surveys.read]: true,
        [permissions.surveys.write]: true,
        [permissions.youth.read]: true,
        [permissions.youth.write]: true
    },
}

export const SuperRole = {
    id: "super",
    permissions: {
        ...StandardRole.permissions,
        [permissions.users.write]: true,
        [permissions.settings.write]: true,
    }
}

export const RoleMap = {
    [SuperRole.id]: "Super",
    [StandardRole.id]: "Standard",
}

export default {
    [StandardRole.id]: StandardRole,
    [SuperRole.id]: SuperRole,
};