import React, {ComponentType, useEffect, useRef, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Badge, Divider} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import NotificationIcon from 'remixicon-react/NotificationLineIcon';
import CalendarEventIcon from 'remixicon-react/CalendarEventFillIcon';
import {toLocalDateTimeString} from "../../lib/toLocalISO";
import Routes from "../../constants/Routes";
import {RemixiconReactIconProps} from "remixicon-react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {useDispatch, useSelector} from "react-redux";
import {notificationActions, notificationStateName} from "../../store/NotificationState";
import {crudActionTypes} from "../../store/CrudState";
import {AppState} from "../../store";

const useStyles = makeStyles(theme => ({
    iconButton: {
        background: 'white',
        boxShadow: '0 1px 4px rgba(0,0,0,.09)',
    },
    appBar: {
        padding: theme.spacing(2),
        fontSize: 16,
        margin: 0,
        fontWeight: 600,
        minWidth: 350,
    },
    emptyView: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

    },
    fetchMoreButton: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    redDot: {
        padding: 4,
        background: theme.palette.error.main,
        borderRadius: "50%"
    },
}));

interface NotificationType {
    icon: ComponentType<RemixiconReactIconProps>;
    link?: string;
}

const defaultNotificationType: NotificationType = {icon: CalendarEventIcon, link: Routes.calendar};
const notificationTypeMap: { [p: string]: NotificationType } = {
    "calendar": {icon: CalendarEventIcon, link: Routes.calendar},
};


export const NotificationBell: React.FC = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const state = useSelector((state: AppState) => state.notification);
    const notifications = state.elements?.slice(0, 10) ?? [];
    const unreadCount = state.elements?.filter(n => !n.read)?.length ?? 0;

    useEffect(() => {
        if (state.connected || state.loading) return;
        dispatch(notificationActions.connect());
    }, [dispatch, state])


    const iconRef = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = async () => {
        setOpen(false);
        const notifications = await state.client?.readAll();
        dispatch({name: notificationStateName, type: crudActionTypes.REFRESH, elements: notifications})
    }
    const handleOpen = async () => {
        setOpen(true);
    };

    // const handleReadMore = () => {
    //     // dispatch(notificationsActions.readQueued());
    // };

    return (
        <React.Fragment>
            <IconButton ref={iconRef} className={classes.iconButton} onClick={handleOpen}>
                <Badge variant="dot" overlap="circle" badgeContent={unreadCount} color="error">
                    <NotificationIcon size={20}/>
                </Badge>
            </IconButton>
            <Popover
                anchorEl={iconRef.current}
                open={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <List style={{position: "relative"}}>
                    <h3 className={classes.appBar}>Notifikationer</h3>
                    <Divider/>
                    {notifications.length === 0 && <div className={classes.emptyView}>Ingen notifikationer</div>}
                    {notifications.map((notification) => {
                        const type = notificationTypeMap[notification.type] ?? defaultNotificationType;
                        const Icon = type.icon;
                        return <ListItem
                            key={notification.id}
                            button
                            onClick={() => {
                                // if (type.link) {
                                // dispatch(push(type.link + '/' + notification.itemId));
                                // onClose();
                                // }
                            }}>
                            <ListItemAvatar>
                                <Icon fontSize={16}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={notification.body}
                                secondary={toLocalDateTimeString(notification.createdAt)}
                            />

                            {!notification.read && <ListItemSecondaryAction>
                                <div className={classes.redDot}/>
                            </ListItemSecondaryAction>}

                        </ListItem>
                    })}
                    {/*<Button className={classes.fetchMoreButton} onClick={handleReadMore}>*/}
                    {/*    Indlæs flere*/}
                    {/*</Button>*/}
                </List>
            </Popover>
        </React.Fragment>
    )
};

export default NotificationBell;