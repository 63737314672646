import Routes from './Routes';
import FeatureNames from "./FeatureNames";
import {permissions} from "./Roles";
import React from "react";

import ChatIcon from 'remixicon-react/Chat3LineIcon';
import SettingsIcon from 'remixicon-react/EqualizerFillIcon';
import UsersIcon from 'remixicon-react/GroupLineIcon';
import CalendarIcon from 'remixicon-react/CalendarEventLineIcon';
import SurveyIcon from 'remixicon-react/SurveyLineIcon';
import YouthIcon from 'remixicon-react/EmpathizeLineIcon';
import UserLineIcon from "remixicon-react/UserLineIcon";
import HelpIcon from "remixicon-react/QuestionLineIcon";
import {RemixiconReactIconComponentType} from "remixicon-react";
import OverviewIcon from "remixicon-react/StackLineIcon";
import ContactsBook2LineIcon from "remixicon-react/ContactsBook2LineIcon";
import {AppState} from "../store";
import {useSelector} from "react-redux";
import OverviewPage from "../pages/overview/OverviewPage";
import ChatPage from "../pages/chat/ChatPage";
import CalendarPage from "../pages/calendar/CalendarPage";
import JournalPage from "../pages/journal/JournalPage";
import SurveyListPage from "../pages/survey/SurveyListPage";
import UsersPage from "../pages/users/UsersPage";
import ProjectPage from "../pages/settings/ProjectPage";
import ProfilePage from "../pages/me/ProfilePage";
import NotFoundPage from "../pages/error/NotFoundPage";
import {isPermitted} from "../hooks/usePermitted";
import YouthPage from "../pages/youth/YouthPage";

export interface ActionNavItem {
    name: string;
    path: string;
    component?: React.ReactNode;
}

export interface NavItem {
    name: string;
    showRedDot?: boolean;
    checkRedDot?: (state: AppState) => boolean;
    external?: boolean;
    scroll?: boolean;
    icon: RemixiconReactIconComponentType;
    path: string;
    feature?: string;
    permission?: string;
    startActions?: [ActionNavItem, ...ActionNavItem[]];
    endActions?: [ActionNavItem, ...ActionNavItem[]];
    component: React.ElementType;
}


export const useNavigationList = () => {
    const state = useSelector((state: AppState) => state);
    const currentUser = state.auth.currentUser;
    const featureMap = state.project.selectedElement?.features ?? {};
    const filteredList = navigationList.filter((item) => {
        const enabled = item.feature ? Boolean(featureMap[item.feature]) : true;
        const permitted = item.permission ? isPermitted(currentUser, item.permission) : true;
        return enabled && permitted;
    }, []);
    return filteredList.map(item => {
        item.showRedDot = item.checkRedDot?.(state);
        return item;
    })
}


export const navigationList: NavItem[] = [
    {
        name: "Overblik",
        icon: OverviewIcon,
        path: Routes.overview,
        scroll: true,
        // permission: permissions.settings.write,
        component: OverviewPage
    },
    {
        name: "Chat",
        icon: ChatIcon,
        path: Routes.chat,
        checkRedDot: (state) => !!state.chat.elements.find(e => !e.read),
        // feature: FeatureNames.contact,
        // permission: Permissions.Inquiries.Read,
        component: ChatPage
    },
    {
        name: "Begivenheder",
        icon: CalendarIcon,
        path: Routes.calendar,
        // feature: FeatureNames.journal,
        component: CalendarPage,
    },
    {
        name: "Journaler",
        icon: ContactsBook2LineIcon,
        path: Routes.journal,
        feature: FeatureNames.journal,
        component: JournalPage,
    },
    {
        name: "Spørgeskemaer",
        icon: SurveyIcon,
        scroll: true,
        path: Routes.surveys,
        feature: FeatureNames.survey,
        component: SurveyListPage,
    },
    {
        icon: YouthIcon,
        name: 'Unge',
        scroll: true,
        path: Routes.youth,
        permission: permissions.youth.read,
        startActions: [
            {name: "Enkelte", path: Routes.youth},
            {name: "Grupper", path: Routes.youthGroup}
        ],
        component: YouthPage
    },
    {
        icon: UsersIcon,
        name: 'Brugere',
        scroll: true,
        path: Routes.users,
        permission: permissions.users.read,
        component: UsersPage,
    },
    {
        icon: SettingsIcon,
        name: 'Indstillinger',
        scroll: true,
        path: Routes.projectSettings,
        permission: permissions.settings.write,
        component: ProjectPage
    },
];

export const help: NavItem = {
    icon: HelpIcon,
    name: 'Hjælp',
    external: true,
    path: 'https://support.innosocial.dk/dikom',
    component: NotFoundPage,
};


export const profile: NavItem = {
    icon: UserLineIcon,
    name: 'Profil',
    scroll: true,
    path: Routes.profile,
    component: ProfilePage,
};

export const utilityNavList = [help, profile]

export default useNavigationList;
