import React, {useState} from "react";
import {Box, CircularProgress, Container} from "@material-ui/core";
import {useParams} from "react-router-dom";
import services from "../../services/rest/services";
import SurveyAnswerForm from "./SurveyAnswerForm";
import {SurveyAnswerRequest} from "../../models/SurveyAnswer";
import FullCenterPage from "../../components/containers/FullCenterPage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import CheckboxCircleFillIcon from "remixicon-react/CheckboxCircleFillIcon";
import {useRequestState} from "../../hooks/useRequestList";

const useStyles = makeStyles(() => ({
    background: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url(\"https://uploads-ssl.webflow.com/5fb295c657973a9d768ede11/5fb2f225471e6f4cfb3107e1_footer_bg_overlay.png\"), linear-gradient(-135deg, #087ee1, #942dc4)",
        overflow: 'hidden',
    },
}));


const SurveyAnswerPage: React.FC = () => {
    const classes = useStyles();
    const {projectId, surveyId} = useParams<{ projectId: string, surveyId: string }>();
    const [survey] = useRequestState(() => services.surveys.get(projectId, surveyId));
    const [done, setDone] = useState<boolean>(false);

    const handleSubmit = async (values: SurveyAnswerRequest) => {
        // TODO
        const response = await services.surveys.submitAnswer(projectId, surveyId, values);
        if (response.success) {
            setDone(true);
        }
    }

    if (!survey?.id) return (
        <FullCenterPage>
            <CircularProgress size={60}/>
        </FullCenterPage>
    )

    if (done) {
        return (
            <Box className={classes.background}>
                <Paper style={{background: '#20CC74', borderRadius: '50%'}}>
                    <Box p={4}>
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            <CheckboxCircleFillIcon style={{color: 'white', fontSize: 60}}/>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        )
    }

    return (
        <Box className={classes.background}>
            <Container maxWidth='md' style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                <SurveyAnswerForm survey={survey} onSubmit={handleSubmit}/>
            </Container>
        </Box>
    );
}


export default SurveyAnswerPage;


