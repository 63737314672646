import Typography from "@material-ui/core/Typography";
import {ListItemIcon, MenuItem, TextField} from "@material-ui/core";
import React, {useState} from "react";
import MailSendLineIcon from "remixicon-react/MailSendLineIcon";
import Box from "@material-ui/core/Box";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import {InputProps} from "@material-ui/core/Input/Input";
import useTypedSelector from "../../hooks/useTypedSelector";
import NamedValue from "../../models/NamedValue";

interface SendMessageMenuItem {
    respondents: NamedValue[];
    title: string;
    onClose: () => void;
}

const SendMessageMenuItem: React.FC<SendMessageMenuItem> = ({respondents, title, onClose}) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string>("");
    const youthState = useTypedSelector(state => state.youth);
    const chatState = useTypedSelector(state => state.chat);


    const handleChange: InputProps["onChange"] = e => setValue(e.target.value);
    const handleClick = () => {
        onClose();
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleConfirm = () => {
        const threadIds = youthState.elements.filter(y => respondents.some(r => r.id === y.id)).map(y => y.chatThreadId);
        threadIds.forEach(threadId => {
            if (!threadId) return;
            chatState.client?.sendMessage(threadId, value);
        })
        handleClose();
    }

    return (
        <React.Fragment>
            <MenuItem onClick={handleClick}>
                <ListItemIcon>
                    <MailSendLineIcon size={20}/>
                </ListItemIcon>
                <Typography variant="inherit">{title}</Typography>
            </MenuItem>
            <ConfirmDialog
                title={title}
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "xs"
                }}
            >
                <Box p={2}>
                    <TextField
                        placeholder="Skriv din besked her"
                        onChange={handleChange}
                        value={value}
                        multiline
                        fullWidth
                    />
                </Box>
            </ConfirmDialog>
        </React.Fragment>
    )
}
export default SendMessageMenuItem;

