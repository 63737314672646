import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import MessageListView from "./MessageListView";
import MessageInput from "./MessageInput";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import useTypedSelector from "../../hooks/useTypedSelector";
import {Box, Menu} from "@material-ui/core";
import VideoButton from "../../components/buttons/VideoLinkButton";
import {CustomTheme} from "../../constants/theme";
import Center from "../../components/containers/Center";
import ContentContainer from "../../components/containers/ContentContainer";
import Routes from "../../constants/Routes";
import {push} from "connected-react-router";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import SendPlane2FillIcon from "remixicon-react/SendPlane2FillIcon";
import EditMenuItem from "../../components/menu/EditMenuItem";
import More2FillIcon from "remixicon-react/More2FillIcon";

const useStyles = makeStyles((theme: CustomTheme) => ({
    root: {
        height: "100%",
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            marginLeft: '0',
        },
    },
    header: {
        height: theme.custom.appBarHeight,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
    },
    botDivider: {marginTop: 8},
    input: {display: 'flex', padding: '8px 12px'},
    onMobile: {
        display: "none",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            display: "block",
        }
    }
}));


const ChatView: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {chatId} = useParams<{ chatId: string }>();
    const state = useTypedSelector(state => state.chat);
    const messagesEndRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
    const [text, setText] = useState('');
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // const youths = useTypedSelector(state => state.youth).elements;
    // const journals = useTypedSelector(state => state.journal).elements;
    // const [openDrawer, setOpenDrawer] = useState(false);
    // const [journal, setJournal] = useState<Journal | undefined>(undefined);
    // const [submitEntryButtonRef, handleSaveEntryClick] = useSubmitButtonRef();

    const {client, elements: threads} = state;
    const thread = threads && threads.find((t: any) => t.id === chatId);
    const messagesLength = thread?.messages?.length;

    useEffect(() => {
        messagesEndRef?.current?.scrollIntoView({behavior: 'auto'})
    }, [messagesLength]);

    if (!chatId || !thread) return (
        <Center>
            Ingen valgt chatsamtale
        </Center>
    );

    // const setYouthJournal = () => {
    //     const youth = youths.find((youth) => youth.chatThreadId === thread.id);
    //     const j = journals.find((journal) => journal.youthId === youth?.id);
    //     setJournal(j);
    // };

    const handleBackClick = () => dispatch(push(Routes.chat));

    const handleChange = (t: string) => setText(t);
    const handleVideo = () => client?.sendVideoLink(thread, thread.id);
    const handleChangeName = (text: string) => client?.setName(thread?.id, text);
    const onSendMessage = () => {
        client?.sendMessage(thread.id, text);
        setText('');
    }
    const handleMore = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    // const handleEditJournalClick = () => {
    //     setYouthJournal();
    //     if (journal !== undefined) {
    //         setOpenDrawer(true);
    //     } else {
    //         snackbar.info("Der er endnu ikke blevet oprettet en journal for den valgte ung.");
    //         setAnchorEl(null);
    //     }
    // };
    //
    // const handleEntrySubmit = (entry: JournalEntry) => {
    //     dispatch(journalActions.updateEntry(journal, {
    //         ...entry,
    //     }));
    //     setJournal(undefined);
    //     setOpenDrawer(false);
    //     setAnchorEl(null);
    // }

    // const handleDelete = () => {
    //     client?.deleteThread(thread.id);
    //     handleBackClick();
    // }

    return (
        <ContentContainer state={state} empty="Ingen chatsamtaler">
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.onMobile}>
                        <IconButton color='primary' onClick={handleBackClick}>
                            <ArrowLeftLineIcon/>
                        </IconButton>
                    </div>
                    <span>{thread.name}</span>
                    <Box flex={1}/>
                    <Box>
                        <VideoButton callId={thread.id} onVideo={handleVideo}/>
                        <IconButton
                            aria-label="more"
                            aria-controls="more-menu"
                            aria-haspopup="true"
                            color="primary"
                            onClick={handleMore}
                        >
                            <More2FillIcon/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <EditMenuItem
                                title="Rediger samtalenavn"
                                text={thread.name}
                                onConfirm={handleChangeName}
                            />
                            {/*<MenuItem onClick={handleEditJournalClick}>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <BookOpenLineIcon size={20}/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <Typography variant="inherit">Tilføj notat til journal</Typography>*/}
                            {/*</MenuItem>*/}
                            {/*<DeleteMenuItem*/}
                            {/*    title="Slet samtale"*/}
                            {/*    message="Er du sikker på, at du vil slette samtalen? Du kan ikke fortryde dette"*/}
                            {/*    onConfirm={handleDelete}*/}
                            {/*/>*/}
                        </Menu>
                    </Box>
                </div>
                <Divider/>
                <MessageListView
                    thread={thread}
                    endRef={messagesEndRef}
                />
                <Divider className={classes.botDivider}/>
                <div className={classes.input}>
                    <MessageInput value={text} onSubmit={onSendMessage} onChange={handleChange}/>
                    <IconButton onClick={onSendMessage} color='primary' disabled={text?.length === 0}>
                        <SendPlane2FillIcon/>
                    </IconButton>
                </div>
            </div>
            {/*<FormDrawer*/}
            {/*    title={"Opret notat"}*/}
            {/*    open={openDrawer}*/}
            {/*    onCancel={() => {*/}
            {/*        setJournal(undefined);*/}
            {/*        setOpenDrawer(false);*/}
            {/*    }}*/}
            {/*    onSave={handleSaveEntryClick}*/}
            {/*>*/}
            {/*    <JournalEntryForm*/}
            {/*        submitButtonRef={submitEntryButtonRef}*/}
            {/*        onSubmit={handleEntrySubmit}*/}
            {/*        initial={emptyJournalEntry}*/}
            {/*    />*/}
            {/*</FormDrawer>*/}
        </ContentContainer>
    );
}

export default ChatView;

