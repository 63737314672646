import React from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';

import TextField from '@material-ui/core/TextField/TextField';
import Grid from "@material-ui/core/Grid";
import FormProps from "../models/FormProps";
import Journal, {emptyJournal} from "../models/Journal";
import {AppState} from "../store";
import {projectYouthActions} from "../store/YouthState";
import useCurrentProject from "../hooks/useCurrentProject";
import useReduxCrudPropertyState from "../hooks/useReduxCrudPropertyState";
import {Input} from "@material-ui/core";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useCurrentUser from "../hooks/useCurrentUser";

const useStyles = makeStyles(theme => ({
    welcome: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1.5),
        height: "100%",
        width: "100%",
        display: 'flex',
        alignItems: 'flex-start',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        fontSize: 14,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        marginBottom: theme.spacing(1),
        background: '#FAFAFA'
    },
}))

export type JournalFormProps = FormProps<Journal>;
export const JournalForm: React.FC<JournalFormProps> = ({submitButtonRef, initial, onSubmit}) => {
    const classes = useStyles();
    const currentProject = useCurrentProject();
    const currentUser = useCurrentUser();
    const [state] = useReduxCrudPropertyState((state: AppState) => state.youth, projectYouthActions, currentProject.id);
    const elements = state.elements;
    const youth = elements.find((y) => initial?.youthId ? (y.id === initial?.youthId) : (y.id === initial?.youthId));


    const handleSubmit = async (values: Journal, {setSubmitting, resetForm}: FormikHelpers<Journal>) => {
        console.log("Her burde du submitte.");
        await onSubmit({
            ...values,
            name: youth?.name??"",
            youthId: youth?.id??"",
        });
        setSubmitting(false);
        resetForm();
        console.log("Her burde du have submittet og resat form.");
    };

    return (
        <Formik<Journal>
            initialValues={{
                ...emptyJournal,
                ...initial,
                name: "Journal for " + youth?.name,
                author: {
                    id: currentUser.id,
                    name: currentUser.firstName + " " + currentUser.lastName,
                }
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {() => {
                return (
                    <Form>
                        <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="name"
                                    type="name"
                                    label="Navn"
                                    disabled
                                    fullWidth
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={Input}
                                    className={clsx(classes.input, classes.welcome)}
                                    placeholder="Kort beskrivelse"
                                    multiline
                                    variant='filled'
                                    fullWidth
                                    rows={10}
                                    name="description"
                                    type="text"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
}



export default JournalForm;

