import React from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';

import TextField from '@material-ui/core/TextField/TextField';
import Grid from "@material-ui/core/Grid";
import FormProps from "../models/FormProps";
import Validators from "../lib/Validators";
import {emptyJournalEntry, JournalEntry} from "../models/Journal";
import {Input} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import Tag, {dummyTags} from "../models/Tag";
import useCurrentUser from "../hooks/useCurrentUser";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";

export type JournalEntryFormProps = FormProps<JournalEntry>;

const useStyles = makeStyles(theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        fontSize: 14,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        marginBottom: theme.spacing(1),
        background: '#FAFAFA'
    },
}))

const initialTags: Tag[] = [];

export const JournalEntryForm: React.FC<JournalEntryFormProps> = ({submitButtonRef, initial, onSubmit}) => {
    const classes = useStyles();
    const currentUser = useCurrentUser();
    const [tags, setTags] = React.useState<Tag[]>(initialTags);

    const handleSubmit = async (values: JournalEntry, {setSubmitting, resetForm}: FormikHelpers<JournalEntry>) => {
        onSubmit({
            ...values,
            tags: tags,
            author: {
                id: currentUser?.id??"",
                name:`${currentUser?.firstName} ${currentUser?.lastName}`??"",
            },
        })
        setSubmitting(false);
        resetForm();
    };

    return (
        <Formik<JournalEntry>
            initialValues={{
                ...emptyJournalEntry,
                ...initial,
            }}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({values,errors,touched,setFieldValue }) => {
                return (
                    <Form>
                        <button aria-label="submit" type="submit" style={{display: 'none'}} ref={submitButtonRef}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="name"
                                    type="name"
                                    label="Titel"
                                    required
                                    fullWidth
                                    variant='filled'
                                    validate={Validators.notEmpty}
                                    error={errors.name && touched.name}
                                    helperText={values.name.length !== 0 && errors.name}
                                />
                            </Grid>
                            <Grid item xs={6} style={{alignSelf: "center"}}>
                                <span>Hændelsesdato</span>
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    inputVariant='filled'
                                    className={classes.input}
                                    value={values.occurrenceDate}
                                    onChange={(date: Date | MaterialUiPickersDate) => {
                                        setFieldValue('occurrenceDate', date);
                                    }}
                                    disableFuture
                                    keyboardIcon={<CalendarLineIcon/>}
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage="Ugyldigt format"
                                    maxDateMessage="Ugyldig dato"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={Input}
                                    placeholder="Notat ..."
                                    multiline
                                    variant='filled'
                                    fullWidth
                                    rows={20}
                                    name="content"
                                    type="text"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="tags-standard"
                                    value={tags}
                                    onChange={(event, newValue) => setTags(newValue)}
                                    multiple
                                    limitTags={3}
                                    options={dummyTags}
                                    getOptionLabel={(option: Tag) => option.name}
                                    renderTags={(tags => <span style={{marginRight: 4}}>{tags.map(e => e.name).join(', ')}</span>)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={tags?.length ? "" : "Tilføj tags (venskab, familie...)"}
                                        />
                                    )}
                                    style={{
                                        flex: 3,
                                        borderRadius: 24,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
}



export default JournalEntryForm;

