import React, {useState} from "react";
import {Survey, surveySearchFilter} from "../../models/Survey";
import {surveyActions} from "../../store/SurveyState";
import BasePage from "../../components/containers/BasePage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import usePermitted from "../../hooks/usePermitted";
import {permissions} from "../../constants/Roles";
import {AppState} from "../../store";
import useCurrentProject from "../../hooks/useCurrentProject";
import CrudDrawer from "../../components/drawers/CrudDrawer";
import SurveyFormik from "./SurveyFormik";
import BasePageToolbar from "../../components/containers/BasePageToolbar";
import SurveyTable from "./SurveyTable";
import ContentContainer from "../../components/containers/ContentContainer";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";
import useSubmitButtonRef from "../../hooks/useSubmitButtonRef";

const useStyles = makeStyles(() => ({
    root: {
        alignItems: "start",
        flexDirection: "column",
    }
}))

const SurveyListPage: React.FC = () => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [state] = useReduxCrudPropertyState((state: AppState) => state.survey, surveyActions, useCurrentProject().id);
    const elements = state.elements;
    const [selected, setSelected] = useState<Survey | undefined>(undefined);
    const [submitButtonRef, handleSubmitClick] = useSubmitButtonRef();

    const handleClose = () => {
        setOpen(false)
        setSelected(undefined);
    };
    // TODO: Survey deletion
    const handleDelete = () => console.log("survey deletion not implemented");

    const handleEditClick = async (surveyId: string) => {
        const survey = elements.find((s: Survey) => s.id === surveyId);
        setSelected(survey);
        setOpen(true);
    }

    const [search, setSearch] = useState('');
    const permitted = usePermitted(permissions.surveys.write);

    const filteredSurvey = elements.filter(surveySearchFilter(search));

    return (
        <BasePage className={classes.root}>
            <BasePageToolbar
                search={search}
                onSearch={setSearch}
                permitted={permitted}
                onCreateClick={() => setOpen(true)}
            />
            <ContentContainer state={state} empty="Ingen spørgeskemaer oprettet">
                <SurveyTable surveys={filteredSurvey} handleEditClick={handleEditClick}/>
            </ContentContainer>

            <CrudDrawer
                elementName="Spørgeskema"
                open={open}
                onCancel={handleClose}
                onSave={handleSubmitClick}
                onDelete={handleDelete}
                element={state.selectedElement}
            >
                <SurveyFormik
                    submitButtonRef={submitButtonRef}
                    onSubmit={() => setOpen(false)}
                    initial={selected}
                />
            </CrudDrawer>
        </BasePage>
    );
}

export default SurveyListPage;