import React from "react";
import {ChatMessage} from "../../models/ChatThread";
import VideoLinkButton from "../../components/buttons/VideoLinkButton";
import {toLocalISOTime} from "../../lib/toLocalISO";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {IconButtonProps} from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItem: "center",
        justifyContent: "space-between",
        color: "#fff",
    },
    body: {
        '& span': {
            fontWeight: "bold",

        },
    },
    button: {
        backgroundColor: theme.palette.primary.contrastText,
        marginRight: theme.spacing(1),
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
            opacity: 0.7,
        }
    }
}))

const VideoMessage: React.FC<{ message: Pick<ChatMessage, "content" | "createdAt"> }> = ({ message }) => {
    const classes = useStyles();

    const buttonProps: IconButtonProps = {
        className: classes.button,
        size: "medium"
    }

    return (
        <div className={classes.root}>
            <VideoLinkButton callId={message.content} ButtonProps={buttonProps} />
            <div className={classes.body}>
                <Typography variant="body1" component="span">Videosamtale</Typography>
                <Typography variant="body2">{toLocalISOTime(message.createdAt as Date)}</Typography>
            </div>
        </div>
    )
}

export default VideoMessage;
