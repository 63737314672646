import {useEffect} from "react";
import {chatActions} from "../../store/ChatState";
import {projectUserActions} from "../../store/ProjectUserState";
import useCurrentProject from "../../hooks/useCurrentProject";
import {useDispatch} from "react-redux";
import {projectYouthActions} from "../../store/YouthState";
import {surveyActions} from "../../store/SurveyState";
import {eventActions} from "../../store/EventState";

const useBaseEffects = () => {
    const dispatch = useDispatch();
    const currentProject = useCurrentProject();
    const currentProjectId = currentProject?.id;

    useEffect(() => {
        dispatch(projectUserActions.refresh(currentProjectId));
        dispatch(projectYouthActions.refresh(currentProjectId));
        dispatch(surveyActions.refresh(currentProjectId));
        dispatch(eventActions.refresh(currentProjectId));
        dispatch(chatActions.connect());
    }, [dispatch, currentProjectId])
}

export default useBaseEffects;
