import Authorization, {emptyAuthorization} from "../../models/Authorization";
import RestResponse, {responseFromError, RestErrorResponse} from "../../models/RestResponse";
import StringMap from "../../models/StringMap";

const REST_AUTH_REMEMBER_KEY = 'REST_AUTH_REMEMBER_KEY';
const AUTHORIZATION_KEY = 'AUTHORIZATION_KEY';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';

export enum RequestMethod {
    'GET',
    'POST',
    'PUT',
    'DELETE',
}

export interface RestRequest {
    method: RequestMethod;
    path: string;
    body?: BodyInit;
    headers?: StringMap;
}

export interface RestClient {
    host: string;
    onHttpError: <T>(request: RestRequest, response: RestErrorResponse) => RestResponse<T>;

    fetchRequest: <T>(request: RestRequest) => RestResponse<T>;
    fetchJSON: <T>(method: RequestMethod, path: string, body?: { [p: string]: string | unknown }, headers?: { [p: string]: string }) => RestResponse<T>;
    fetchForm: <T>(method: RequestMethod, path: string, body?: FormData, headers?: { [p: string]: string }) => RestResponse<T>;
    fetch: <T>(method: RequestMethod, path: string, body?: BodyInit, headers?: StringMap) => RestResponse<T>;


    getRemember: () => boolean;
    setRemember: (remember: boolean) => void;
    getAuthorization: () => Authorization;
    setAuthorization: (authorization: Authorization) => void;
    getAccessToken: () => string | undefined;
    setAccessToken: (accessToken: string) => void;
}

export function restClient(host: string, options?: {
    cache?: RequestCache,
}): RestClient {
    const authorizationKey = [host, AUTHORIZATION_KEY].join('.');
    const accessTokenKey = [host, ACCESS_TOKEN_KEY].join('.');
    return {
        host,
        onHttpError: async function <T>(request: RestRequest, response: RestErrorResponse) {
            return response
        },
        getRemember: () => {
            return localStorage.getItem(REST_AUTH_REMEMBER_KEY) !== null;
        },
        setRemember: (remember) => {
            remember ? localStorage.setItem(REST_AUTH_REMEMBER_KEY, '' + remember)
                : localStorage.removeItem(REST_AUTH_REMEMBER_KEY);
        },
        getAuthorization() {
            const serializedState = localStorage.getItem(authorizationKey) ?? sessionStorage.getItem(authorizationKey);
            return serializedState === null ? emptyAuthorization : JSON.parse(serializedState);
        },
        setAuthorization(authorization: Authorization) {
            const serializedState = JSON.stringify(authorization);
            const storage = this.getRemember() ? localStorage : sessionStorage;
            storage.setItem(authorizationKey, serializedState)
            storage.setItem(accessTokenKey, authorization?.accessToken)
        },
        getAccessToken() {
            return localStorage.getItem(accessTokenKey) ?? sessionStorage.getItem(accessTokenKey) ?? undefined;
        },
        setAccessToken(accessToken: string) {
            const storage = this.getRemember() ? localStorage : sessionStorage;
            storage.setItem(accessTokenKey, accessToken)
        },
        fetchRequest: async function <T>(request: RestRequest) {
            return this.fetch(request.method, request.path, request.body, request.headers);
        },
        fetchForm: async function <T>(method: RequestMethod, path: string, body?: FormData, headers: { [p: string]: string } = {}) {
            return this.fetch(method, path, body, headers)
        },
        fetchJSON: async function <T>(method: RequestMethod, path: string, body?: { [p: string]: string | unknown } | any, headers: { [p: string]: string } = {}) {
            return this.fetch(method, path, JSON.stringify(body), {
                'Content-Type': 'application/json',
                ...headers,
            })
        },
        fetch: async function <T>(method: RequestMethod, path: string, body?: BodyInit, headers: { [p: string]: string } = {}) {
            const url = this.host + path;
            const methodName = RequestMethod[method];

            const authorization = await this.getAuthorization();
            const authorizationHeader = authorization?.tokenType + ' ' + authorization?.accessToken;
            const response: Response | undefined = await fetch(url, {
                method: methodName,
                mode: 'cors',
                cache: options?.cache ?? undefined,
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                headers: {
                    'Authorization': authorizationHeader,
                    'Accept': 'application/json',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Connection': 'keep-alive',
                    ...headers,
                },
                body,
            }).catch((error) => {
                console.error('AppClient.fetch', error);
                return undefined;
            });
            if (response === undefined) {
                console.log(methodName, url, '\nheaders:', headers, '\nbody:', body, '\nstatus:', 408);
                return responseFromError(408);
            }


            // if (response.status === 401 && !headers["No-Refresh"]) {
            //     const refreshResponse = await options?.onAuthorizationError?.(this, authorization.refreshToken);
            //     if (refreshResponse?.success) {
            //         this.setAuthorization(refreshResponse.value);
            //         return this.fetch(method, path, body, {
            //             ...headers,
            //             "No-Refresh": 'true',
            //         });
            //     }
            // }

            const responseBody = await response.json().catch((error) => {
                console.log('ERROR parsing json body\n' +
                    'url:', url, '\nerror:', error
                );
            });

            console.log(methodName, url, '\n', headers, '\n', body, '\n', response.status, response.statusText, '\n', responseBody);

            if (!response.ok) {
                const request: RestRequest = {method, path, body, headers};
                const error = responseFromError(response.status, responseBody);
                return this.onHttpError<T>(request, error);
            }

            return {
                success: true,
                status: response.status,
                statusText: response.statusText,
                feedback: {severity: 'success', message: ''},
                value: responseBody as T,
            }
        },
    }
}


