import {Box} from "@material-ui/core";
import {Autocomplete, AutocompleteRenderInputParams, createFilterOptions} from "@material-ui/lab";
import {Participant} from "../../models/Participant";
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from 'remixicon-react/CheckLineIcon'
import QuestionIcon from 'remixicon-react/QuestionLineIcon'
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {projectUserActions} from "../../store/ProjectUserState";
import {projectYouthActions} from "../../store/YouthState";
import useReduxCrudPropertyState from "../../hooks/useReduxCrudPropertyState";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    auto: {
        '& div': {
            '& div': {
                paddingTop: "0 !important",
            },
        },
    },
    participant: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-start",
        padding: theme.spacing(1, 2),
    },
}))

interface EventParticipantFieldProps {
    participants: Participant[];
    input: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const EventParticipantField: React.FC<EventParticipantFieldProps> = ({participants, input, setFieldValue}) => {
    const classes = useStyles();
    const currentProjectId = useSelector<AppState, string>((state) => state.project.selectedElement?.id ?? '')
    const [userState] = useReduxCrudPropertyState((state: AppState) => state.users, projectUserActions, currentProjectId);
    const [youthState] = useReduxCrudPropertyState((state: AppState) => state.youth, projectYouthActions, currentProjectId);
    const participantOptions = [...userState.elements, ...youthState.elements.map(e => ({...e, isYouth: true}))];



    const filterOptions = createFilterOptions<Participant>({
        stringify: option => option.name,
    });

    const handleChange = <T, >(event: React.ChangeEvent<{}>, newValue: T | T[], reason: string) => {
        if (reason !== "remove-option") {
            setFieldValue('participants', newValue);
        }
    };
    const handleInput = (event: React.ChangeEvent<{}>, newInputValue: string) => setFieldValue('participantInput', newInputValue)
    const handleSelect = (option: Participant, value: Participant) => option.id === value.id;
    const handleParticipantClick = (pid: string) => () => setFieldValue('participants', participants.filter(p => p.id !== pid))

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            placeholder="Tilføj deltagere"
            variant="filled"
        />
    );
    const renderNull = () => null;
    const renderParticipant = (p: Participant) => {
        return <span>{p.name} {(p as any).isYouth ? '(ung)' : ''}</span>;
    };

    return (
        <div className={classes.root}>
            <Box pb={1} component="span">Deltagere ({participants?.length ?? 0})</Box>
            <Autocomplete
                id="participant-search-input"
                multiple
                value={participants}
                onChange={handleChange}
                inputValue={input}
                onInputChange={handleInput}
                options={participantOptions}
                filterOptions={filterOptions}
                renderInput={renderInput}
                renderOption={renderParticipant}
                getOptionSelected={handleSelect}
                renderTags={renderNull}
                fullWidth
                className={classes.auto}
                noOptionsText="Ingen mulige deltagere"
            />
            {participants.map(participant => (
                <div key={participant.id} className={classes.participant}>
                    <EventParticipantStatusIcon status={participant.status}/>
                    <span style={{marginLeft: 8}}>{participant.name}</span>
                    <div style={{flex: 1}}/>
                    <IconButton size={"small"} onClick={handleParticipantClick(participant.id)}>
                        <CloseLineIcon fontSize={"small"}/>
                    </IconButton>
                </div>
            ))}
        </div>
    )
}

export const EventParticipantStatusIcon: React.FC<{ status?: number }> = ({status}) => {
    if (status === undefined || status < 0 || status > 3) return <></>
    const Icon = status === 0 ? QuestionIcon : status === 1 ? CheckIcon : status === 2 ? QuestionIcon : CloseLineIcon;
    const tooltip = status === 0 ? 'Deltager måske' : status === 1 ? 'Deltager' : status === 2 ? 'Deltager måske' : 'Deltager ikke';

    return (
        <Tooltip title={tooltip}>
            <div style={{display: 'flex', marginTop: 4}}><Icon/></div>
        </Tooltip>
    )
}

export default EventParticipantField;
