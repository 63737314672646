import {AppState} from "../store";
import {useSelector} from "react-redux";


export const useTypedSelector = <TState = AppState, TSelected = unknown>(
    selector: (state: AppState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => useSelector(selector, equalityFn);


export default useTypedSelector;

